import { useEffect, useState } from 'react'
import _ from 'lodash'
import { IMapJson } from 'shared/json/IMapJson'
import { useDispatch } from 'react-redux'
import { t } from 'client/i18n'
import { setPinForMapLocations, updatePin } from 'client/screens/AppEditor/MapEditor/MapEditorUtils'
import { ICoordinates } from 'shared/util/maps'
import MapPreview from './MapPreview'
import FloorMapLocation from './MapLocation/FloorMapLocation'
import { IDragEndEventProps } from './MapLocation/MapLocation'

interface IFloorPreviewProps {
  floor: IMapJson
  selectedPinId?: number | null
  onSelectedPinChanged?: (id: number) => void
}

const FloorPreview = (props: IFloorPreviewProps) => {
  const { floor: floorRaw, selectedPinId, onSelectedPinChanged } = props
  const [floor, setFloor] = useState<IMapJson>(floorRaw)

  useEffect(() => {
    setFloor(floorRaw)
  }, [floorRaw])

  const dispatch = useDispatch()

  const handleDragEndLocation = async ({ id, coordinates }: IDragEndEventProps) => {
    const mapLocations = setPinForMapLocations({ id, coordinates, map: floor })
    setFloor({
      ...floor,
      mapLocations
    })

    await updatePin(floor.id, { id, coordinates }, dispatch)
  }

  const handleMapLocationResized = async (
    id: number,
    radius: number,
    coordinates?: ICoordinates
  ) => {
    const mapLocations = setPinForMapLocations({ id, radius, map: floor })
    setFloor({
      ...floor,
      mapLocations
    })

    await updatePin(floor.id, { id, radius, coordinates }, dispatch)
  }

  return !_.isEmpty(floor) ? (
    <MapPreview
      map={floor}
      title={t('Floor __floorTitle__', { floorTitle: floorRaw.title })}
      selectedPinId={selectedPinId}
      onSelectedPinChanged={onSelectedPinChanged}
      onDragEndLocation={handleDragEndLocation}
      onMapLocationResized={handleMapLocationResized}
      mapLocationElement={FloorMapLocation}
    />
  ) : null
}

export default FloorPreview
