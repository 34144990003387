import { useContext } from 'react'
import * as yup from 'yup'
import gql from 'graphql-tag'
import TranslationForm from 'client/components/TranslationForm/TranslationForm'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import useNumericRouteParam from 'client/hooks/useNumericRouteParam'
import { createApiConfig } from 'client/components/TranslationForm/util'
import FeatureInput from 'shared/graphql/mutations/FeatureInput'
import { t } from 'client/i18n'

const TITLE_CHAR_LIMIT = 180

const apiConfig = createApiConfig(
  'feature',
  gql`
    query getSingleFeature($id: Int!) {
      feature(id: $id) {
        id
        translations {
          title

          locale {
            englishName
            code
          }
        }
      }
    }
  `
)

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .nullable()
    .max(TITLE_CHAR_LIMIT, `${t('Title')}: ${t('Max character limit exceeded.')}`)
})

const FeatureFormView = () => {
  const { getFieldName } = useContext(TranslationFormContext)
  return (
    <TextInputFormField
      name={getFieldName('title')}
      label={t('Title')}
      placeholder={t('e.g., Now on Display, Past Exhibitions, Family Programming')}
      maxLength={TITLE_CHAR_LIMIT}
    />
  )
}

const FeatureForm = () => {
  const contentId = useNumericRouteParam('featureId')

  return (
    <TranslationForm
      contentName="Section Title"
      contentId={contentId}
      apiConfig={apiConfig}
      inputType={FeatureInput}
      initialValuesParser={(values) => ({
        ...values,
        /*

        We allow features to have empty strings as titles. That's why this empty string initialization is here.

        * If title isn't initialized as an empty string in the returned object in `initialValuesParser`, we are unable to create a feature without populating the title field; we can create a feature with a non-empty title and edit it to be an empty string however.
        * It title is initialized to be `null`, we are able to create a feature without populating the title field, but we then can't edit the title. Creating a feature with a populated title allows us to edit the title, but if it is ever saved as an empty value, we lose the ability to edit.

        There's some weirdness going on in the TranslationForm `onSave` function.

        Aimy Yu has some context on this.  Also see https://pm.bloomberg.com/jira/browse/DOCNT-7013
         */
        title: ''
      })}
      allowDelete={false}
      validationSchema={validationSchema}
    >
      <FeatureFormView />
    </TranslationForm>
  )
}

export default FeatureForm
