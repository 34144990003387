import React from 'react'
import _ from 'lodash'
import { GQLItem, GQLExhibit, GQLImage } from 'shared/graphql/types/graphql'
import { ImageType } from 'shared/constants/images'
import ImagePlaceholderSVG from 'client/assets/svg/emptyStates/no_image_rectangle.svg'

const getImageUrlByType =
  (type: ImageType, fallbackImage: React.FC<React.SVGProps<SVGSVGElement>>) =>
  (image: GQLImage, placeholderImage?: React.FC<React.SVGProps<SVGSVGElement>>) =>
    (image && (image[type] || image.url)) || placeholderImage || fallbackImage

const getImageThumbnail = getImageUrlByType(ImageType.THUMBNAIL, ImagePlaceholderSVG)
const getImageHero = getImageUrlByType(ImageType.HERO, ImagePlaceholderSVG)

const ImageUrl = {
  [ImageType.THUMBNAIL]: getImageThumbnail,
  [ImageType.HERO]: getImageHero
}

const getImage =
  (extractImage: (data: GQLExhibit | GQLItem) => GQLImage) =>
  (type: keyof typeof ImageUrl) =>
  (data: GQLExhibit | GQLItem) =>
    ImageUrl[type](extractImage(data))

const getImageFromItemOrExhibit = (itemOrExhibit: GQLExhibit | GQLItem) =>
  _.first(itemOrExhibit.images)! // Items and Exhibits MUST have at least one image

const getExhibitOrItemImageByType = getImage(getImageFromItemOrExhibit)

export const getItemOrExhibitImageThumbnail = getExhibitOrItemImageByType(ImageType.THUMBNAIL)
export const getItemOrExhibitImageHero = getExhibitOrItemImageByType(ImageType.HERO)
