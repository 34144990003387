import * as yup from 'yup'
import _ from 'lodash'
import { LOOKUP_NUMBER_MAX, LOOKUP_NUMBER_MIN } from 'shared/validation/constants'
import { parse as parseHtml } from 'node-html-parser'
import { XMLValidator } from 'fast-xml-parser'
import { TFunction } from 'i18next'
import { TKey } from 'shared/i18n/types/translationResources'
import { BulkUploadJson } from 'shared/bulkUpload/types'
import { AnyObject } from 'yup/lib/types'
import { Nil } from 'shared/util/types'

interface ISchemaFunctionParams {
  data: BulkUploadJson
  validLocales: { isDefault: boolean; code: string }[]
  assignedLookupNumbers: number[]
}

export type SchemaFunction = (params: ISchemaFunctionParams) => yup.AnyObjectSchema

// TODO is this safe to TS assert non-null?
export const getFieldNameFromTestPath = (path: string) => _(path).split('.').last()!

const VALID_HTML_TAGS = ['p', 'a', 'strong', 'em', 'b', 'br']

function isSupportedProtocol(url: string) {
  try {
    const { protocol } = new URL(url)
    return _.includes(['http:', 'https:', 'tel:', 'mailto:', 'sms:'], protocol)
  } catch {
    return false
  }
}

function isValidATag(attributes: Record<string, string>) {
  const unexpectedAttributes = _.omit(attributes, ['href', 'target'])
  return isSupportedProtocol(attributes.href) && _.isEmpty(unexpectedAttributes)
}

enum InvalidHtmlErrorCodes {
  INVALID_HTML,
  INVALID_A_TAG
}

export const validateHTML = (html: string) => {
  const parsed = parseHtml(html)
  const elements = parsed.querySelectorAll('*')
  const errors = new Set<InvalidHtmlErrorCodes>()

  // Accumulate any errors
  _.forEach(elements, (element) => {
    const tagName = element.tagName.toLowerCase()
    const { attributes } = element

    if (tagName === 'a') {
      if (!isValidATag(attributes)) {
        errors.add(InvalidHtmlErrorCodes.INVALID_A_TAG)
      }
    } else if (!_.includes(VALID_HTML_TAGS, tagName) || !_.isEmpty(attributes)) {
      errors.add(InvalidHtmlErrorCodes.INVALID_HTML)
    }
  })

  if (errors.size !== 0) {
    return errors.has(InvalidHtmlErrorCodes.INVALID_A_TAG)
      ? InvalidHtmlErrorCodes.INVALID_A_TAG
      : InvalidHtmlErrorCodes.INVALID_HTML
  }

  const htmlLowercase = html.toLowerCase().trim()
  const isValidParagraph = htmlLowercase.startsWith('<p>') && htmlLowercase.endsWith('</p>')

  if (!isValidParagraph) {
    return InvalidHtmlErrorCodes.INVALID_HTML
  }

  const isValidXml =
    XMLValidator.validate(`<root>${html}</root>`, {
      unpairedTags: ['br']
    }) === true

  return !isValidXml ? InvalidHtmlErrorCodes.INVALID_HTML : true
}

export function validHtml(t: TFunction): {
  name: string
  test: yup.TestFunction<string | Nil, AnyObject>
} {
  return {
    name: 'valid-html',
    test: (value: string | Nil, context) => {
      if (!value) {
        return true
      }
      const result = validateHTML(value)
      switch (result) {
        case InvalidHtmlErrorCodes.INVALID_HTML: {
          return context.createError({
            // eslint-disable-next-line docent/require-translation-keys-to-be-literals
            message: `${t(getFieldNameFromTestPath(context.path) as TKey)}: ${t(
              'HTML text must be properly formatted, must be wrapped in a <p> tag, can only contain <em> <strong> <a> tags otherwise, and does not allow custom classes'
            )}`
          })
        }
        case InvalidHtmlErrorCodes.INVALID_A_TAG: {
          return context.createError({
            // eslint-disable-next-line docent/require-translation-keys-to-be-literals
            message: `${t(getFieldNameFromTestPath(context.path) as TKey)}: ${t(
              'Links must start with http:// or https:// (URLs), tel: (phone numbers), or mailto: (email addresses).'
            )}`
          })
        }
        default: {
          return true
        }
      }
    }
  }
}

export function validLookupNumber(t: TFunction) {
  return yup
    .number()
    .nullable()
    .min(
      LOOKUP_NUMBER_MIN,
      t(
        'The lookupNumber is out of range. Supported range is __LOOKUP_NUMBER_MIN__ - __LOOKUP_NUMBER_MAX__.',
        { LOOKUP_NUMBER_MIN, LOOKUP_NUMBER_MAX }
      )
    )
    .max(
      LOOKUP_NUMBER_MAX,
      t(
        'The lookupNumber is out of range. Supported range is __LOOKUP_NUMBER_MIN__ - __LOOKUP_NUMBER_MAX__.',
        { LOOKUP_NUMBER_MIN, LOOKUP_NUMBER_MAX }
      )
    )
}
