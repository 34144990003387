import styled from 'styled-components'
import classnames from 'classnames'
import ErrorIconSVG from 'client/assets/svg/icon/status_error_20.svg'
import _ from 'lodash'
import { t } from 'client/i18n'
import { ChangeEventHandler } from 'react'

const css = require('./StringInput.css')

export interface IStringInputProps {
  name?: string
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onEndEditing?: (event: any) => void
  password?: boolean
  placeholder?: string
  disableAutocomplete?: boolean
  enableSpellCheck?: boolean
  inputType?: 'number' | 'text'
  maxLength?: number
  warningLimit?: number
  className?: string
  inputClassName?: string
  autoFocus?: boolean
  min?: number
  max?: number
  isInputRequired?: boolean
  hasBorder?: boolean
}

interface IInputProps {
  displayWarning: boolean
  hasBorder?: boolean
}

const borderStyleConditional = (props: IInputProps) =>
  props.displayWarning ? 'var(--color-status-error)' : 'inherit'

const Input = styled.input<IInputProps>`
  ${({ hasBorder }) =>
    hasBorder
      ? `
        border-color: ${borderStyleConditional};

        &:hover {
          border-color: ${borderStyleConditional};
        }

        &:focus {
        border-color: ${borderStyleConditional};
        }
        `
      : `
        border-width: 0px;
        border-bottom: 1px dotted var(--input-border-normal);
        &:hover {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px dotted var(--input-border-normal);
        }
        &:focus {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px dotted var(--input-border-normal);
        }
      `}
`

export default function StringInput(props: IStringInputProps) {
  const {
    name,
    value,
    onChange,
    placeholder,
    password,
    disableAutocomplete,
    enableSpellCheck,
    inputType,
    maxLength,
    warningLimit,
    className,
    autoFocus,
    inputClassName,
    min,
    max,
    isInputRequired = false,
    hasBorder = true
  } = props
  const type = password ? 'password' : inputType || 'text'
  const placeholderValue = placeholder || ''
  const disableKeyword = password ? 'new-password' : 'off'
  const autoComplete = disableAutocomplete ? disableKeyword : undefined
  const spellCheck = enableSpellCheck !== false
  const maxLengthAttribute = maxLength ? { maxLength } : null

  const containerClassName = classnames(css.container, className)

  const inputClass = classnames(css.input, inputClassName)

  const isOverLimit = !!(value && warningLimit && value.length > warningLimit)
  const isInputEmpty = _.isEmpty(value)

  return (
    <>
      <div className={containerClassName}>
        {isOverLimit && <ErrorIconSVG className={css.errorExclaim} />}
        <Input
          displayWarning={isOverLimit}
          placeholder={placeholderValue}
          className={inputClass}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          autoComplete={autoComplete}
          spellCheck={spellCheck}
          autoFocus={autoFocus}
          min={min}
          max={max}
          hasBorder={hasBorder}
          {...maxLengthAttribute}
        />
      </div>
      {isOverLimit && <p className={css.errorWarning}>{t('Max character limit exceeded.')}</p>}
      {isInputRequired && isInputEmpty && (
        <p className={css.errorWarning}>{t('This field is required.')}</p>
      )}
    </>
  )
}
