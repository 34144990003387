import { useContext, useMemo } from 'react'
import _ from 'lodash'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import { IUsedLookupMapping } from 'client/screens/Catalog/forms/shared/LookupNumber/useAvailableLookupNumbers'

type LookupNumberItem = {
  id: number
  lookupNumber: number
}

const useExhibitLookupMapping = (): IUsedLookupMapping => {
  const { initialValues, getFieldValue } = useContext(TranslationFormContext)
  const { items, lookupNumber = '' } = initialValues as {
    items: LookupNumberItem[]
    lookupNumber: number
  }
  const currentItems = getFieldValue('items') as LookupNumberItem[]
  const currentLookupNumber = getFieldValue('lookupNumber') as number

  const usedLookupMapping = useMemo(() => {
    const initialItemsById = _.keyBy(items, 'id')
    const currentItemsById = _.keyBy(currentItems, 'id')

    // old-to-new-number.
    // old numbers are the numbers to allow selecting
    // new numbers are the numbers to disallow selecting
    const lookupMap: IUsedLookupMapping = {}

    // For each new item, create a oldLookup-to-newLookup value
    _.each(currentItemsById, (value, key) => {
      // Note we're explicitly falling back to truthy value to avoid null key collisions between exhibition items.
      // null-to-number has the same effect as number-to-number mapping.
      // null-to-null has no effect in the shown lookup number. See `useAvailableLookupNumbers.tsx`
      const oldLookupValue = initialItemsById[key]?.lookupNumber || value.lookupNumber
      lookupMap[oldLookupValue] = value.lookupNumber
    })

    // We're initializing exhibition lookup entry AFTER exhibition items in order to avoid null collisions.
    // If the exhibit changes its lookup number from null-to-number, that can get overridden
    // by exhibition items if there is a null-to-null entry. Placing this after prevents thats.
    // null keys are the only case where collision can exist.
    // It's not a problem outside of that initial case
    return { ...lookupMap, [lookupNumber]: currentLookupNumber }
  }, [items, lookupNumber, currentItems, currentLookupNumber])

  return usedLookupMapping
}

export default useExhibitLookupMapping
