import { memo } from 'react'
import _ from 'lodash'
import { GQLExhibit, GQLItem } from 'shared/graphql/types/graphql'
import { isItemContent, getItemDescription } from 'shared/util/items'
import useMapLocationContent from 'client/screens/AppEditor/MapEditor/useMapLocationContent'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import { useBuildings } from 'client/screens/AppEditor/MapEditor/useBuildings'
import { getMapById } from 'client/screens/AppEditor/MapEditor/MapEditorUtils'
import useNumericRouteParam from 'client/hooks/useNumericRouteParam'
import MapTooltip, { IMapTooltip } from './MapLocationTooltip/MapLocationTooltip'
import MapLocation, { IMapLocation } from './MapLocation'
import FloorTooltipMenu from './MapLocationTooltip/FloorTooltipMenu'

interface ITooltipBody {
  header?: string
  subheader?: string
}

const getBody = (content: GQLExhibit | GQLItem): ITooltipBody => {
  return isItemContent(content) ? getItemDescription(content) : { header: content.title }
}

const FloorMapLocationTooltip = memo<IMapTooltip>((incomingProps) => {
  const { locationId } = incomingProps
  const buildingId = useNumericRouteParam('buildingId')
  const floorId = useNumericRouteParam('floorId')
  const { buildings, exteriorMap, loading: isQueryLoading } = useBuildings()
  const floor = getMapById(buildings, exteriorMap, buildingId, floorId)

  const { mapLocationContents: contents, loading } = useMapLocationContent(locationId)

  const mapLocation = _.find(floor?.mapLocations, { id: locationId })!
  const content = contents.length ? contents[0] : null
  const showTotalContent = contents.length > 1
  const totalContent = showTotalContent ? contents.length - 1 : 0
  // Note: content has either GQLItem or GQLExhibit spread onto it. In addition, includes a `.content` property
  // of type GQLMapLocationContent which also contains the GQLItem or GQLExhibit among other properties.
  // Should be cleaned up at some point.
  const body = content ? getBody(content as GQLItem | GQLExhibit) : null
  if (loading || isQueryLoading) {
    return <LoadingOverlay />
  }

  return (
    <MapTooltip
      {...incomingProps}
      locationName={mapLocation?.name}
      menuPopout={FloorTooltipMenu}
      body={{ ...body, showTotalContent, totalContent }}
    />
  )
})

export default (props: IMapLocation) => (
  <MapLocation {...props} tooltipElement={FloorMapLocationTooltip} />
)
