import { memo, useState, useEffect } from 'react'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import _ from 'lodash'
import { IReorderableRowProps } from 'client/components/ContentRow/ReorderableRow'

export interface IReorderableList {
  name: string
  values: any[]
  idFieldName?: string
  rowComponent: (props: IReorderableRowProps<any>) => JSX.Element | null
  onUpdate: (values: any[]) => void
  isDragDisabled?: boolean
  className?: string
}

function ReorderableList(props: IReorderableList) {
  const {
    name,
    values,
    onUpdate,
    idFieldName = 'id',
    rowComponent,
    isDragDisabled = false,
    className = ''
  } = props

  // Use local state to avoid flickering
  const [items, setItems] = useState(values)

  // Re-sync when data changes
  useEffect(() => {
    setItems(values)
  }, [values])

  // @ts-ignore DOCNT-10958
  const handleUpdate = (updated) => {
    setItems(updated)
    onUpdate(updated)
  }

  // @ts-ignore DOCNT-10958
  const onDragEnd = (e) => {
    const { destination, source, draggableId } = e

    if (_.isEmpty(destination)) {
      return
    }
    const newPosition = destination.index
    const oldPosition = source.index

    // Nothing to do in this case
    if (newPosition === oldPosition) {
      return
    }

    const value = _.find(items, (obj) => _.get(obj, idFieldName).toString() === draggableId)
    const reordered = _.without(items, value)
    reordered.splice(newPosition, 0, value)
    handleUpdate(reordered)
  }

  const handleRemove = (value: any) => {
    const listWithValueRemoved = _.without(items, value)
    handleUpdate(listWithValueRemoved)
  }

  const handleEdit = (value: any, newValue: any) => {
    const updatedValues = [...items]
    updatedValues[items.indexOf(value)] = newValue
    handleUpdate(updatedValues)
  }

  const renderList = () => {
    if (_.isEmpty(items)) {
      return null
    }

    const Row = rowComponent
    return items.map((value, index) => {
      const id = _.get(value, idFieldName)

      const dragDisabled = isDragDisabled || items.length === 1
      return (
        <Draggable
          key={`draggable-item${name}${id}`}
          draggableId={id.toString()}
          index={index}
          isDragDisabled={dragDisabled}
        >
          {(draggableProvided, draggableSnapshot) => {
            return (
              <div
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
                key={`draggable-div${name}${id}`}
                ref={draggableProvided.innerRef}
              >
                <Row
                  key={`${name}${id}`}
                  value={value}
                  index={index}
                  onRemove={() => handleRemove(value)}
                  onEdit={(newValue) => handleEdit(value, newValue)}
                  isDragging={draggableSnapshot.isDragging}
                  showGrabber={!dragDisabled}
                />
              </div>
            )
          }}
        </Draggable>
      )
    })
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={name} type={name}>
        {(droppableProvided) => (
          <div ref={droppableProvided.innerRef} className={className}>
            {renderList()}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default memo(ReorderableList)
