import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import createBaseConfiguration, { ResourceLanguageCode, TRANSLATIONS } from 'shared/i18n'
import _ from 'lodash'
// Should this import live here?
import { Locale } from 'date-fns/locale'

const { ENABLED_TRANSLATION_LANG_CODES } = window

const showMissingKeyMessage = _.memoize((message) => {
  // eslint-disable-next-line no-console
  console.error(message)
})

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ...createBaseConfiguration(ENABLED_TRANSLATION_LANG_CODES),
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      // Don't cache any values, only detect
      // This means we can't call `i18next.changeLanguage`, so there's a `changeLanguage` function below to help
      caches: []
    },
    ...(process.env.NODE_ENV === 'development' && {
      saveMissing: true,
      missingKeyHandler(lngs: readonly string[], ns: string, key: string) {
        showMissingKeyMessage(`Missing t key: ${key}`)
      }
    }),
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'em', 'p', 'ul', 'li', 'span']
    }
  })

export function changeLanguage(lng: string | null) {
  // We cannot directly call `i18next.changeLanguage`, as it is not configured to save values to localStorage, only READ
  // This is to prevent writing cookie/navigator values to localstorage will would take precedence over detected values on next refresh
  // localStorage is only used for explicitly setting the language via the Language Selector in the top nav, which is only enabled for internal users
  if (!lng) {
    localStorage.removeItem('i18nextLng')
  } else {
    localStorage.setItem('i18nextLng', lng)
  }
  window.location.reload()
}

export function getCurrentLanguageHasGoogleTranslateEntries() {
  const { language } = i18n
  return _.has(TRANSLATIONS, language) && !_.isEmpty(_.get(TRANSLATIONS, `${language}.gt`))
}

type RealResourceLanguageCode = Exclude<ResourceLanguageCode, 'hearts'>
type LanguageCodeToConfig = {
  [key in RealResourceLanguageCode]: {
    name: string
    dateFnsLocale: undefined | Locale
  }
}

const LANGUAGE_CODE_TO_CONFIG: LanguageCodeToConfig = {
  en: {
    name: 'English',
    // undefined will be treated as "en-US" by default
    dateFnsLocale: undefined
  },
  ...TRANSLATIONS
}

// TODO: Support other languages
export function getDateFnsLocale(): Locale | undefined {
  return LANGUAGE_CODE_TO_CONFIG[i18n.language as RealResourceLanguageCode]?.dateFnsLocale
}

type LanguageCodeToLabel = {
  [key in keyof LanguageCodeToConfig]: string
}
const LANGUAGE_CODE_TO_LABEL: LanguageCodeToLabel = _.mapValues(
  LANGUAGE_CODE_TO_CONFIG,
  (config) => config.name
)

export const enabledLanguageCodeToLabel =
  ENABLED_TRANSLATION_LANG_CODES === '*'
    ? LANGUAGE_CODE_TO_LABEL
    : // using this `pickBy` instead of `.pick(ENABLED_TRANSLATION_LANG_CODES)` to preserve the
      // original LANGUAGE_CODE_TO_LABEL ordering
      _.pickBy(LANGUAGE_CODE_TO_LABEL, (value, code) =>
        ENABLED_TRANSLATION_LANG_CODES.includes(code as ResourceLanguageCode)
      )

export default i18n

const { t } = i18n
export { t }
