import { ReorderableExhibitRow } from 'client/components/ContentRow/ReorderableRow'
import { PickerExhibitRow } from 'client/components/ContentRow/PickerRow'
import { useCallback } from 'react'
import gql from 'graphql-tag'
import DataAwareReorderableList from 'client/components/Formik/ReorderableList/DataAwareReorderableList'
import { COMMON_CONTENT_FILTER_CRITERIA } from 'client/util/filters'

const EXHIBITS_MINIPICKER_QUERY = gql`
  query exhibitsForMinipicker($museumId: Int!) {
    museum(id: $museumId) {
      id
      exhibits {
        id
        type
        title
        published
        displayPeriod
        from
        to
        timezone
        isAllDay
        images {
          id
          thumbnail
          hero
          full
        }
        locales {
          code
          englishName
        }
      }
    }
  }
`

interface IExhibitsListProps {
  name: string
}

export default function ExhibitsList(props: IExhibitsListProps) {
  const ExhibitsRow = useCallback(
    // @ts-ignore DOCNT-10958
    (reorderableRowProps) => <ReorderableExhibitRow {...reorderableRowProps} showGrabber={false} />,
    []
  )

  return (
    <DataAwareReorderableList
      name={props.name}
      isDragDisabled={true}
      rowComponent={ExhibitsRow}
      pickerRowComponent={PickerExhibitRow}
      filterCriteria={COMMON_CONTENT_FILTER_CRITERIA}
      contentName="Content"
      gqlQuery={EXHIBITS_MINIPICKER_QUERY}
    />
  )
}
