import authSaga from './auth'
import versionSaga from './version'
import navigationSaga from './navigation'
import gqlIntegrationSaga from './gqlIntegration'
import toastSaga from './toast'
import bulkUploadSaga from './bulkUpload'

export default [
  authSaga,
  versionSaga,
  navigationSaga,
  gqlIntegrationSaga,
  toastSaga,
  bulkUploadSaga
]
