import { useDispatch } from 'react-redux'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import { useNavigate, useParams } from 'react-router-dom'
import { confirm } from 'client/redux/actions/confirmation'
import { IBuildingsURLParams } from 'client/types'
import { PermanentActionMessage } from 'client/dsm/Dialog/Dialog'
import { t } from 'client/i18n'
import { refetchActiveQueries } from 'client/apollo'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { useDelete } from 'client/hooks/api'

const defaultMessage = (
  <>
    <p>{t('Are you sure you want to delete this pin?')}</p>
    <PermanentActionMessage />
  </>
)

const withContentMessage = (
  <>
    <p>
      {t(
        'This pin is associated with content. Deleting this pin will remove content from the map. Content itself will not be affected.'
      )}
    </p>
    {defaultMessage}
  </>
)

interface ITooltipMenuProps {
  locationId: number
  isShowingBubble: boolean
  onToggleBubble: () => void
  hasContent: boolean
}

const TooltipMenu = (props: ITooltipMenuProps) => {
  const { locationId, isShowingBubble, onToggleBubble, hasContent } = props
  const { floorId } = useParams<IBuildingsURLParams>()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [deletePin] = useDelete(`/maps/floor/${floorId}/pin/${locationId}`, {
    onSuccess: () => {
      refetchActiveQueries()
      dispatch(showChangesSavedToast())
    },
    onError: () => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: t("We weren't able to delete the floor pin. Please try again later."),
          isAlert: true
        })
      )
    }
  })

  const handleDelete = () => {
    const deleteAction = async () => {
      await deletePin()
    }

    dispatch(
      confirm({
        title: t('Delete Pin?'),
        message: hasContent ? withContentMessage : defaultMessage,
        confirmYes: { action: deleteAction, label: t('Delete Pin'), isDestructive: true }
      })
    )
  }

  const options: IMenuOption[] = [
    {
      label: t('Edit Pin'),
      onClick: () => navigate(`pins/${locationId}`)
    },
    isShowingBubble
      ? {
          label: t('Hide Area'),
          onClick: () => onToggleBubble()
        }
      : {
          label: t('Show Area'),
          onClick: () => onToggleBubble()
        },
    {
      label: t('Delete Pin'),
      onClick: handleDelete
    }
  ]

  return <MenuPopout options={options} />
}

export default TooltipMenu
