import { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { GQLFeature } from 'shared/graphql/types/graphql'
import styled from 'styled-components'
import StandardForm from 'client/components/StandardForm/StandardForm'
import ReorderableList from 'client/components/ReorderableList/ReorderableList'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { usePost } from 'client/hooks/api'
import { refetchActiveQueries } from 'client/apollo'
import { useNavigate } from 'react-router-dom'
import ImageAsset from 'client/screens/AppEditor/ImageUpload/ImageAsset'
import { useErrorDialog } from 'client/components/ErrorDialog'
import { t } from 'client/i18n'
import { useDispatch } from 'react-redux'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import FeatureRow, { IMinimalFeature } from './FeatureRow'

const FEATURES_GRAPHQL_QUERY = gql`
  query featuresForMuseum($museumId: Int!) {
    museum(id: $museumId) {
      id
      features {
        id
        title
        featuredItems {
          id
          item {
            id
            title
            commonName
            scientificName
          }
          exhibit {
            id
            title
          }
        }
      }

      images {
        museumLogo {
          id
          sourceUrl
        }
      }
    }
  }
`

const HomeLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

const getMinimalFeatures = (features: GQLFeature[]): IMinimalFeature[] => {
  let emptySectionCount = 1

  const minimalFeatures = _.map(features, (feature) => {
    let label: string

    if (feature.title && _.trim(feature.title)) {
      label = feature.title
    } else if (feature.featuredItems.length) {
      const featuredItem = feature.featuredItems[0]
      if (featuredItem.exhibit) {
        label = featuredItem.exhibit.title
      } else if (featuredItem.item) {
        label = (featuredItem.item.title ||
          featuredItem.item.commonName ||
          featuredItem.item.scientificName)!
      } else {
        throw new Error(
          'This should be unreachable: got a GQLFeaturedItem that is neither Item nor Exhibit'
        )
      }
    } else {
      label = t('Empty Section __emptySectionCount__', { emptySectionCount })
      emptySectionCount += 1
    }

    return { id: feature.id, title: feature.title, label }
  })
  return minimalFeatures
}

const ReorderFeatureForm = () => {
  const { loading, data } = useQuery(FEATURES_GRAPHQL_QUERY)
  const featureList = useMemo(() => _.get(data, 'museum.features', []), [data])
  const homeLogoUrl = data?.museum.images.museumLogo?.sourceUrl

  const navigate = useNavigate()
  const [features, setFeatures] = useState<IMinimalFeature[]>([])
  const dispatch = useDispatch()

  const [errorDialog, setError] = useErrorDialog()
  const [reorderFeatures] = usePost('/features/sections/reorder', {
    onSuccess: () => {
      dispatch(showChangesSavedToast())
      navigate('..')
      refetchActiveQueries()
    },
    onError: (error) => setError({ error, title: t('Unable to Reorder Features') })
  })

  useEffect(() => {
    setFeatures(getMinimalFeatures(featureList))
  }, [loading, featureList]) // TODO do we need to have loading here too?

  const handleReorder = (reorderedFeatures: IMinimalFeature[]) => {
    setFeatures(reorderedFeatures)
  }

  const submitReorderedFeatures = async () => {
    const featureIds = _.map(features, 'id')
    await reorderFeatures({ featureIds })
  }

  const cancelReorderFeatures = () => navigate('..')

  const content = loading ? null : (
    <>
      {homeLogoUrl && (
        <HomeLogoContainer>
          <ImageAsset src={homeLogoUrl} width={112} height={64} imageIsDisabled={true} />
        </HomeLogoContainer>
      )}
      <ReorderableList
        values={features}
        name="features-reorder"
        rowComponent={FeatureRow}
        onUpdate={handleReorder}
      />
    </>
  )

  return (
    <StandardForm
      title={t('Reorder Sections')}
      onCancel={cancelReorderFeatures}
      onSave={submitReorderedFeatures}
      isLoading={loading}
    >
      {errorDialog}
      {content}
    </StandardForm>
  )
}

export default ReorderFeatureForm
