/**
 *  A React wrapper for CropperJS.
 *  For more info on the API, consult: https://github.com/fengyuanchen/cropperjs
 */

import { createRef, Component } from 'react'
import Cropper from 'cropperjs'
// global css classes need for cropper
import 'cropperjs/dist/cropper.css'
import { t } from 'client/i18n'

// We have to redeclare this because cropperjs forgot to export this enum
enum DragMode {
  Crop = 'crop',
  Move = 'move',
  None = 'none'
}

const CropperJSDefaults = {
  alt: '',
  canvasData: null,
  cropBoxData: null,
  data: undefined,
  dragMode: DragMode.Move,
  enable: true,
  rotateTo: 0,
  scaleX: 1,
  scaleY: 1,
  zoomable: true,
  cropBoxResizable: false,
  rotatable: false,
  scalable: false,
  src: null,
  style: null,
  zoomTo: 1,
  toggleDragModeOnDblclick: false,
  responsive: false,
  viewMode: 0 as Cropper.ViewMode,
  guides: false,
  background: false,
  movable: true,
  checkCrossOrigin: false
}

interface IProps {
  src: string
  aspectRatio?: number
  className?: string
  zoomTo?: number
  // @ts-ignore DOCNT-10958
  cropend?: (e) => any
  // @ts-ignore DOCNT-10958
  zoom?: (e) => any
  ready?: () => any
  alt?: string
  cropBoxMovable?: boolean
}

interface IState {
  loaded: boolean
}

class ReactCropperJS extends Component<IProps, IState> {
  private img = createRef<HTMLImageElement>()

  private cropper: Cropper

  // @ts-ignore DOCNT-10958
  constructor(props) {
    super(props)

    this.onImageLoad = this.onImageLoad.bind(this)
  }

  // @ts-ignore DOCNT-10958
  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.cropper.reset().clear().replace(this.props.src)
    }
    if (prevProps.aspectRatio !== this.props.aspectRatio) {
      this.setAspectRatio(this.props.aspectRatio)
    }
    if (prevProps.zoomTo !== this.props.zoomTo) {
      this.zoomTo(this.props.zoomTo)
    }
  }

  componentWillUnmount() {
    if (this.img) {
      this.cropper.destroy()
    }
  }

  onImageLoad() {
    const { aspectRatio, src, zoomTo, zoom, alt, cropend, ready, cropBoxMovable } = this.props
    const options = {
      ...CropperJSDefaults,
      aspectRatio,
      src,
      zoomTo,
      zoom,
      alt,
      cropend,
      ready,
      cropBoxMovable
    }

    this.cropper = new Cropper(this.img.current!, options)
  }

  // These are actually used via refs
  /* eslint-disable react/no-unused-class-component-methods */
  // @ts-ignore DOCNT-10958
  setAspectRatio(aspectRatio) {
    return this.cropper.setAspectRatio(aspectRatio)
  }

  // @ts-ignore DOCNT-10958
  setCropBoxData(data) {
    if (this.cropper) {
      return this.cropper.setCropBoxData(data)
    }
  }

  getCropBoxData() {
    return this.cropper.getCropBoxData()
  }

  // @ts-ignore DOCNT-10958
  setCanvasData(data) {
    return this.cropper.setCanvasData(data)
  }

  getCanvasData() {
    return this.cropper.getCanvasData()
  }

  getImageData() {
    return this.cropper.getImageData()
  }

  getContainerData() {
    return this.cropper.getContainerData()
  }

  // @ts-ignore DOCNT-10958
  setData(data) {
    return this.cropper.setData(data)
  }

  getData(rounded = true) {
    return this.cropper.getData(rounded)
  }

  crop() {
    return this.cropper.crop()
  }

  // @ts-ignore DOCNT-10958
  zoom(ratio) {
    return this.cropper.zoom(ratio)
  }

  // @ts-ignore DOCNT-10958
  zoomTo(ratio) {
    return this.cropper.zoomTo(ratio)
  }

  reset() {
    return this.cropper.reset()
  }

  clear() {
    return this.cropper.clear()
  }

  /* eslint-enable react/no-unused-class-component-methods */

  render() {
    const { src, alt, className } = this.props
    return (
      <div className={className} style={{ display: 'block', maxWidth: '100%' }}>
        <img
          ref={this.img}
          src={src}
          alt={alt === undefined ? t('picture') : alt}
          style={{ opacity: 0, maxWidth: '100%', display: 'block' }}
          onLoad={this.onImageLoad}
        />
      </div>
    )
  }
}

export default ReactCropperJS
