import { useMemo } from 'react'
import useField from 'client/hooks/useField'
import ErrorMessage from 'client/components/Formik/ErrorMessage/ErrorMessage'
import FormFieldSection from 'client/components/TranslationForm/TranslatableFormFieldSection'
import FormField from 'client/components/Form/FormField/FormField'
import TimezoneSelect from 'client/components/TimezoneSelect/TimezoneSelect'
import { t } from 'client/i18n'
import ToggleField from 'client/components/Form/ToggleField/ToggleField'
import styled from 'styled-components'
import { DateRange } from 'react-day-picker'
import { format, getHours, getMinutes, set, subDays } from 'date-fns'
import SingleDateTextInputField from 'client/components/DateRangeInput/SingleDateTextInputField'
import { getOptionByValue, HoursInputSelect } from 'client/components/HoursInputSelect'
import Expiration from 'client/screens/Catalog/forms/shared/Expiration'
import { Trans } from 'react-i18next'
import TimeZoneContextualHelp from 'client/screens/Catalog/forms/shared/TimeZoneContextualHelp'

function extractTime(date: Date | undefined): string {
  if (!date) {
    return '00:00'
  }

  return format(date, 'HH:mm')
}

function getHoursAndMinutesFromTime(time: string) {
  const [hoursString, minutesString] = time.split(':')

  return {
    hours: Number(hoursString),
    minutes: Number(minutesString)
  }
}

function setHoursAndMinutesBasedOnTimeString(date: Date, time: string) {
  return set(date, getHoursAndMinutesFromTime(time))
}

function setHoursAndMinutesBasedOnExistingDate(newDate: Date, currentDate: Date | undefined) {
  if (!currentDate) {
    return newDate
  }

  const hours = getHours(currentDate)
  const minutes = getMinutes(currentDate)

  return set(newDate, { hours, minutes })
}

const DateFieldsContainer = styled.div`
  display: flex;
  column-gap: var(--spacing-small);
`

const DateTimeContainer = styled.div`
  display: flex;
  column-gap: var(--spacing-xsmall);
`

const StyledHoursInputSelect = styled(HoursInputSelect)`
  margin-top: var(--spacing-xsmall);
`

const EnDashContainer = styled.div`
  padding-top: 37px;
`
const StyledToggleField = styled(ToggleField)`
  padding-top: var(--spacing-small);
  margin-bottom: var(--spacing-small);
`

interface IDateAndTimeInputProps {
  value: DateRange | undefined
  onChange: (dateRange: DateRange | undefined) => void
  hasError?: boolean
  disabled?: boolean
  disableAfterToday?: boolean
  enableTimeSelect?: boolean
  hasTimeError?: boolean
}

function DateAndTimeInput(props: IDateAndTimeInputProps) {
  const { value, onChange, enableTimeSelect, hasError, disabled, disableAfterToday, hasTimeError } =
    props

  const { to, from } = value || {}

  // Used to disable selecting `today`. react-day-picker treats the `after` property
  // in { disabled: { before, after }} as exclusive
  const yesterday = useMemo(() => subDays(new Date(), 1), [])

  const startDateDisabledDates = useMemo(() => {
    if (to) {
      return { after: to }
    }
    if (disableAfterToday) {
      return { after: yesterday }
    }
  }, [to, disableAfterToday, yesterday])

  const endDateDisabledDates = useMemo(() => {
    const after = disableAfterToday ? yesterday : undefined
    if (from) {
      return { before: from, after }
    }
    if (after) {
      return { after }
    }
  }, [from, disableAfterToday, yesterday])

  const startTime = useMemo(() => extractTime(from), [from])
  const endTime = useMemo(() => extractTime(to), [to])

  return (
    <DateFieldsContainer>
      <DateTimeContainer>
        <SingleDateTextInputField
          label={t('* Start Date')}
          value={from}
          selectedRange={{ from, to }}
          hasError={hasError}
          disabled={disabled}
          disabledDays={startDateDisabledDates}
          onChange={(date) =>
            onChange({
              from: date ? setHoursAndMinutesBasedOnExistingDate(date, from) : undefined,
              to
            })
          }
        />
        {enableTimeSelect && (
          <FormField label={t('Start Time')} inline={true} disabled={disabled}>
            <StyledHoursInputSelect
              value={getOptionByValue(startTime)}
              is24HourDisabled={true}
              hasError={hasTimeError}
              onChange={(e) => {
                const newDate = setHoursAndMinutesBasedOnTimeString(
                  from ?? new Date(),
                  e.target.value
                )
                onChange({
                  from: newDate,
                  to
                })
              }}
            />
          </FormField>
        )}
      </DateTimeContainer>
      <EnDashContainer>–</EnDashContainer>
      <DateTimeContainer>
        <SingleDateTextInputField
          label={t('* End Date')}
          value={to}
          selectedRange={{ from, to }}
          hasError={hasError}
          disabled={disabled}
          disabledDays={endDateDisabledDates}
          onChange={(date) =>
            onChange({
              from,
              to: date ? setHoursAndMinutesBasedOnExistingDate(date, to) : undefined
            })
          }
        />

        {enableTimeSelect && (
          <FormField label={t('End Time')} inline={true} disabled={disabled}>
            <StyledHoursInputSelect
              value={getOptionByValue(endTime)}
              is24HourDisabled={true}
              hasError={hasTimeError}
              onChange={(e) => {
                const newDate = setHoursAndMinutesBasedOnTimeString(
                  to ?? new Date(),
                  e.target.value
                )
                onChange({
                  from,
                  to: newDate
                })
              }}
            />
          </FormField>
        )}
      </DateTimeContainer>
    </DateFieldsContainer>
  )
}

const DisplayDateTime = () => {
  const { value: from, setValue: setFrom } = useField('from')
  const { value: to, setValue: setTo } = useField('to')
  const { value: lastExpiredDate, setValue: setLastExpiredDate } = useField('lastExpiredDate')
  const { setValue: setTimezone } = useField('timezone')
  const { value: isAllDay } = useField('isAllDay')
  const { error: fieldError, touched: fieldTouched } = useField('displayPeriod')
  const hasDateError = fieldError ? fieldTouched : false
  const dateRange: DateRange = useMemo(() => ({ from, to }), [to, from])
  const { value: expirationEnabled } = useField('expirationEnabled')

  // Set timeCheck field to trigger time's yup validation, the value of this field is not used.
  const {
    setValue: setTimeCheckValue,
    error: timeCheckError,
    touched: timeCheckTouched,
    setTouched: setTimeCheckTouched
  } = useField('timeCheck')
  const hasTimeError = timeCheckError ? timeCheckTouched : false

  const handleDateChange: IDateAndTimeInputProps['onChange'] = async (range) => {
    // Please note that formik includes a new type update inside a newer version. Essensially what setFrom does inside formik actually returns a promise
    // The reason we need to await here is because the from value is not updated at the time we validate the timeCheck. We first ensure "from" value is updated, then we can set the "to" value.
    await setFrom(range?.from)
    setTo(range?.to)
    // For an event that's expired before, extending it to another date will set lastExpiredDate to null so that it can be expired again.
    if (lastExpiredDate && range?.to && range?.to > new Date(lastExpiredDate)) {
      setLastExpiredDate(null)
    }
    // manually set timeCheck value and touched to true to trigger validation
    await setTimeCheckTouched(true)
    setTimeCheckValue(true)
  }

  const handleTimezoneChange = (newTimezone: string) => {
    setTimezone(newTimezone)
  }

  return (
    <FormFieldSection label={t('Date/Time')} translatable={false}>
      <StyledToggleField name="isAllDay" label={t('All Day')} />
      <FormField>
        <DateAndTimeInput
          value={dateRange}
          onChange={handleDateChange}
          enableTimeSelect={!isAllDay}
          hasError={hasDateError}
          hasTimeError={hasTimeError}
        />
        {hasDateError && <ErrorMessage name="displayPeriod" />}
        {hasTimeError && <ErrorMessage name="timeCheck" />}
      </FormField>
      <FormField label={t('Time Zone')} additionalLabelNode={TimeZoneContextualHelp}>
        <TimezoneSelect name="timezone" onChange={handleTimezoneChange} />
      </FormField>
      {expirationEnabled && (
        <FormField label={t('Expiration')} description={<Trans i18nKey="expireEventDescription" />}>
          <Expiration />
        </FormField>
      )}
    </FormFieldSection>
  )
}

export default DisplayDateTime
