import _ from 'lodash'
import { TestContext } from 'yup'

export function createHasMediaProcessingErrorTest<TValue = any>(name: string, message: string) {
  return {
    name: `${name}-media-processing-error`,
    test: (value: TValue, context: TestContext) => {
      const { processingError, file } = context.parent
      return !_.isEmpty(processingError) && !file
        ? context.createError({
            message
          })
        : true
    }
  }
}

export function createRequiredIfNoProcessingErrorTest<TValue = any>(name: string, message: string) {
  return {
    name: `${name}-required-if-no-processing-error`,
    test: (value: TValue, context: TestContext) => {
      const { isProcessing, processingError } = context.parent
      return !isProcessing && _.isEmpty(processingError) && !value
        ? context.createError({
            message
          })
        : true
    }
  }
}
