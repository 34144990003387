import styled from 'styled-components'
import { Resizable, ResizableProps } from 'react-resizable'

export interface IResizableProps {
  width: number
  height: number
  onResize: ResizableProps['onResize']
  onResizeEnd: ResizableProps['onResizeStop']
  style: React.CSSProperties | undefined
}

const Container = styled.div`
  .react-resizable {
    position: relative;
  }
  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIEZpcmV3b3JrcyBDUzYsIEV4cG9ydCBTVkcgRXh0ZW5zaW9uIGJ5IEFhcm9uIEJlYWxsIChodHRwOi8vZmlyZXdvcmtzLmFiZWFsbC5jb20pIC4gVmVyc2lvbjogMC42LjEgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCgl4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4PSIwcHgiIHk9IjBweCINCgl3aWR0aD0iMTBweCIgaGVpZ2h0PSIxMHB4Ij4NCgk8ZyBvcGFjaXR5PSIxIj4NCgkJPHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9InJnYigzNCwgMTE3LCAyMTEpIiAvPg0KCTwvZz4NCjwvc3ZnPg0K');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
  }
  .react-resizable-handle-se {
    bottom: -8px;
    right: -8px;
  }
  .react-resizable-handle-sw {
    bottom: -8px;
    left: -8px;
    cursor: sw-resize;
    transform: rotate(90deg);
  }

  .react-resizable-handle-nw {
    top: -8px;
    left: -8px;
    cursor: nw-resize;
    transform: rotate(180deg);
  }

  .react-resizable-handle-ne {
    top: -8px;
    right: -8px;
    cursor: ne-resize;
    transform: rotate(270deg);
  }
`

export default (props: IResizableProps) => {
  const { width, height, onResizeEnd, onResize, style } = props

  return (
    <Container>
      <Resizable
        width={width}
        height={height}
        onResize={onResize}
        lockAspectRatio={true}
        onResizeStop={onResizeEnd}
        resizeHandles={['se', 'sw', 'ne', 'nw']}
      >
        {/* React-resizable library requires a child element */}
        <div style={style} />
      </Resizable>
    </Container>
  )
}
