import { memo, useState } from 'react'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import styled from 'styled-components'
import { t } from 'client/i18n'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useBuildingList from 'client/screens/AppEditor/MapEditor/useBuildingList'
import _ from 'lodash'
import { confirm } from 'client/redux/actions/confirmation'
import { getBuildingCreateConfirmationMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/exteriorMap'
import {
  ExteriorMapSuccess,
  ExteriorMapUpload
} from 'client/screens/AppEditor/MapEditor/ExteriorMapUpload'
import { usePost } from 'client/hooks/api'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import { refetchActiveQueries } from 'client/apollo'
import { GQLMap } from 'shared/graphql/types/graphql'
import { useExteriorMap } from 'client/screens/AppEditor/MapEditor/useExteriorMap'

const AddBuildingButton = styled(PlusIconAddButton)`
  flex-shrink: 0;
  margin: 16px;
`

const AddBuilding = memo(() => {
  const [showUploadExteriorDialog, setShowUploadExteriorDialog] = useState(false)
  const [showSuccessUploadExteriorDialog, setShowSuccessUploadExteriorDialog] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { exteriorMap } = useExteriorMap()
  const buildings = useBuildingList()
  const needsExteriorMap = !_.isEmpty(buildings) && _.isEmpty(exteriorMap)

  const [createExterior, isUploadingExteriorMap] = usePost<GQLMap>('/maps/exterior', {
    onSuccess: async (data) => {
      // The reason is we want to await the front end get the created exterior before navigating to the exterior map preview
      await refetchActiveQueries()
      navigate(`/app-editor/maps/exterior/${data.id}`)
      setShowSuccessUploadExteriorDialog(true)
      dispatch(showChangesSavedToast())
    },
    onError: () => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: t("We weren't able to upload the exterior map. Please try again later."),
          isAlert: true
        })
      )
    }
  })

  const { message, title } = getBuildingCreateConfirmationMessage()

  const handleImageChange = async (file: File) => {
    setShowUploadExteriorDialog(false)
    const formData = new FormData()
    formData.append('image', file)
    await createExterior(formData)
  }

  return (
    <>
      <AddBuildingButton
        type="primary"
        label={t('Add Building')}
        onClick={() => {
          if (needsExteriorMap) {
            setShowUploadExteriorDialog(true)
          } else {
            navigate('buildings/new')
          }
        }}
      />
      {showUploadExteriorDialog && (
        <ExteriorMapUpload
          onFileChange={handleImageChange}
          onCancel={() => setShowUploadExteriorDialog(false)}
          message={message}
          title={title}
        />
      )}
      {showSuccessUploadExteriorDialog && (
        <ExteriorMapSuccess
          onContinue={() => {
            navigate('buildings/new')
            setShowSuccessUploadExteriorDialog(false)
          }}
          label={t('Add Building')}
        />
      )}
      {isUploadingExteriorMap && <LoadingOverlay />}
    </>
  )
})

export default AddBuilding
