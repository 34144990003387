import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const ITEMS_AND_EXHIBITS_LOCATION_QUERY = gql`
  query itemsAndExhibitsLocationQuery($museumId: Int!) {
    museum(id: $museumId) {
      id
      exteriorMap {
        isGoogleMap
      }
      items {
        id
        title
        type
        scientificName
        commonName
        published
        uuid
        itemMapLocation {
          mapLocation {
            id
          }
        }
        images {
          id
          thumbnail
          hero
          full
        }
        locales {
          code
          englishName
        }
      }
      exhibits {
        id
        type
        title
        published
        uuid
        displayPeriod
        from
        to
        timezone
        isAllDay
        exhibitMapLocations {
          mapLocation {
            id
            mapId
          }
        }
        images {
          id
          thumbnail
          hero
          full
        }
        locales {
          code
          englishName
        }
      }
    }
  }
`

const useItemsAndExhibits = () => {
  const { data, loading, error } = useQuery(ITEMS_AND_EXHIBITS_LOCATION_QUERY)

  const items = data?.museum?.items
  const exhibits = data?.museum?.exhibits
  const isGoogleMap = data?.museum?.exteriorMap?.isGoogleMap

  return { items, exhibits, isGoogleMap, loading, error }
}

export default useItemsAndExhibits
