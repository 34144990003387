import EnhancedStandardForm from 'client/components/StandardForm/EnhancedStandardForm'
import { t } from 'client/i18n'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/client'
import useNumericRouteParam from 'client/hooks/useNumericRouteParam'
import { BuildingFormSchemaClient, BuildingSchemaCore } from 'client/validation/Map'
import TextInput from 'client/components/Formik/TextInput/TextInput'
import { getCreateSuccessMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/buildings'
import { confirm } from 'client/redux/actions/confirmation'
import FormFieldSection from 'client/components/Form/FormField/FormFieldSection'
import gql from 'graphql-tag'
import { GQLBuilding } from 'shared/graphql/types/graphql'
import _ from 'lodash'
import { useExteriorMap } from 'client/screens/AppEditor/MapEditor/useExteriorMap'
import { usePost, usePut } from 'client/hooks/api'
import { IBuildingJson } from 'shared/json/IBuildingJson'
import { refetchActiveQueries } from 'client/apollo'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { LatitudeContextualHelp, LongitudeContextualHelp } from '../GoogleMapContextualHelp'

const BUILDING_QUERY = gql`
  query getSingleBuilding($id: Int!) {
    building(id: $id) {
      id
      uuid
      name
      mapLocation {
        id
        latitude
        longitude
      }
    }
  }
`

type BuildingFormValuesType = {
  name: string
  latitude: number
  longitude: number
}

const BuildingForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const buildingId = useNumericRouteParam('editBuildingId')
  const { exteriorMap } = useExteriorMap()
  const isGoogleMap = exteriorMap?.isGoogleMap ?? false

  const [updateBuilding, isUpdatingBuilding] = usePut<IBuildingJson>(
    `/maps/building/${buildingId}`,
    {
      onSuccess: () => {
        refetchActiveQueries()
        dispatch(showChangesSavedToast())
      },
      onError: () => {
        dispatch(
          confirm({
            title: t('Unable to Save Changes'),
            message: t("We weren't able to update the building. Please try again later."),
            isAlert: true
          })
        )
      }
    }
  )

  const [createBuilding, isCreatingBuilding] = usePost<IBuildingJson>('/maps/building', {
    onSuccess: () => {
      refetchActiveQueries()
      const { message, title } = getCreateSuccessMessage(!_.isEmpty(exteriorMap), isGoogleMap)
      dispatch(
        confirm({
          title,
          message,
          isAlert: true
        })
      )
      dispatch(showChangesSavedToast())
    },
    onError: () => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: t("We weren't able to create the building. Please try again later."),
          isAlert: true
        })
      )
    }
  })

  const onSubmit = async (values: BuildingFormValuesType) => {
    if (buildingId) {
      await updateBuilding({
        building: {
          id: buildingId,
          name: values.name,
          mapLocation: {
            latitude: values.latitude,
            longitude: values.longitude
          }
        }
      })
    } else {
      await createBuilding({
        building: { name: values.name, latitude: values.latitude, longitude: values.longitude }
      })
    }
    // TODO: need to handle navigate back for all map forms in EnhancedStandardForm
    navigate('..')
  }

  const getBuildingDescriptionText = () => {
    return isGoogleMap
      ? t('To move the building’s pin, adjust the latitude and longitude coordinates.')
      : t('You can set the building’s location by dragging the pin on the map.')
  }

  const { data, loading: isFetchedBuilding } = useQuery<{ building: GQLBuilding }>(BUILDING_QUERY, {
    variables: { id: buildingId },
    skip: _.isNil(buildingId)
  })
  const initialValues = {
    name: data?.building?.name,
    latitude: data?.building?.mapLocation?.latitude,
    longitude: data?.building?.mapLocation?.longitude
  }

  return (
    <EnhancedStandardForm
      contentName="Building"
      contentId={buildingId}
      validationSchema={isGoogleMap ? BuildingFormSchemaClient : BuildingSchemaCore}
      initialValues={initialValues}
      onSubmit={onSubmit}
      isLoading={isUpdatingBuilding || isCreatingBuilding || isFetchedBuilding}
    >
      <FormFieldSection
        label={t('Building')}
        description={getBuildingDescriptionText()}
        hideDivider={true}
      />
      <TextInput name="name" label={t('* Name')} />
      {isGoogleMap && (
        <>
          <TextInput
            name="latitude"
            label={t('* Latitude')}
            additionalLabelNode={LatitudeContextualHelp}
          />
          <TextInput
            name="longitude"
            label={t('* Longitude')}
            additionalLabelNode={LongitudeContextualHelp}
          />
        </>
      )}
    </EnhancedStandardForm>
  )
}

export default BuildingForm
