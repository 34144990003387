import { Formik, FormikProps } from 'formik'
import PopupWidget from 'client/components/PopupWidget/PopupWidget'
import FormHeader from 'client/components/Form/FormHeader'
import _ from 'lodash'
import { t } from 'client/i18n'
import styled from 'styled-components'
import { useState, PropsWithChildren, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import DiscardChangesDialog from 'client/components/DiscardChangesDialog'
import useFormikErrors from 'client/hooks/useFormikErrors'
import FormErrorBanner from 'client/dsm/Banner/FormErrorBanner'
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay'

const ContentContainer = styled.div`
  position: relative;
  overflow: auto;
  padding: var(--container-padding);
  background-color: var(--color-white);
  height: 100%;

  > *:first-child {
    &[data-showbackground='false'] {
      padding-top: 0px;
    }
  }
`

type ContentName = 'Building' | 'Map' | 'Pin' | 'Profile'

const ErrorBanner = () => {
  const errors = useFormikErrors()
  return <FormErrorBanner errorMap={errors} />
}

interface IEnhancedStandardFormProps extends PropsWithChildren {
  contentName: ContentName
  contentId?: number
  validationSchema?: any
  initialValues: any
  onSubmit: (values: any) => void
  isLoading?: boolean
  onClose?: () => void
}

const EnhancedStandardForm = (props: IEnhancedStandardFormProps) => {
  const navigate = useNavigate()
  const {
    contentName,
    contentId,
    validationSchema,
    initialValues,
    onSubmit,
    isLoading,
    onClose = () => navigate('..'),
    children
  } = props
  const isFormCreating = _.isNil(contentId)
  // eslint-disable-next-line docent/require-translation-keys-to-be-literals
  const title = t(`${isFormCreating ? 'Add' : 'Edit'} ${contentName}`)
  const [showDiscardChangesDialog, setShowDiscardChangesDialog] = useState(false)

  const handleCancel = useCallback(
    // @ts-ignore DOCNT-10958
    (dirty) => {
      if (dirty) {
        setShowDiscardChangesDialog(true)
      } else {
        onClose()
      }
    },
    [onClose]
  )

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit: formikSubmit, dirty }: FormikProps<any>) => {
        return (
          <PopupWidget zIndex={10000}>
            {isLoading && <LoadingOverlay />}
            {showDiscardChangesDialog && (
              <DiscardChangesDialog
                onCancel={() => setShowDiscardChangesDialog(false)}
                onSave={onClose}
              />
            )}
            <FormHeader
              title={title}
              onCancel={() => handleCancel(dirty)}
              onSave={formikSubmit}
              enableSave={true}
            />
            <ErrorBanner />
            <ContentContainer>{children}</ContentContainer>
          </PopupWidget>
        )
      }}
    </Formik>
  )
}

export default EnhancedStandardForm
