import { formatDuration, intervalToDuration } from 'date-fns'
import i18n from 'client/i18n'

export function formatDurationInHoursMinutesSeconds(seconds: number) {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

  // We need to set these to 0 if they are undefined, otherwise they will not be included in the formatted string
  duration.minutes = duration.minutes ?? 0
  duration.seconds = duration.seconds ?? 0

  const zeroPad = (num: number) => String(num).padStart(2, '0')

  return formatDuration(duration, {
    format: ['hours', 'minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (token, count) => zeroPad(count)
    }
  })
}

export function formatDateRange(
  from: Date,
  to: Date,
  timezone: string,
  includeTime: boolean
): string | null {
  if (!from || !to) {
    return null
  }

  const dateFormatOptions = includeTime ? ({ hour: 'numeric', minute: 'numeric' } as const) : {}

  const { language } = i18n
  return new Intl.DateTimeFormat(language, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    ...dateFormatOptions,
    timeZone: timezone
  }).formatRange(from, to)
}
