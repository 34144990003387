import { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import FloorMapLocation from 'client/screens/AppEditor/MapEditor/MapLocation/FloorMapLocation'
import { t } from 'client/i18n'
import { useExteriorMap } from 'client/screens/AppEditor/MapEditor/useExteriorMap'
import { setPinForMapLocations, updatePin } from 'client/screens/AppEditor/MapEditor/MapEditorUtils'
import { IMapJson } from 'shared/json/IMapJson'
import { ICoordinates } from 'shared/util/maps'
import MapPreview from './MapPreview'
import BuildingMapLocation from './MapLocation/BuildingMapLocation'
import { useBuildings } from './useBuildings'
import { IDragEndEventProps } from './MapLocation/MapLocation'

interface IExteriorMapPreviewProps {
  selectedPinId?: number | null
  onSelectedPinChanged?: (id: number) => void
}

const ExteriorMapLocation = memo((props: any) => {
  const { id, ...rest } = props
  const { buildings } = useBuildings()
  const buildingsByMapLocationId = useMemo(() => {
    return _.keyBy(buildings, 'mapLocationId')
  }, [buildings])
  return !buildingsByMapLocationId[id] ? (
    <FloorMapLocation id={id} {...rest} />
  ) : (
    <BuildingMapLocation id={id} {...rest} />
  )
})

const ExteriorMapPreview = (props: IExteriorMapPreviewProps) => {
  const { selectedPinId, onSelectedPinChanged = _.noop } = props
  const { exteriorMap } = useExteriorMap()
  const dispatch = useDispatch()

  const [map, setMap] = useState<IMapJson>(exteriorMap)

  useEffect(() => {
    setMap(exteriorMap)
  }, [exteriorMap])

  const handleDragEndLocation = async ({ id, coordinates }: IDragEndEventProps) => {
    const mapLocations = setPinForMapLocations({ id, coordinates, map: exteriorMap })

    setMap({
      ...exteriorMap,
      mapLocations
    })
    await updatePin(exteriorMap.id, { id, coordinates }, dispatch)
  }

  const handleMapLocationResized = async (
    id: number,
    radius: number,
    coordinates?: ICoordinates
  ) => {
    const mapLocations = setPinForMapLocations({ id, radius, map: exteriorMap })
    setMap({
      ...exteriorMap,
      mapLocations
    })

    await updatePin(exteriorMap.id, { id, radius, coordinates }, dispatch)
  }

  return (
    <MapPreview
      title={t('Exterior Map')}
      map={map}
      selectedPinId={selectedPinId}
      onSelectedPinChanged={onSelectedPinChanged}
      onDragEndLocation={handleDragEndLocation}
      onMapLocationResized={handleMapLocationResized}
      mapLocationElement={ExteriorMapLocation}
    />
  )
}

export default ExteriorMapPreview
