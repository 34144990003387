import _ from 'lodash'
import { parse } from 'node-html-parser'
import { Nil } from './types'

function getTextContent(html: string) {
  return parse(html).textContent
}

export function getPlainTextForHtml(value: Nil): null
export function getPlainTextForHtml(value: string): string
export function getPlainTextForHtml(value: string | Nil): string | null
export function getPlainTextForHtml(value: string | Nil) {
  return _.isNil(value) ? null : getTextContent(value)
}

export const getCharacterCountForHtml = (value: string): number =>
  value ? getPlainTextForHtml(value).length : 0
