import { useState, useRef, RefObject, useEffect, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import StandardForm from 'client/components/StandardForm/StandardForm'
import './CropperJSOverrides.css'
import Tips from 'client/screens/AppEditor/ImageControls/Tips'
import {
  SplashScreenPreviewFooter,
  PreviewImageAspectRatio,
  SplashPreviewConnectsLogoWidth
} from 'shared/constants/splash'
import _ from 'lodash'
import BPFooterLogo from 'client/assets/svg/logo_digitalExBy_BP.svg'
import ImageCropper from 'client/components/ImageCropper/ImageCropper'
import useField from 'client/hooks/useField'
import { useErrorDialog } from 'client/components/ErrorDialog'
import { usePut } from 'client/hooks/api'
import { t } from 'client/i18n'
import {
  TipsContainer,
  Footer,
  FooterContainer,
  footerBottomMargin,
  cropBoxTopMargin
} from './styledComponents'
import ErrorOverlay from './ErrorOverlay'
import { DEFAULT_SPLASH_BACKGROUND_CROPPING } from '../constants'
import { IFormValues } from '../types'

const TIPS = [
  t('Images can be resized and moved around the canvas.'),
  t(
    'The preview screen only displays one of the common screen sizes. The positioning of the visual might vary depending on different sizes of the screen.'
  )
]

type BackgroundImageFieldType = IFormValues['backgroundImage']

const SplashBackgroundForm = () => {
  const { value, setValue } = useField<BackgroundImageFieldType>('backgroundImage')
  const { sourceUrl, cropState, file } = value

  const containerRef: RefObject<HTMLDivElement> = useRef(null)
  const imageCropperRef: RefObject<ImageCropper> = useRef(null)
  const [errorDialog, setError] = useErrorDialog()
  const navigate = useNavigate()

  const layoutHeight = _.get(containerRef, 'current.offsetHeight', 0)
  const layoutWidth = _.get(containerRef, 'current.offsetWidth', 0)
  const height = Math.max(
    layoutHeight - SplashScreenPreviewFooter.height - footerBottomMargin - 20, // Cropper library uses css transform to add a 20px margin above the crop box
    0
  )

  const width = PreviewImageAspectRatio * height
  const left = Math.max(layoutWidth * 0.5 - width * 0.5, 0)

  const cropBoxData = useMemo(
    () => ({ width, height, left, top: cropBoxTopMargin }),
    [width, height, left]
  )

  const [localCropState, setLocalCropState] = useState(cropState)

  // This is a put method, but effectively work as a get method, hence naming it as getBackgroundPreview here.
  const [getBackgroundPreview, isGettingBackgroundPreview] = usePut('/splash/background-preview', {
    onSuccess: (response) => {
      const { buffer } = response
      const uri = `data:image/jpeg;base64,${buffer}`
      setValue({
        ...value,
        url: uri,
        cropState: localCropState
      })
      navigate('/app-editor/brand-assets/splash')
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log('Error creating edited splash image', error)
      setError({
        error: "We weren't able to edit the Splash background image. Please try again later.",
        title: t('Unable to Save Changes')
      })
    }
  })

  const isPopulatedCropState = (currentCropState: BackgroundImageFieldType['cropState']) =>
    currentCropState?.canvasData && currentCropState?.zoomRatio && currentCropState?.cropData

  useEffect(() => {
    if (layoutWidth) {
      setLocalCropState((curLocalCropState) => {
        if (!isPopulatedCropState(curLocalCropState)) {
          return { ...curLocalCropState, cropBoxData }
        }
        return curLocalCropState
      })
      if (imageCropperRef && imageCropperRef.current) {
        /*
            Because the popup widget has multiple renders at various widths
            I have to explicitly set the crop box value without altering the canvas data

            The ImageCropper does not respond to cropState changes, and in the interest
            of minimizing the amount of code touched, this direct manipulation resolves this issue.
          */
        imageCropperRef.current.setCropBox(cropBoxData)
      }
    }
  }, [layoutWidth, cropBoxData])

  const onSave = async () => {
    const shouldSaveCropping = isPopulatedCropState(localCropState)
    const didCroppingChange =
      !_.isNil(localCropState?.cropData) &&
      !_.isEqual(localCropState?.cropData, cropState?.cropData)
    if (shouldSaveCropping && didCroppingChange) {
      const formData = new FormData()
      // if this is a new image upload, request must include a file
      if (file) {
        formData.append('image', file)
      }
      formData.append('cropData', JSON.stringify(localCropState.cropData))
      await getBackgroundPreview(formData)
    }
  }

  const cropIsValid = (cropEvent: BackgroundImageFieldType['cropState']) => {
    if (
      !_.has(cropEvent?.cropData, 'top') ||
      !_.has(cropEvent?.canvasData, 'height') ||
      !_.has(cropEvent?.canvasData, 'top')
    ) {
      return true
    }
    const verticalOffsetsAreValid = _.get(cropEvent, 'cropBoxData.top') === cropBoxTopMargin
    const horizontalOffsetsAreValid = _.get(cropEvent, 'cropBoxData.left') === left

    return verticalOffsetsAreValid && horizontalOffsetsAreValid
  }

  const onCancel = useCallback(() => navigate('/app-editor/brand-assets/splash'), [navigate])

  // prevent deep-linking to this form when no image exists
  useEffect(() => {
    if (_.isNil(sourceUrl)) {
      onCancel()
    }
  }, [sourceUrl, onCancel])

  return (
    <>
      <StandardForm
        enableSave={cropIsValid(localCropState)}
        onSave={onSave}
        onCancel={onCancel}
        title={t('Splash Screen / Edit Image')}
        saveButtonLabel={t('Apply')}
        fullWidth={true}
        isLoading={isGettingBackgroundPreview}
        isLarge={true}
      >
        <div ref={containerRef} style={{ height: '100%' }} className="splash-cropper">
          <ImageCropper
            ref={imageCropperRef}
            handleReset={() =>
              setLocalCropState({ ...DEFAULT_SPLASH_BACKGROUND_CROPPING, cropBoxData })
            }
            viewControlsBottomPosition={145}
            cropState={localCropState}
            onCropError={onCancel}
            onChange={setLocalCropState}
            hasStaticCropper={true}
            aspect={PreviewImageAspectRatio}
            image={sourceUrl}
            renderFooter={() => (
              <FooterContainer>
                <Footer width={width} left={left}>
                  <BPFooterLogo style={{ width: SplashPreviewConnectsLogoWidth, height: 'auto' }} />
                </Footer>
              </FooterContainer>
            )}
          />
          <ErrorOverlay
            width={width}
            height={height}
            left={left}
            active={!cropIsValid(localCropState)}
          />
        </div>
        <TipsContainer>
          <Tips tips={TIPS} />
        </TipsContainer>
      </StandardForm>
      {errorDialog}
    </>
  )
}

export default SplashBackgroundForm
