import * as React from 'react'
import { List as VirtualizedList, AutoSizer } from 'react-virtualized'

interface Props<T> {
  items: T[]
  rowRenderer: (item: T) => React.ReactNode
  rowHeight?: number
}

const List = <T,>({ items, rowRenderer, rowHeight = 106 }: Props<T>) => {
  // @ts-ignore DOCNT-10958
  const rowRenderWrapper = ({ key, index, style }) => {
    return (
      <div key={key} style={style}>
        {rowRenderer(items[index])}
      </div>
    )
  }
  return (
    <AutoSizer>
      {({ height, width }) => (
        <VirtualizedList
          width={width}
          height={height}
          rowCount={items.length}
          rowHeight={rowHeight}
          rowRenderer={rowRenderWrapper}
        />
      )}
    </AutoSizer>
  )
}

export default List
