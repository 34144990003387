import Tooltip from 'client/dsm/Tooltip/Tooltip'
import _ from 'lodash'
import { ReactNode, useContext } from 'react'
import { GQLVideo } from 'shared/graphql/types/graphql'
import styled, { ThemeContext } from 'styled-components'
import useGuideDefaultLocale from 'client/components/Languages/useGuideDefaultLocale'
import { ThemeType, IThemeObject } from 'client/types'
import { Body1CSS } from 'client/components/TextStyles'
import { t } from 'client/i18n'
import { TKey } from 'shared/i18n/types/translationResources'

const Text = styled.div<{ themeType: ThemeType }>`
  ${Body1CSS};
  color: ${({ themeType }) =>
    themeType === ThemeType.DARK ? 'var(--color-black)' : 'var(--color-grey-03)'};
  border: 1px solid
    ${({ themeType }) =>
      themeType === ThemeType.DARK ? 'var(--color-black)' : 'var(--color-grey-03)'};
  padding: 0px 4px;
  font-size: 10px;
  line-height: 1.5;
  font-weight: 700;
  border-radius: 2px;
  font-family: system-ui;
  margin: 0px;
  top: 4px;
  cursor: pointer;
  :hover {
    border: 1px solid
      ${({ themeType }) =>
        themeType === ThemeType.DARK ? 'var(--color-blue-07)' : 'var(--color-white)'};
    color: ${({ themeType }) =>
      themeType === ThemeType.DARK ? 'var(--color-blue-07)' : 'var(--color-white)'};
  }
`

const TooltipHeader = styled.span`
  font-weight: 700;
  margin: 5px;
`

const TooltipText = styled.span`
  margin: 5px;
`
const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
`

// @ts-ignore DOCNT-10958
const stopEventPropagation = (e) => {
  e.stopPropagation()
}

export interface ILabelTooltipProps {
  children: ReactNode
  tooltipHeader: string
  translations: GQLVideo['translations']
  className?: string
  isPopperInteractive?: boolean
}

const LabelTooltip = ({
  children,
  tooltipHeader,
  translations,
  className,
  isPopperInteractive = false
}: ILabelTooltipProps) => {
  const { loading, defaultLocale } = useGuideDefaultLocale()
  const themeContext: IThemeObject = useContext(ThemeContext)
  const themeType = themeContext?.type ?? ThemeType.DARK

  if (loading || _.isEmpty(translations)) {
    return null
  }

  return (
    <Tooltip
      content={
        <TooltipContent>
          <TooltipHeader>{tooltipHeader}</TooltipHeader>
          {_.sortBy(translations, 'locale.englishName').map((translation) => {
            const { locale } = translation
            const translatedName = t(`language.${locale.code}` as TKey)
            return (
              <TooltipText key={locale.id}>
                {translatedName}
                {defaultLocale.code === locale.code ? ` - ${t('Default')}` : ''}
              </TooltipText>
            )
          })}
        </TooltipContent>
      }
      interactive={isPopperInteractive}
      themeType={themeType}
    >
      <Text
        className={className}
        themeType={themeType}
        onClick={stopEventPropagation}
        onMouseDown={stopEventPropagation}
      >
        {children}
      </Text>
    </Tooltip>
  )
}

export default LabelTooltip
