import { useMemo, useState } from 'react'
import _ from 'lodash'
import { GQLLocale } from 'shared/graphql/types/graphql'
import {
  DocumentNode,
  GraphQLInputObjectType,
  isListType,
  getNamedType,
  isScalarType,
  GraphQLInputFieldMap,
  GraphQLInputField,
  FieldNode,
  OperationDefinitionNode
} from 'graphql'
import createMutationQueries from 'shared/util/gqlMutation'

/**
 * This hook is used to maintain proper ordering and sorting of locales in one place.
 * This also helps when removing locales and selecting the correct locale to select next.
 */
export const useLocales = (defaultLocale: GQLLocale): [GQLLocale[], (l: GQLLocale[]) => void] => {
  const [locales, setSortedLocales] = useState<GQLLocale[]>([])

  const setLocales = (proposedLocales: GQLLocale[]) => {
    const sortedLocales = [
      defaultLocale,
      ..._(proposedLocales).reject({ code: defaultLocale?.code }).sortBy('englishName').value()
    ]

    setSortedLocales(sortedLocales)
  }

  return [locales, setLocales]
}

export interface DialogOptions {
  title?: string
  body?: React.ReactNode
  isBlocking?: boolean
  // TODO: this dialog setup is making this more awkward than it should be
  errorDetails?: string | Error
}

export interface Dialog<T> {
  type?: T
  options?: DialogOptions
}

export const useDialog = <T>(): {
  setCurrentDialog: (type: T, options?: DialogOptions) => void
  isDialog: (type: T) => boolean
  closeDialog: () => void
  currentDialog: Dialog<T>
} => {
  const [currentType, setCurrentType] = useState<T>()
  const [currentOptions, setCurrentOptions] = useState<DialogOptions>()

  const isDialog = (type: T) => _.isEqual(currentType, type)
  const setCurrentDialog = (type: T | undefined, options?: DialogOptions) => {
    setCurrentType(type)
    setCurrentOptions(options)
  }
  const closeDialog = () => setCurrentDialog(undefined, undefined)

  return {
    setCurrentDialog,
    isDialog,
    closeDialog,
    currentDialog: { type: currentType, options: currentOptions }
  }
}

interface DirtyLocaleCodesResponse {
  isDefaultDirty: boolean
  translations: {
    editedOrAdded: string[] // array of locale codes; Ex: ['en-US, 'es-ES']
    removed: string[]
  }
}

// Used to check a key within Formik objects -- values, errors, and touched.
export const isLocaleCode = (key: string) => /[a-z]{2}-[A-Z]{2}/.test(key)

/**
 * Shift nontranslatable fields to be included as part of the default locale.
 * Used to simplify logic that reads values, errors, and touched objects.
 *
 * // BEFORE
 * {
 *  nonTranslatableField: 'foo',
 *  [defaultLocale.code]: {
 *    translatableField: 'bar'
 *  },
 *  [otherLocale.code]: { translatableField: 'baz' }
 * }
 *
 * // AFTER
 * {
 *  [defaultLocale.code]: {
 *    nonTranslatableField: 'foo',
 *    translatableField: 'bar'
 *  },
 *  [otherLocale.code]: { translatableField: 'baz' }
 * }
 */
// @ts-ignore DOCNT-10958
export const applyNontranslatableFieldToDefaultLocale = (values, defaultLocale: GQLLocale) => {
  return _.reduce(
    values,
    (acc, value, key) => {
      if (isLocaleCode(key)) {
        // @ts-ignore DOCNT-10958
        acc[key] = {
          // @ts-ignore DOCNT-10958
          ...acc[key],
          ...value
        }
      } else {
        // @ts-ignore DOCNT-10958
        acc[defaultLocale.code] = {
          // @ts-ignore DOCNT-10958
          ...acc[defaultLocale.code],
          [key]: value
        }
      }
      return acc
    },
    {}
  )
}

/**
 * const company = {
 *  name: 'Company',
 *  workers: [{ id: '1', name: 'Worker One'}, { id: 2, name: Worker Two }]
 * }
 *
 * Lodash _.pick requires that you generate a path for each worker by index.
 * Ex: paths = [ company.name, 'company.workers[0].name', 'company.workers[1].name', ... ]
 *
 * This function handles the arrays automatically for you, without needing to build a path for each worker.
 * Ex: paths = [ company.name, 'company.workers[].name' ]
 */
// @ts-ignore DOCNT-10958
export const pickPathsWithArrays = (obj, paths: string[]) => {
  return _.reduce(
    paths,
    // @ts-ignore DOCNT-10958
    (acc, path) => {
      if (path.includes('[].')) {
        const [pathToArray, arrayPaths] = _.split(path, /\[]\.(.+)/)
        const arrayOfStuff = _.get(obj, pathToArray)
        // @ts-ignore DOCNT-10958
        const pickedArray = _.map(arrayOfStuff, (stuff) => pickPathsWithArrays(stuff, [arrayPaths]))
        // @ts-ignore DOCNT-10958
        const pickedArrayResult = _.set({}, pathToArray, pickedArray)

        return _.merge(acc, pickedArrayResult)
      }

      /*
        This block is to handle a situation like

        obj = {
          foo: null
        }

        paths = [
          'foo.bar.baz'
        ]

        In this case, we want `foo: null` in the output object. Without this check, we'd end up with `foo: undefined`.
        Our mutations treat `undefined` as "ignore this", which is not what we want if we have a `null` value in our formik values.
       */
      if (path.includes('.')) {
        const startingPath = _(path).split('.').first()!
        // @ts-ignore DOCNT-10958
        if (_.isNull(obj[startingPath]) && _.isUndefined(acc[startingPath])) {
          return _.merge(acc, { [startingPath]: null })
        }
      }
      const val = _.pick(obj, path)
      return _.merge(acc, val)
    },
    {}
  )
}

const getFieldPaths = (field: GraphQLInputField, name: string): string[] => {
  const fieldType = field?.type as GraphQLInputObjectType
  const typeOfField = getNamedType(fieldType) as GraphQLInputObjectType

  // Checking for both of these, because if a scalar is wrapped in GraphQLNonNull, they are both scalar.
  // Example: audios: { id: Int }
  // 'id' is a scalar, but it is firstly a GraphQLNonNull (a scalar) that wraps an Int (a scalar).
  if (isScalarType(fieldType) || isScalarType(typeOfField)) {
    return [name]
  }

  const subfields = typeOfField?.getFields()

  const getFieldName = (fieldName: string) =>
    `${name}${isListType(fieldType) ? '[]' : ''}.${fieldName}`

  return _.flatMap(subfields, (fieldValue, fieldName) =>
    getFieldPaths(fieldValue, getFieldName(fieldName))
  )
}

/**
 * Example return:
 *
 * const snippetOfExhibitPaths = [
 *  published,
 *  lookupNumber,
 *  images[].id,
 *  items[].lookupNumber,
 *  exhibitMapLocations[].featured,
 *  exhibitMapLocations[].mapLocation.id,
 *  translations[].title,
 *  translations[].information
 * ]
 */
export const getFieldPathsFromMutationInput = _.memoize((inputType: GraphQLInputObjectType) => {
  const fields: GraphQLInputFieldMap = inputType.getFields()
  return _.flatMap(fields, (fieldValue, fieldName) => getFieldPaths(fieldValue, fieldName))
})

// @ts-ignore DOCNT-10958
export const mapValuesToMutationInput = (values, inputType: GraphQLInputObjectType) => {
  const mutationInputPaths = getFieldPathsFromMutationInput(inputType)
  return pickPathsWithArrays(values, mutationInputPaths)
}

export const getFieldsFromMutationInput = (
  input: GraphQLInputObjectType
): {
  translatable: string[]
  nonTranslatable: string[]
} => {
  const fields: GraphQLInputFieldMap = input.getFields()
  const translataionField: GraphQLInputField = _.get(fields, 'translations')
  const translationFieldsType = translataionField?.type as GraphQLInputObjectType
  const translationInputType = getNamedType(translationFieldsType) as GraphQLInputObjectType

  const translatable = _(translationInputType.getFields()).keys().without('localeCode').value()

  const nonTranslatable = _(fields).keys().without('translations').value()

  return { translatable, nonTranslatable }
}

export const useFieldsFromMutationInput = (input: GraphQLInputObjectType) => {
  return useMemo(() => getFieldsFromMutationInput(input), [input])
}

export const getFieldsFromQuery = (query: DocumentNode) => {
  const rootFieldNode: FieldNode = (query?.definitions?.[0] as OperationDefinitionNode)
    ?.selectionSet?.selections?.[0] as FieldNode
  const subFieldNodes: FieldNode[] = rootFieldNode?.selectionSet!.selections as FieldNode[] // { ...nonTranslatableFields, translations: { ...translatableFields } }

  const nonTranslatable = _(subFieldNodes) // { id, nonTranslatableField, translations }
    .map((fieldNode: FieldNode) => fieldNode?.name?.value) // [ 'id', 'nonTranslatableField', 'translations' ]
    // We have to fetch `id` field for Apollo caching, but we don't want to actually include it in Formik values or submit it in mutations
    .without('translations', 'id') // [ 'nonTranslatableField' ]
    .value()

  const translationsFieldNode: FieldNode = _.find(
    subFieldNodes,
    (subFieldNode: FieldNode) => subFieldNode?.name?.value === 'translations'
  )! // { translations }

  if (!translationsFieldNode) {
    return { translatable: [], nonTranslatable }
  }

  const translationsFieldSubNodes: FieldNode[] = translationsFieldNode.selectionSet!
    .selections as FieldNode[] // { translatableFieldA, translatableFieldB }

  const translatable = _(translationsFieldSubNodes)
    .map((fieldNode: FieldNode) => fieldNode?.name?.value) // [ 'translatableFieldA', 'translatableFieldB' ]
    .without('locale')
    .value()

  return { translatable, nonTranslatable }
}

export const useFieldsFromQuery = (query: DocumentNode) => {
  return useMemo(() => getFieldsFromQuery(query), [query])
}

// there is no option for checking if a particular field/value is dirty; Formik author suggests comparing initialValues to values
// https://github.com/formium/formik/issues/215
export const getDirtyLocaleCodes = (
  // @ts-ignore DOCNT-10958
  initialValues,
  // @ts-ignore DOCNT-10958
  values,
  defaultLocale: GQLLocale
): DirtyLocaleCodesResponse => {
  /**
   * Due to the need for different places to know more than just 'which locales have changed (are dirty)',
   * and to avoid repetitive code in multiple places for filtering and calculating, this function has been tasked with
   * returning a more full/explicit data set, which makes the lives of other functions easier.
   */

  const updatedInitialValues = applyNontranslatableFieldToDefaultLocale(
    initialValues,
    defaultLocale
  )
  const updatedValues = applyNontranslatableFieldToDefaultLocale(values, defaultLocale)

  const defaultLocaleCode = defaultLocale?.code
  const isDefaultDirty = !_.isEqual(
    _.get(updatedInitialValues, defaultLocaleCode),
    _.get(updatedValues, defaultLocaleCode)
  )
  const removed = _(updatedInitialValues).keysIn().difference(_.keysIn(updatedValues)).value()

  // handles edited and added
  const editedOrAdded = _(updatedValues)
    .keysIn()
    .difference([defaultLocaleCode])
    // @ts-ignore DOCNT-10958
    .filter((key) => !_.isEqual(updatedInitialValues[key], updatedValues[key]))
    .value()

  return {
    isDefaultDirty,
    translations: {
      editedOrAdded,
      removed
    }
  }
}

export const createApiConfig = (typeName: string, fetchQuery: DocumentNode) => {
  return {
    query: fetchQuery,
    mutation: createMutationQueries(typeName)
  }
}
