import styled from 'styled-components'
import {
  GQLAudio,
  GQLCreator,
  GQLExhibit,
  GQLImage,
  GQLItem,
  GQLVideo
} from 'shared/graphql/types/graphql'
import { getItemDescription, isItemContent } from 'shared/util/items'
import { IGetMapLocationContent } from 'client/types'
import IGuidePickerJson from 'shared/json/IGuidePickerJson'
import AudioDescriptionLabelTooltip from 'client/components/Languages/AudioDescriptionLabelTooltip'
import { getCreatorDisplayName } from 'client/util/creators'
import DownloadAssetIconButton from 'client/screens/Catalog/grids/shared/DownloadAssetIconButton'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import AudioPlaceholderSVG from 'client/assets/svg/emptyStates/audio_no_image_rectangle.svg'
import CreatorPlaceholderSVG from 'client/assets/svg/emptyStates/creator_no_image_rectangle.svg'
import SubtitlesPlaceholderSVG from 'client/assets/svg/emptyStates/subtitles_captions_no_image_rectangle.svg'
import VideoPlaceholderSVG from 'client/assets/svg/emptyStates/video_no_image_rectangle.svg'
import ImagePlaceholderSVG from 'client/assets/svg/emptyStates/no_image_rectangle.svg'
import { getItemOrExhibitImageHero, getItemOrExhibitImageThumbnail } from 'client/util/images'
import _ from 'lodash'
import { IReorderableRowWrapperProps } from 'client/components/ContentRow/types'
import { getExhibitDescription } from 'client/util/exhibits'
import { t } from 'client/i18n'
import { getItemOrExhibitTags } from 'client/util/contentTags'
import { formatDurationInHoursMinutesSeconds } from 'client/util/dates'
import { AssetType } from 'shared/constants/asset'
import { HeaderSubheaderContainer } from 'client/components/ContentRow/HeaderSubheaderContainer'
import { FullContentRow } from 'client/components/ContentRow/FullContentRow'
import { ReorderableRowWrapper } from 'client/components/ContentRow/ReorderableRowWrapper'
import {
  HeaderSubheaderWrapper,
  LowerContent,
  NoteIconsContainer,
  RemoveButton
} from './styledComponents'
import GlobeIconTooltip from '../Languages/GlobeIconTooltip'
import ClosedCaptionLabelTooltip from '../Languages/ClosedCaptionLabelTooltip'
import { ContentRow } from './ContentRenderer'

const StyledRemoveButton = styled.div`
  padding-top: 10px;
`

export interface IReorderableRowProps<T> extends IReorderableRowWrapperProps<T> {
  value: T
  index?: number
}

export const ReorderableItemRow = (props: IReorderableRowProps<GQLItem>) => {
  const { value, isCompact, isSelected } = props
  const { header, subheaders } = getItemDescription(value)
  const image = isCompact ? getItemOrExhibitImageThumbnail(value) : getItemOrExhibitImageHero(value)
  const tags = getItemOrExhibitTags(value)

  return (
    <ContentRow
      {...props}
      header={header}
      subheaders={subheaders}
      image={image}
      tags={tags}
      note={<GlobeIconTooltip locales={value.locales} />}
      isCompact={isCompact}
      isSelected={isSelected}
    />
  )
}

export const ReorderableExhibitRow = (props: IReorderableRowProps<GQLExhibit>) => {
  const { value, isCompact, isSelected } = props
  const { header, subheaders } = getExhibitDescription(value)
  const image = isCompact ? getItemOrExhibitImageThumbnail(value) : getItemOrExhibitImageHero(value)
  const tags = getItemOrExhibitTags(value)

  return (
    <ContentRow
      {...props}
      header={header}
      subheaders={subheaders}
      image={image}
      tags={tags}
      note={<GlobeIconTooltip locales={value.locales} />}
      isCompact={isCompact}
      isSelected={isSelected}
    />
  )
}

export const ReorderableCreatorRow = (props: IReorderableRowProps<GQLCreator>) => {
  const { value, isCompact } = props
  const subheaders = [getCreatorDisplayName(value)]

  return (
    <ContentRow
      {...props}
      subheaders={subheaders}
      image={CreatorPlaceholderSVG}
      note={<GlobeIconTooltip locales={value.locales} />}
      isCompact={isCompact}
    />
  )
}

const getMediaContentCaption = (isProcessing: boolean, isChildrenProcessingError: boolean) => {
  if (isProcessing) {
    return t('Processing...')
  }
  if (isChildrenProcessingError) {
    return t('Unable to process')
  }
  return undefined
}

export const ReorderableAudioRow = (props: IReorderableRowProps<GQLAudio>) => {
  const { value, isCompact } = props
  const { transcript, title, duration, isMarketingUseAllowed, sourceUrl } = value
  const header = title
  const image = AudioPlaceholderSVG
  const audioDuration = duration && formatDurationInHoursMinutesSeconds(duration)
  const subheaders = [audioDuration]
  const contentStateCaption = getMediaContentCaption(
    value.isProcessing,
    value.isChildrenProcessingError
  )
  const tags = transcript ? [t('Transcript')] : null
  const { MARKETING } = useFeatureFlags()
  const hasSourceUrl = !_.isNil(sourceUrl)

  const note =
    MARKETING && !isCompact ? (
      <NoteIconsContainer>
        <GlobeIconTooltip locales={value.locales} />
        <DownloadAssetIconButton
          assetId={value.id}
          assetType={AssetType.AUDIO}
          hasSourceUrl={hasSourceUrl}
          isMarketingUseAllowed={isMarketingUseAllowed}
        />
      </NoteIconsContainer>
    ) : (
      <GlobeIconTooltip locales={value.locales} />
    )

  return (
    <ContentRow
      {...props}
      header={header}
      subheaders={subheaders}
      image={image}
      tags={contentStateCaption ? null : tags}
      isError={!!value.isChildrenProcessingError}
      contentStateCaption={contentStateCaption}
      note={note}
      isCompact={isCompact}
    />
  )
}

export const ReorderableVideoRow = (props: IReorderableRowProps<GQLVideo>) => {
  const { value, isCompact } = props
  const { title, posterImageUrl, duration, transcript, isMarketingUseAllowed, sourceUrl } = value
  const contentStateCaption = getMediaContentCaption(value.isProcessing, !!value.processingError)
  const videoDuration = duration && formatDurationInHoursMinutesSeconds(duration)
  const subheaders = [videoDuration]
  const tags = transcript ? [t('Transcript')] : null
  const { MARKETING } = useFeatureFlags()
  const hasSourceUrl = !_.isNil(sourceUrl)

  const note =
    MARKETING && !isCompact ? (
      <NoteIconsContainer>
        <ClosedCaptionLabelTooltip translations={value.translations} />
        <AudioDescriptionLabelTooltip translations={value.translations} />
        <GlobeIconTooltip locales={value.locales} />
        <DownloadAssetIconButton
          assetId={value.id}
          assetType={AssetType.VIDEO}
          hasSourceUrl={hasSourceUrl}
          isMarketingUseAllowed={isMarketingUseAllowed}
        />
      </NoteIconsContainer>
    ) : (
      <NoteIconsContainer>
        <ClosedCaptionLabelTooltip translations={value.translations} />
        <AudioDescriptionLabelTooltip translations={value.translations} />
        <GlobeIconTooltip locales={value.locales} />
      </NoteIconsContainer>
    )

  return (
    <ContentRow
      {...props}
      header={title}
      subheaders={subheaders}
      image={posterImageUrl ?? VideoPlaceholderSVG}
      tags={contentStateCaption ? null : tags}
      isError={!!value.processingError}
      contentStateCaption={contentStateCaption}
      note={note}
      isCompact={isCompact}
    />
  )
}

export const ReorderableImageRow = (props: IReorderableRowProps<GQLImage>) => {
  const { value, isCompact } = props
  const { hero, thumbnail, fileName, isMarketingUseAllowed, sourceUrl } = value
  const subheaders = [fileName]
  const image = isCompact ? thumbnail : hero
  const { MARKETING } = useFeatureFlags()
  const hasSourceUrl = !_.isNil(sourceUrl)

  const note =
    MARKETING && !isCompact ? (
      <NoteIconsContainer>
        <GlobeIconTooltip locales={value.locales} />
        <DownloadAssetIconButton
          assetId={value.id}
          assetType={AssetType.IMAGE}
          hasSourceUrl={hasSourceUrl}
          isMarketingUseAllowed={isMarketingUseAllowed}
        />
      </NoteIconsContainer>
    ) : (
      <GlobeIconTooltip locales={value.locales} />
    )

  return (
    <ContentRow
      {...props}
      subheaders={subheaders}
      image={image || ImagePlaceholderSVG}
      note={note}
      isCompact={isCompact}
    />
  )
}

export const ReorderableGuideRow = (props: IReorderableRowProps<IGuidePickerJson>) => {
  const { value, isCompact } = props
  const { name, imageUrl } = value
  const image = imageUrl || ImagePlaceholderSVG
  const header = isCompact ? undefined : name
  const subheaders = isCompact ? [name] : undefined
  return <ContentRow {...props} header={header} subheaders={subheaders} image={image} />
}

export const ReorderableMapLocationContentRow = (
  props: IReorderableRowProps<IGetMapLocationContent>
) => {
  const { value } = props

  // the `as unknown` parts of these assertions exist to get around the fact that these have an
  // extra 'content' prop that is unused in the components below
  return isItemContent(value) ? (
    <ReorderableItemRow {...(props as unknown as IReorderableRowProps<GQLItem>)} />
  ) : (
    <ReorderableExhibitRow {...(props as unknown as IReorderableRowProps<GQLExhibit>)} />
  )
}

export const ReorderableSubtitlesRow = (
  props: IReorderableRowProps<{ subtitlesValue: string }>
) => {
  const { value } = props
  const subheaders = [value.subtitlesValue]
  const image = SubtitlesPlaceholderSVG

  return <FullContentRow {...props} subheaders={subheaders} image={image} />
}

export const ShowOnMapRow = <T extends object>(props: IReorderableRowProps<T>) => {
  const { value, onRemove, children, flexComponent = () => null } = props

  return (
    <ReorderableRowWrapper {...props}>
      {children}
      <HeaderSubheaderWrapper>
        <HeaderSubheaderContainer />
        {flexComponent && <LowerContent>{flexComponent(value)}</LowerContent>}
      </HeaderSubheaderWrapper>
      <StyledRemoveButton>
        <RemoveButton onRemove={onRemove} />
      </StyledRemoveButton>
    </ReorderableRowWrapper>
  )
}
