import { useContext } from 'react'
import gql from 'graphql-tag'
import TranslationForm, {
  TranslationFormProps
} from 'client/components/TranslationForm/TranslationForm'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import RichTextEditorField from 'client/components/Formik/RichTextEditor/RichTextEditor'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import useNumericRouteParam from 'client/hooks/useNumericRouteParam'
import { createApiConfig } from 'client/components/TranslationForm/util'
import _ from 'lodash'
import tusdUpload from 'client/util/tusdUpload'
import { VideoInput } from 'shared/graphql/mutations/VideoInput'
import { VideoSchemaClient, TITLE_CHAR_LIMIT } from 'client/validation/Video'
import { t } from 'client/i18n'
import ToggleField from 'client/components/Form/ToggleField/ToggleField'
import VideoFileField from './VideoFileField'
import SubtitlesFileField from './SubtitlesFileField'
import TranscriptContextualHelp from './TranscriptContextualHelp'

const apiConfig = createApiConfig(
  'video',
  gql`
    query getSingleVideo($id: Int!) {
      video(id: $id) {
        id
        duration
        url
        sourceUrl
        posterUrlWithoutFallback
        isProcessing
        processingError
        audioLanguageInfo
        externalId
        isMarketingUseAllowed
        translations {
          title
          credits
          transcript
          audioDescriptionTrackIndex
          videoSubtitles {
            id
            fileName
          }
          locale {
            id
            englishName
            code
          }
        }
      }
    }
  `
)

const VideoFormView = () => {
  const { getFieldName } = useContext(TranslationFormContext)

  return (
    <>
      <VideoFileField />
      <SubtitlesFileField />
      <TextInputFormField
        name={getFieldName('title')}
        label={t('* Title')}
        maxLength={TITLE_CHAR_LIMIT}
      />
      <RichTextEditorField
        name={getFieldName('credits')}
        label={t('Credits')}
        fieldContainer={FormField}
      />
      <RichTextEditorField
        name={getFieldName('transcript')}
        label={t('Transcript')}
        additionalLabelNode={TranscriptContextualHelp}
        fieldContainer={FormField}
      />
      <FormField translatable={false}>
        <ToggleField
          name={getFieldName('isMarketingUseAllowed')}
          label={t('Allow Use in Bloomberg Connects Social Media')}
          description={t(
            'Bloomberg Connects can use this video on Bloomberg Connects social media channels. Posts will be shared with you for awareness before going live and will be credited, where possible, based on credit information provided in the CMS.'
          )}
        />
      </FormField>
    </>
  )
}

interface VideoUploadResults {
  file?: {
    name: string
    url: string
  }
}

const VideoForm = () => {
  const contentId = useNumericRouteParam('id')
  const submitValuesParser: TranslationFormProps['submitValuesParser'] = async (
    values,
    setLoadingText,
    setLoadingProgress
  ) => {
    const uploadToTusd = (file: File): Promise<VideoUploadResults> =>
      new Promise((resolve, reject) => {
        if (file) {
          const onProgress = (bytesUploaded: number, bytesTotal: number) => {
            const percentage = Math.round((bytesUploaded / bytesTotal) * 100)
            setLoadingText(
              !_.isNil(percentage)
                ? t('Uploading Video (__percentage__%)', { percentage })
                : t('Saving Video')
            )
            setLoadingProgress(percentage)
          }

          tusdUpload(file, onProgress)
            .then((url) => {
              resolve({ file: { name: file.name, url } })
            })
            .catch(reject)
            .finally(() => {
              setLoadingText(null)
              setLoadingProgress(null)
            })
        } else {
          resolve({})
        }
      })

    const videoUploadResults = await uploadToTusd(values.file?.file)

    return {
      ...values,
      ...videoUploadResults,
      translations: _.map(values.translations, (translation) => ({
        ...translation,
        subtitlesFile: translation.subtitlesFile ? _.omit(translation.subtitlesFile, 'file') : null,
        videoSubtitles: translation.videoSubtitles ? _.pick(translation.videoSubtitles, 'id') : null
      }))
    }
  }

  return (
    <TranslationForm
      contentName="Video"
      contentId={contentId}
      apiConfig={apiConfig}
      validationSchema={VideoSchemaClient}
      inputType={VideoInput}
      submitValuesParser={submitValuesParser}
      additionalFields={{ nontranslatable: ['file'] }}
      initialValidationFields={contentId ? ['processingError'] : undefined}
    >
      <VideoFormView />
    </TranslationForm>
  )
}

export default VideoForm
