import { useContext } from 'react'
import gql from 'graphql-tag'
import TranslationForm from 'client/components/TranslationForm/TranslationForm'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import useNumericRouteParam from 'client/hooks/useNumericRouteParam'
import { createApiConfig } from 'client/components/TranslationForm/util'
import CreatorInput from 'shared/graphql/mutations/CreatorInput'
import { CreatorSchemaClient } from 'client/validation/Creator'
import { t } from 'client/i18n'
import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'

const LifeDatesContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Creator Life Dates')}
    helpContent={
      <>
        <ContextualHelpTextSection>
          {t('Use abbreviations and punctuation consistently across creators in your guide.')}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Examples:')}>
          <ul>
            <li>{t('1920–1998')}</li>
            <li>{t('b. 1973, lives and works in Rio de Janeiro, Brazil')}</li>
            <li>{t('ca. 1835, Kyoto, Japan – 1901, New York, NY, USA')}</li>
            <li>{t('French, early 16th century')}</li>
          </ul>
        </ContextualHelpTextSection>
      </>
    }
    tooltipContent={t('More on life dates')}
  />
)

const apiConfig = createApiConfig(
  'creator',
  gql`
    query getSingleCreator($id: Int!) {
      creator(id: $id) {
        id
        externalId
        translations {
          name
          prefix
          lifedates

          locale {
            englishName
            code
          }
        }
      }
    }
  `
)

const CreatorFormView = () => {
  const { getFieldName } = useContext(TranslationFormContext)
  return (
    <>
      <TextInputFormField
        name={getFieldName('prefix')}
        label={t('Prefix')}
        placeholder={t('e.g., Dr., Mrs., Sra., Professor')}
      />
      <TextInputFormField name={getFieldName('name')} label={t('* Name')} />
      <TextInputFormField
        name={getFieldName('lifedates')}
        label={t('Life Dates')}
        description={t('In the app, parentheses automatically enclose this text.')}
        additionalLabelNode={LifeDatesContextualHelp}
      />
    </>
  )
}

const CreatorForm = () => {
  const contentId = useNumericRouteParam('id')

  return (
    <TranslationForm
      contentName="Creator"
      contentId={contentId}
      apiConfig={apiConfig}
      validationSchema={CreatorSchemaClient}
      inputType={CreatorInput}
    >
      <CreatorFormView />
    </TranslationForm>
  )
}
export default CreatorForm
