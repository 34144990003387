import { put, takeEvery } from 'redux-saga/effects'
import { setSelectedVersionInfo } from 'client/redux/actions/version'
import { navigateWithRefresh } from 'client/redux/actions/navigation'
import { AnyAction } from 'redux'
import * as types from '../actions/types'

function handleNavigateWithRefresh(action: AnyAction) {
  const { url } = action
  window.location.href = url
}

function* handleNavigateWithVersionInfo(action: AnyAction) {
  const { selectedVersionInfo, url } = action
  yield put(setSelectedVersionInfo(selectedVersionInfo, false))
  yield put(navigateWithRefresh(url))
}

export default function* root(): IterableIterator<any> {
  yield takeEvery(types.NAVIGATE_WITH_REFRESH, handleNavigateWithRefresh)
  yield takeEvery(types.NAVIGATE_WITH_VERSION_INFO, handleNavigateWithVersionInfo)
}
