import * as React from 'react'
import { PermanentActionMessage } from 'client/dsm/Dialog/Dialog'
import { t } from 'client/i18n'

interface IConfirmation {
  title: React.ReactNode
  message: React.ReactNode
}

const DELETE = {
  BODY: {
    DEFAULT: <PermanentActionMessage />,
    NO_CONTENT: <p>{t('Are you sure you want to delete this building?')}</p>,
    WITH_CONTENT: (
      <p>
        {t(
          'Are you sure you want to delete this building? This will also delete its associated floor plan(s) and pins.'
        )}
      </p>
    )
  },
  TITLE: {
    NO_CONTENT: t('Delete Building?'),
    WITH_CONTENT: t('Delete Building + Floor Plan(s)?')
  }
}

export const getDeleteMessage = (hasContent: boolean): IConfirmation => {
  const { BODY, TITLE } = DELETE
  const { WITH_CONTENT: withContentBody, NO_CONTENT: noContentBody, DEFAULT } = BODY
  const { WITH_CONTENT: withContentTitle, NO_CONTENT: noContentTitle } = TITLE
  const message = (
    <>
      {hasContent ? withContentBody : noContentBody}
      {DEFAULT}
    </>
  )
  const title = hasContent ? withContentTitle : noContentTitle
  return { message, title }
}

export const getCreateSuccessMessage = (
  hasExteriorMap: boolean,
  isGoogleMap: boolean
): IConfirmation => {
  const message = (
    <>
      <p>{t('A building has been added.')}</p>
      {hasExteriorMap && (
        <p>
          {isGoogleMap
            ? t('To move the building’s pin, adjust the latitude and longitude coordinates.')
            : t('Drag and drop this pin to change the location.')}
        </p>
      )}
    </>
  )
  const title = t('Success')
  return { message, title }
}
