import SupportFooter from 'client/components/SupportFooter'
import ContainerWithConnectsLogo from 'client/components/ContainerWithConnectsLogo'
import { useNavigate, useSearchParams } from 'react-router-dom'
import StringInput, { IStringInputProps } from 'client/components/Form/StringInput/StringInput'
import { useState } from 'react'
import styled from 'styled-components'
import Button from 'client/components/Button/Button'
import FormField from 'client/components/Form/FormField/FormField'
import api from 'client/util/api'
import { t } from 'client/i18n'

const BackToLoginButton = styled(Button)`
  width: 100%;
  margin-top: var(--spacing-xlarge);
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  text-align: center;
  margin-top: var(--spacing-xlarge);
  > button + button {
    margin-left: var(--spacing-xsmall);
  }
`

const ForgotPassword = () => {
  const [searchParams] = useSearchParams()
  const searchParamsEmail = searchParams.get('email')
  const [email, setEmail] = useState(searchParamsEmail ?? '')
  const [sendResetLinkButtonWasClicked, setSendResetLinkButtonWasClicked] = useState(false)
  const navigate = useNavigate()
  const onChange: IStringInputProps['onChange'] = (event) => setEmail(event.target.value)

  const requestPasswordReset = async () => {
    setSendResetLinkButtonWasClicked(true)
    await api.post('/auth/request-password-reset', { email })
  }

  return (
    <ContainerWithConnectsLogo>
      {sendResetLinkButtonWasClicked ? (
        <>
          <p>{t('Check your email for a link to reset your password.')}</p>
          <BackToLoginButton
            type="primary"
            label={t('Back to Login')}
            onClick={() => navigate('/auth/login')}
          />
        </>
      ) : (
        <>
          <p>
            {t(
              'Forgot your password? Enter the email you use to log in and we’ll send you a link to reset it.'
            )}
          </p>
          <FormField label={t('Email')}>
            <StringInput name="email" value={email} onChange={onChange} />
          </FormField>
          <ButtonContainer>
            <Button label={t('Back')} onClick={() => navigate('/auth/login')} />
            <Button
              type="primary"
              label={t('Send Reset Link')}
              disabled={!email}
              onClick={requestPasswordReset}
            />
          </ButtonContainer>
        </>
      )}
      <SupportFooter />
    </ContainerWithConnectsLogo>
  )
}

export default ForgotPassword
