import { MuseumImageType } from 'shared/constants/images'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import { usePost } from 'client/hooks/api'
import { refetchActiveQueries } from 'client/apollo'
import { useCallback, useState } from 'react'
import { useErrorDialog } from 'client/components/ErrorDialog'
import AddImageAsset from 'client/screens/AppEditor/ImageUpload/AddImageAsset'
import { t } from 'client/i18n'
import InvalidLogoFileErrorDialog from 'client/screens/AppEditor/InvalidLogoFileErrorDialog'
import isValidLogo from 'client/util/validator/isValidLogo'
import { GQLMuseum } from 'shared/graphql/types/graphql'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'

const ORGANIZATION_IMAGE_QUERY = gql`
  query getOrganizationImage($museumId: Int!) {
    museum(id: $museumId) {
      id
      images {
        exploreHero {
          id
          url
          sourceUrl
        }
      }
    }
  }
`

const SQUARE_LOGO_QUERY = gql`
  query getSquareLogo($museumId: Int!) {
    museum(id: $museumId) {
      id
      images {
        exploreIcon {
          id
          url
          sourceUrl
        }
      }
    }
  }
`

const RECTANGULAR_LOGO_QUERY = gql`
  query getRectangularLogo($museumId: Int!) {
    museum(id: $museumId) {
      id
      images {
        museumLogo {
          id
          url
          sourceUrl
        }
      }
    }
  }
`

const IMAGE_TYPE_MAP = {
  [MuseumImageType.EXPLORE_HERO]: {
    query: ORGANIZATION_IMAGE_QUERY,
    errorMessage: t('Unable to Upload Organization Image'),
    label: t('Replace Image'),
    downloadMenuOptionLabel: t('Download Image')
  },
  [MuseumImageType.EXPLORE_ICON]: {
    query: SQUARE_LOGO_QUERY,
    errorMessage: t('Unable to Upload Square Logo'),
    label: t('Replace Logo'),
    downloadMenuOptionLabel: t('Download Logo')
  },
  [MuseumImageType.MUSEUM_LOGO]: {
    query: RECTANGULAR_LOGO_QUERY,
    errorMessage: t('Unable to Upload Rectangular Logo'),
    label: t('Replace Logo'),
    downloadMenuOptionLabel: t('Download Logo')
  }
}

interface IImageUploaderProps {
  type: MuseumImageType.EXPLORE_HERO | MuseumImageType.EXPLORE_ICON | MuseumImageType.MUSEUM_LOGO
  isSvgAllowed: boolean
  width: number
}

const ImageUploaderField = ({ type, isSvgAllowed, width }: IImageUploaderProps) => {
  const { query, errorMessage, label, downloadMenuOptionLabel } = IMAGE_TYPE_MAP[type]
  const [hasValidationError, setHasValidationError] = useState(false)
  const [errorDialog, setError] = useErrorDialog()
  const onClose = useCallback(() => setHasValidationError(false), [])
  const { data, loading } = useQuery<{ museum: GQLMuseum }>(query)
  const { DOWNLOAD_ASSETS } = useFeatureFlags()

  const [uploadImage, isImageUploading] = usePost(`/museum-images/${type}`, {
    onSuccess: () => {
      refetchActiveQueries()
    },
    onError: (error) => setError({ error, title: errorMessage })
  })

  if (loading || isImageUploading) {
    return <LoadingOverlay />
  }

  const src = data?.museum?.images[type]?.url

  const onChange = async (file: File) => {
    if (type === MuseumImageType.MUSEUM_LOGO) {
      const isValid = await isValidLogo(file)

      if (!isValid) {
        setHasValidationError(true)
        return
      }
    }

    const formData = new FormData()
    formData.append('image', file)
    await uploadImage(formData)
  }

  return (
    <>
      {hasValidationError && <InvalidLogoFileErrorDialog onClose={onClose} />}
      {errorDialog}
      <AddImageAsset
        id={type}
        src={src}
        width={width}
        fileInputOption={{
          label,
          onFileChange: onChange,
          isSvgAllowed
        }}
        additionalOptions={
          DOWNLOAD_ASSETS
            ? [
                {
                  label: downloadMenuOptionLabel,
                  onClick: () => {
                    const museumImageId = data?.museum?.images[type]?.id
                    window.open(`/api/download/museumImage/${museumImageId}/${type}`)
                  }
                }
              ]
            : undefined
        }
      />
    </>
  )
}

export default ImageUploaderField
