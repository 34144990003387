export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD'
export const USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR'

export const LOGOUT_START = 'LOGOUT_START'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const FETCH_TOKEN = 'FETCH_TOKEN'
export const FETCH_TOKEN_SUCCESS = 'FETCH_TOKEN_SUCCESS'

export const AUTH_FAILED = 'AUTH_FAILED'
export const AUTH_ACCESS_TIME_RESTRICTED = 'AUTH_ACCESS_TIME_RESTRICTED'

export const FLASH_MESSAGE = 'FLASH_MESSAGE'

export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR'

export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const CONFIRM_START = 'CONFIRM_START'
export const CONFIRM_YES = 'CONFIRM_YES'
export const CONFIRM_NO = 'CONFIRM_NO'
export const CONFIRM_END = 'CONFIRM_END'

export const MFA_FETCH_QR_URL = 'MFA_FETCH_QR_URL'
export const MFA_FETCH_QR_URL_SUCCESS = 'MFA_FETCH_QR_URL_SUCCESS'

export const MFA_VERIFICATION_START = 'MFA_VERIFICATION_START'
export const MFA_VERIFICATION_SUCCESS = 'MFA_VERIFICATION_SUCCESS'
export const MFA_VERIFICATION_FAILURE = 'MFA_VERIFICATION_FAILURE'

export const SET_SELECTED_VERSION_INFO = 'SET_SELECTED_VERSION_INFO'

export const RESET_APP = 'RESET_APP'

export const NAVIGATE_WITH_REFRESH = 'NAVIGATE_WITH_REFRESH'
export const NAVIGATE_WITH_VERSION_INFO = 'NAVIGATE_WITH_VERSION_INFO'

export const SHOW_CHANGES_SAVED_TOAST = 'SHOW_CHANGES_SAVED_TOAST'

export const FETCH_BULK_UPLOAD_START = 'FETCH_BULK_UPLOAD_START'
export const FETCH_BULK_UPLOAD_SUCCESS = 'FETCH_BULK_UPLOAD_SUCCESS'
export const FETCH_BULK_UPLOAD_FAILURE = 'FETCH_BULK_UPLOAD_FAILURE'
