import { useState, useEffect } from 'react'
import _ from 'lodash'
import { GQLLocale } from 'shared/graphql/types/graphql'
import Label from 'client/components/Form/Label/Label'
import SelectBox, { ISelectBoxOptions } from 'client/components/Form/SelectBox/SelectBox'
import styled from 'styled-components'
import mapLocaleToOption from 'client/util/mapLocaleToOption'
import { t } from 'client/i18n'

const SelectBoxWrapper = styled.div`
  margin-top: var(--spacing-xsmall);
  height: 40px;
  width: 342px;
`

interface ILanguageInputProps {
  selectedLocale?: GQLLocale
  setSelectedLocale: React.Dispatch<React.SetStateAction<GQLLocale | undefined>>
  defaultLocale?: GQLLocale
  availableLocales?: GQLLocale[]
  excludedLocales: GQLLocale[]
  className?: string
}

export function LanguageInput({
  selectedLocale,
  setSelectedLocale,
  defaultLocale,
  availableLocales,
  excludedLocales,
  className
}: ILanguageInputProps) {
  const [localeOptions, setLocaleOptions] = useState<ISelectBoxOptions[]>()

  useEffect(() => {
    if (availableLocales) {
      const excludedCodes = _<GQLLocale | undefined>(excludedLocales)
        .concat(defaultLocale)
        .compact()
        .map('code')
        .value()
      const options: ISelectBoxOptions[] = _(availableLocales)
        .reject((locale) => _.includes(excludedCodes, locale.code))
        .sortBy('englishName')
        .map((locale) => mapLocaleToOption(locale, false))
        .value()

      // put defaultLocale at top of options list
      if (defaultLocale) {
        options.unshift(mapLocaleToOption(defaultLocale, true))
      }

      setLocaleOptions(options)
    }
  }, [availableLocales, excludedLocales, defaultLocale])

  // @ts-ignore DOCNT-10958
  const onSelectChange = (e) => {
    const locale = _.find(availableLocales, { code: e.target?.value })!
    setSelectedLocale(locale)
  }

  return (
    <div className={className}>
      <Label>{t('Language')}</Label>
      <SelectBoxWrapper>
        <SelectBox
          name="languages"
          value={
            selectedLocale &&
            mapLocaleToOption(selectedLocale, selectedLocale.code === defaultLocale?.code)
          }
          options={localeOptions}
          onChange={onSelectChange}
          placeholder={t('Choose a Language')}
          isSearchable={true}
        />
      </SelectBoxWrapper>
    </div>
  )
}
