import { memo, useState } from 'react'
import styled from 'styled-components'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import { t } from 'client/i18n'
import {
  ExteriorMapSuccess,
  ExteriorMapUpload
} from 'client/screens/AppEditor/MapEditor/ExteriorMapUpload'
import { usePost } from 'client/hooks/api'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { confirm } from 'client/redux/actions/confirmation'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import { getExteriorMapCreateConfirmationMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/exteriorMap'
import { refetchActiveQueries } from 'client/apollo'
import { createFormData } from 'client/screens/AppEditor/MapEditor/MapEditorUtils'
import { GQLMap } from 'shared/graphql/types/graphql'

const Container = styled.div`
  border-bottom: var(--border-light);
  display: flex;
  justify-content: center;
  align-items: center;
`

const AddExteriorMapButton = styled(PlusIconAddButton)`
  border: 1px dotted var(--color-grey-02);
  border-radius: 2px;
  width: 100%;
  height: 60px;
  margin: var(--spacing-small);
`

interface IAddExteriorMapProps {
  isHidden?: boolean
}

const AddExteriorMap = memo((props: IAddExteriorMapProps) => {
  const [showUploadExteriorDialog, setShowUploadExteriorDialog] = useState(false)
  const [showSuccessUploadExteriorDialog, setShowSuccessUploadExteriorDialog] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isHidden = false } = props

  const [createExterior, isUploadingExteriorMap] = usePost<GQLMap>('/maps/exterior', {
    onSuccess: async (data) => {
      // The reason is we want to await the front end get the added exterior images at the time it navigate to that
      // url, otherwise, there would be a very short amount of time the map preview show an empry view message because it do not get the updated image info from the gql yet.
      await refetchActiveQueries()
      navigate(`/app-editor/maps/exterior/${data.id}`)
      setShowSuccessUploadExteriorDialog(true)
      dispatch(showChangesSavedToast())
    },
    onError: () => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: t("We weren't able to upload the exterior map. Please try again later."),
          isAlert: true
        })
      )
    }
  })

  const { message, title } = getExteriorMapCreateConfirmationMessage()

  const handleImageChange = async (image: File) => {
    setShowUploadExteriorDialog(false)
    const formData = createFormData({ image })
    await createExterior(formData)
  }

  return (
    <Container>
      {!isHidden && (
        <AddExteriorMapButton
          label={t('Add Exterior Map')}
          onClick={() => setShowUploadExteriorDialog(true)}
        />
      )}
      {showUploadExteriorDialog && (
        <ExteriorMapUpload
          onFileChange={handleImageChange}
          onCancel={() => setShowUploadExteriorDialog(false)}
          message={message}
          title={title}
        />
      )}
      {showSuccessUploadExteriorDialog && (
        <ExteriorMapSuccess
          onContinue={() => {
            setShowSuccessUploadExteriorDialog(false)
          }}
          label={t('OK')}
        />
      )}
      {isUploadingExteriorMap && <LoadingOverlay />}
    </Container>
  )
})

export default AddExteriorMap
