import { Route, Routes } from 'react-router-dom'
import BuildingForm from 'client/screens/AppEditor/MapEditor/Buildings/BuildingForm'
import useBuildingList from 'client/screens/AppEditor/MapEditor/useBuildingList'
import { BuildingItem } from 'client/screens/AppEditor/MapEditor/Buildings/BuildingItem'

interface IBuildingListProps {
  onFloorSelected: (buildingId: number, floorId: number) => void
}
const BuildingList = (props: IBuildingListProps) => {
  const { onFloorSelected } = props
  const buildings = useBuildingList()

  return (
    <>
      {buildings.map((building) => (
        <BuildingItem
          key={building.id}
          currentBuilding={building}
          onFloorSelected={onFloorSelected}
        />
      ))}
      <Routes>
        <Route path="buildings/new" element={<BuildingForm />} />
        <Route path="buildings/:editBuildingId/edit" element={<BuildingForm />} />
      </Routes>
    </>
  )
}

export default BuildingList
