import { takeEvery } from 'redux-saga/effects'
import { refetchActiveQueries } from 'client/apollo'
import { AnyAction } from 'redux'

const MUTATION_ACTION_TYPE_REGEX = /(ADD|CREATE|DELETE|UPDATE|REORDER)_(.*_)*SUCCESS/
// REORDER can be removed from the regex once feature section reordering (ReorderFeaturesForm)
// migrates away from REST (`/sections/reorder` endpoint in the featureRouter)

function clearApolloCache() {
  refetchActiveQueries()
}

function shouldClearApolloCache(action: AnyAction) {
  return MUTATION_ACTION_TYPE_REGEX.test(action.type)
}

export default function* root(): IterableIterator<any> {
  yield takeEvery(shouldClearApolloCache, clearApolloCache)
}
