import { useEffect, useState } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import _ from 'lodash'
import styled from 'styled-components'
import PageContent, { MAX_PAGE_CONTENT_WIDTH } from 'client/components/PageContent/PageContent'
import useNumericRouteParam from 'client/hooks/useNumericRouteParam'
import { t } from 'client/i18n'
import { useBuildings } from 'client/screens/AppEditor/MapEditor/useBuildings'
import { getMapById } from 'client/screens/AppEditor/MapEditor/MapEditorUtils'
import MapSidebar, { IMapSideBarProps } from './MapSideBar'
import FloorPreview from './FloorPreview'
import ExteriorMapPreview from './ExteriorMapPreview'
import EmptyMapContentView from './MapEmptyContentView'

const StyledMapEditor = styled.article`
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
  max-width: ${MAX_PAGE_CONTENT_WIDTH}px;
`

const LeftColumn = styled.div`
  display: flex;
`

const RightColumn = styled.div`
  display: flex;
  flex-grow: 1;
`

const Editor = () => {
  const navigate = useNavigate()
  const buildingId = useNumericRouteParam('buildingId')
  const floorId = useNumericRouteParam('floorId')
  const isExteriorMapSelected = _.isNil(buildingId) && !_.isNil(floorId)
  const { buildings, exteriorMap, loading: isQueryLoading } = useBuildings()

  const floor = getMapById(buildings, exteriorMap, buildingId, floorId)
  const [selectedPinId, setSelectedPinId] = useState<number>()

  const handleSelectedPinChanged = (id: number) => setSelectedPinId(id)

  const handleOnFloorChanged: IMapSideBarProps['onSelect'] = (changedBuildingId, changedFloorId) =>
    navigate(`/app-editor/maps/building/${changedBuildingId}/floor/${changedFloorId}`)

  const handleExteriorMapSelected: IMapSideBarProps['onExteriorMapSelected'] = (id) => {
    navigate(`/app-editor/maps/exterior/${id}`)
  }

  const showEmptyState = _.isEmpty(floor) && !isQueryLoading
  const rightColumnContent = (
    <>
      {isExteriorMapSelected && (
        <ExteriorMapPreview
          selectedPinId={selectedPinId}
          onSelectedPinChanged={handleSelectedPinChanged}
        />
      )}
      {!isExteriorMapSelected && floor && (
        <FloorPreview
          floor={floor}
          selectedPinId={selectedPinId}
          onSelectedPinChanged={handleSelectedPinChanged}
        />
      )}
    </>
  )

  return (
    <>
      <LeftColumn>
        <MapSidebar
          onSelect={handleOnFloorChanged}
          isExteriorMapSelected={isExteriorMapSelected}
          onExteriorMapSelected={handleExteriorMapSelected}
        />
      </LeftColumn>
      <RightColumn>{showEmptyState ? <EmptyMapContentView /> : rightColumnContent}</RightColumn>
    </>
  )
}

const MapEditor = () => {
  const navigate = useNavigate()
  const { buildings, loading } = useBuildings()
  const location = useLocation()
  // We only want to redirect if we're arriving here for the first time
  const [redirected, setRedirected] = useState(location.pathname.includes('building/'))

  useEffect(() => {
    if (!redirected && !loading && !_.isEmpty(buildings)) {
      const building = _.values(buildings)[0]
      const floor = _.find(building?.floors, { isDefault: true }) || building?.floors[0]
      const buildingUrl = building ? `building/${building.id}` : 'building'
      const redirectUrl = floor ? `${buildingUrl}/floor/${floor.id}` : `${buildingUrl}/floor`
      navigate(redirectUrl)
      setRedirected(true)
    }
  }, [buildings, navigate, setRedirected, redirected, location, loading])

  return (
    <PageContent title={t('Map')}>
      <StyledMapEditor>
        <Routes>
          {/* They are all pointing to the same component but the component is making the decision to render specific component based on url. */}
          {/* We should split up <Editor /> into specific components to be used here. Doing so will remove the need for our redirected state. */}
          <Route path="building/:buildingId/floor/:floorId/*" element={<Editor />} />
          <Route path="exterior/*" element={<Editor />} />
          <Route path="exterior/:floorId/*" element={<Editor />} />
          <Route path="/*" element={<Editor />} />
        </Routes>
      </StyledMapEditor>
    </PageContent>
  )
}

export default MapEditor
