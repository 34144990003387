import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { IMapJson } from 'shared/json/IMapJson'

const MUSEUM_EXTERIOR_QUERY = gql`
  query museumBuildings($museumId: Int!) {
    museum(id: $museumId) {
      exteriorMap {
        id
        uuid
        title
        imageUrl
        optimizedSize
        position
        height
        width
        isDefault
        isGoogleMap
        cameraCenterLatitude
        cameraCenterLongitude
        cameraHeading
        cameraZoom
        mapLocations {
          id
          name
          mapId
          radius
          xPosition
          yPosition
          longitude
          latitude
          map {
            id
          }
        }
      }
    }
  }
`

export const useExteriorMap = () => {
  const { data, loading, error } = useQuery(MUSEUM_EXTERIOR_QUERY)
  const exteriorMap: IMapJson = data?.museum?.exteriorMap

  return { exteriorMap, loading, error }
}
