/* eslint-disable react-hooks/rules-of-hooks */
import { useContext } from 'react'
import _ from 'lodash'
import {
  getDirtyLocaleCodes,
  isLocaleCode,
  Dialog,
  DialogOptions
} from 'client/components/TranslationForm/util'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import TranslationFormDialog from 'client/components/TranslationForm/TranslationFormDialog'
import { PermanentActionMessage } from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import { ErrorBody } from 'client/components/ErrorDialog'
import { t } from 'client/i18n'
import { TKey } from 'shared/i18n/types/translationResources'
import { ContentName } from 'client/components/TranslationForm/types'
import { Nil } from 'shared/util/types'

export enum FormHeaderDialogType {
  DELETE = 'DELETE',
  CANCEL = 'CANCEL',
  SAVE = 'SAVE',
  SAVE_ERROR = 'SAVE_ERROR'
}

interface IFormHeaderProps {
  dialog: Dialog<FormHeaderDialogType>
  closeForm: () => void
  closeDialog: () => void
  delete: () => void
  save: () => void
}

const getTitleByType = (dialog: Dialog<FormHeaderDialogType>, contentName: ContentName) => {
  const title = dialog?.options?.title

  if (!_.isEmpty(title)) {
    return title
  }

  switch (dialog.type) {
    case FormHeaderDialogType.DELETE:
      // eslint-disable-next-line docent/require-translation-keys-to-be-literals
      return t(`Delete ${contentName}?`)
    case FormHeaderDialogType.SAVE:
      return t('Save Changes?')
    case FormHeaderDialogType.CANCEL:
      return t('Discard Changes?')
    case FormHeaderDialogType.SAVE_ERROR:
      return t('Unable to Complete Request')
    default:
      return null
  }
}

const DeleteBody = ({ options }: { options: DialogOptions | Nil }) => {
  const { defaultLocale, values } = useContext(TranslationFormContext)

  const translations = _(values)
    .keysIn()
    .filter(isLocaleCode)
    .difference([defaultLocale?.code!])
    .value()

  const translationsMessage =
    !_.isEmpty(translations) &&
    t('translationFormWillBeDeletedMessage', { count: translations.length })

  return (
    <div>
      {translationsMessage && <p data-testid="translations-message">{translationsMessage}</p>}
      {options?.body}
      <PermanentActionMessage />
    </div>
  )
}

const SaveBody = ({ options }: { options: DialogOptions | Nil }) => {
  const { defaultLocale, initialValues, values } = useContext(TranslationFormContext)

  const { translations } = getDirtyLocaleCodes(initialValues, values, defaultLocale!)

  const { editedOrAdded, removed } = translations

  const editedOrAddedNames = _.map(editedOrAdded, (code) => t(`language.${code}` as TKey))

  const savedMessage =
    !_.isEmpty(editedOrAdded) &&
    t('translationFormWillBeSavedMessage', {
      count: editedOrAdded.length,
      // TODO: cleanup join
      translations: editedOrAddedNames.join(',')
    })

  const removedNames = _.map(removed, (code) => t(`language.${code}` as TKey))

  const removedMessage =
    !_.isEmpty(removed) &&
    t('translationFormWillBeRemovedMessage', {
      count: removed.length,
      // TODO: cleanup join
      translations: removedNames.join(',')
    })

  return (
    <div>
      {savedMessage && <p data-testid="saved-message">{savedMessage}</p>}
      {removedMessage && <p data-testid="removed-message">{removedMessage}</p>}
      {options?.body && options.body}
    </div>
  )
}

interface ISaveErrorBodyProps {
  errorDetails: Error | string
}
const SaveErrorBody = ({ errorDetails }: ISaveErrorBodyProps) => {
  return <ErrorBody error={errorDetails} />
}

const CancelBody = () => {
  const { initialValues, values, defaultLocale } = useContext(TranslationFormContext)

  const { translations } = getDirtyLocaleCodes(initialValues, values, defaultLocale!)
  const { editedOrAdded } = translations

  const message = _.isEmpty(editedOrAdded)
    ? t('translationFormUnsavedChangesMessage')
    : t('translationFormUnsavedChangesWithTranslationsMessage', { count: editedOrAdded.length })

  return <div>{message}</div>
}

const FormHeaderBody = ({ dialog }: { dialog: Dialog<FormHeaderDialogType> }) => {
  const { type, options } = dialog
  // this has to handle 2 things:
  // 1. returning only the body from the config (if isBlocking)
  // 2. merging the body from the config otherwise (if not isBlocking)

  // the first conditional check is #1
  if (options?.isBlocking) {
    return options?.body as JSX.Element
  }

  // #2 is handled in the specific body functions
  switch (type) {
    case FormHeaderDialogType.DELETE:
      return <DeleteBody options={options} />
    case FormHeaderDialogType.SAVE:
      return <SaveBody options={options} />
    case FormHeaderDialogType.CANCEL:
      return <CancelBody />
    case FormHeaderDialogType.SAVE_ERROR:
      return <SaveErrorBody errorDetails={options?.errorDetails!} />
    default:
      return null
  }
}

interface IFormHeaderActionsProps extends IFormHeaderProps {
  contentName: ContentName
}

const FormHeaderActions = (props: IFormHeaderActionsProps) => {
  const { dialog, delete: deleteAction, save, closeDialog, closeForm, contentName } = props
  const { type, options } = dialog

  if (options?.isBlocking) {
    return <Button type="primary" onClick={closeDialog} label={t('OK')} />
  }

  switch (type) {
    case FormHeaderDialogType.DELETE:
      return (
        <>
          <Button onClick={closeDialog} label={t('Cancel')} />
          <Button
            type="danger"
            onClick={() => {
              closeDialog()
              deleteAction()
            }}
            // eslint-disable-next-line docent/require-translation-keys-to-be-literals
            label={t(`Delete ${contentName}`)}
          />
        </>
      )
    case FormHeaderDialogType.SAVE:
      return (
        <>
          <Button onClick={closeDialog} label={t('Cancel')} />
          <Button
            type="primary"
            onClick={() => {
              closeDialog()
              save()
            }}
            label={t('Save')}
          />
        </>
      )
    case FormHeaderDialogType.CANCEL:
      return (
        <>
          <Button onClick={closeDialog} label={t('Continue Editing')} />
          <Button type="danger" onClick={closeForm} label={t('Discard Changes')} />
        </>
      )
    case FormHeaderDialogType.SAVE_ERROR:
      return <Button type="primary" onClick={closeDialog} label={t('OK')} />
    default:
      return null
  }
}

export const FormHeaderDialogs = (props: IFormHeaderProps) => {
  const { dialog } = props

  // no dialog requested at this time
  if (!dialog?.type) {
    return null
  }

  const { contentName } = useContext(TranslationFormContext)

  return (
    <TranslationFormDialog
      title={getTitleByType(dialog, contentName)!}
      body={<FormHeaderBody dialog={dialog} />}
      actions={<FormHeaderActions {...props} contentName={contentName!} />}
    />
  )
}
