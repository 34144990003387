// TODO: move this file into ImageForm?
import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import StandardForm from 'client/components/StandardForm/StandardForm'
import { ICropState, ICropBox, ICanvasData, ICropEvent, ICropInformation } from 'shared/CropTypes'
import { ImageInfo, ImageType } from 'shared/constants/images'
import ImageCropper from 'client/components/ImageCropper/ImageCropper'
import useNumericRouteParam from 'client/hooks/useNumericRouteParam'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { t } from 'client/i18n'
import { Nil } from 'shared/util/types'
import ErrorOverlay from './ErrorOverlay'

const extractCropStateValues = (cropInfo: ICropInformation): ICropEvent => {
  // default initialization for crop form of any imageType
  if (!cropInfo) {
    return {
      cropData: null,
      zoomRatio: 0,
      cropBoxData: null,
      canvasData: null
    }
  }

  const { cropData } = cropInfo
  const { zoomRatio, cropBoxData, canvasData } = cropInfo.UI

  return {
    cropData,
    zoomRatio,
    cropBoxData,
    canvasData
  }
}

const mapCropStateValues = ({
  imageType,
  cropData,
  zoomRatio,
  cropBoxData,
  canvasData
}: {
  imageType: string
  cropData: ICropBox | Nil
  zoomRatio: number | undefined
  cropBoxData: ICropBox | null
  canvasData: ICanvasData | Nil
}): ICropState => ({
  [imageType]: {
    cropData,
    UI: {
      zoomRatio,
      cropBoxData,
      canvasData
    }
  }
})

const query = gql`
  query getSingleImage($id: Int!) {
    image(id: $id) {
      id
      squashed
      cropState
    }
  }
`

// This entire interface only applies to the new (ML) version of this form
interface IImageCropFormProps {
  objectUrl?: string // source of a new image file uploaded within the image form session
  cropState?: ICropState // all cropping updates made within the image form session
  applyCropState: (cropState: ICropState) => void
}

type ImageCropFormUrlParams = Record<string, ImageType.HERO | ImageType.THUMBNAIL>

const ImageCropForm = (props: IImageCropFormProps) => {
  const navigate = useNavigate()

  // For consistency (and deep-linking), imageId & imageType always exist as query params;
  const imageType = useParams<ImageCropFormUrlParams>().imageType!
  const imageId = useNumericRouteParam('id')

  const { objectUrl, cropState, applyCropState } = props

  // skip GQL if an image source was supplied to the crop form (new image) or if there is no imageId
  const shouldSkipQuery = !_.isNil(objectUrl) || _.isNil(imageId)
  const { data, loading } = useQuery(query, { variables: { id: imageId }, skip: shouldSkipQuery })

  const image = data?.image
  const { aspectRatio } = ImageInfo[imageType]

  const [cropData, setCropData] = useState<ICropBox | Nil>(null)
  const [cropBoxData, setCropBoxData] = useState<ICropBox | null>(null)
  const [zoomRatio, setZoomRatio] = useState<number | undefined>(0)
  const [canvasData, setCanvasData] = useState<ICanvasData | Nil>(null)

  const navigateToBaseImageForm = useCallback(() => navigate('..'), [navigate])

  useEffect(() => {
    // user has deep-linked into a new/image/:imageType/crop
    if (!imageId && !objectUrl) {
      navigateToBaseImageForm() // new/image
    }
  }, [imageId, objectUrl, navigateToBaseImageForm])

  const onCropStateChange = (event: ICropEvent): void => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { cropData, cropBoxData, canvasData, zoomRatio } = event
    setCropData(cropData)
    setCropBoxData(cropBoxData)
    setCanvasData(canvasData)
    setZoomRatio(zoomRatio)
  }

  useEffect(() => {
    // Initialize the crop form with the local cropState or DB cropState, associated with the current imageType.
    // If neither exists, this function will return default initialization values
    onCropStateChange(
      extractCropStateValues(cropState?.[imageType] || image?.cropState?.[imageType])
    )
  }, [image, cropState, imageType])

  const onCancel = () => navigateToBaseImageForm()
  const onSubmit = () => {
    const newCropState = mapCropStateValues({
      cropData,
      imageType,
      zoomRatio,
      canvasData,
      cropBoxData
    })

    // Accept every change as a valid change, with the exception of the existence of pre-existing data and it's equivalence to the current local state.
    // i.e. ignore crop data that already exists in the DB (the data is only used to initialize the form)
    // Example: A pre-existing image has cropState in the DB for the thumbnail.
    //  - This form will get initialized with the cropState.thumbnail data from the DB (as it should for display purposes).
    //  - User makes no edits and clicks 'Apply'.
    //  - The cropState data in this form represents data that already exists in the DB - nothing has changed; so it should be ignored.
    const ignoreCurrentCropData =
      image?.cropState?.[imageType] &&
      _.isEqual(newCropState[imageType], image?.cropState?.[imageType])
    // the ML form only sends actual requested changes to the server; no pre-existing DB crop data
    const updatedCropState = ignoreCurrentCropData ? {} : newCropState

    applyCropState({ ...cropState, ...updatedCropState })
    navigateToBaseImageForm()
  }

  const isCropValid = _.isNil(cropBoxData?.left) || cropBoxData!.left >= 0

  return (
    <StandardForm
      title={t('Crop Image')}
      onCancel={onCancel}
      onSave={onSubmit}
      enableSave={isCropValid}
      saveButtonLabel={t('Apply')}
      fullWidth={true}
      transparentBackgroundContainer={true}
      isLoading={loading}
    >
      <ImageCropper
        image={objectUrl || image?.squashed}
        aspect={aspectRatio}
        cropState={{ zoomRatio, cropBoxData, canvasData }}
        onCropError={onCancel}
        onChange={onCropStateChange}
        showControlForNoCrop={true}
      />
      <ErrorOverlay active={!isCropValid} />
    </StandardForm>
  )
}

export default ImageCropForm
