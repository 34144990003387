import _ from 'lodash'
import { useMemo } from 'react'
import { useBuildings } from 'client/screens/AppEditor/MapEditor/useBuildings'

const useBuildingList = () => {
  const { buildings } = useBuildings()
  return useMemo(() => _(buildings).values().sortBy('position').value(), [buildings])
}

export default useBuildingList
