import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import _ from 'lodash'
import { t } from 'client/i18n'
import { ImageType } from 'shared/constants/images'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import React from 'react'
import { Nil } from 'shared/util/types'

interface IImageFileMenuPopoutProps {
  id: number | Nil
  onReplace: () => void
  onEdit: (imageType: ImageType) => void
  localFile: any
}

function ImageFileMenuPopout(props: IImageFileMenuPopoutProps) {
  const { id, localFile, onReplace, onEdit } = props
  const { DOWNLOAD_ASSETS } = useFeatureFlags()
  const canDownload = DOWNLOAD_ASSETS && id && !localFile

  const options: IMenuOption[] = _.compact([
    {
      label: t('Edit Thumbnail Preview'),
      onClick: () => onEdit(ImageType.THUMBNAIL)
    },
    {
      label: t('Edit Hero Preview'),
      onClick: () => onEdit(ImageType.HERO)
    },
    {
      label: t('Replace Image'),
      onClick: () => onReplace()
    },
    canDownload && {
      label: t('Download Original Image'),
      onClick: () => {
        window.open(`/api/download/image/${id}/sourceUrl`)
      }
    },

    canDownload && {
      label: t('Download Full Image'),
      onClick: () => {
        window.open(`/api/download/image/${id}/full`)
      }
    },

    canDownload && {
      label: t('Download Hero Image'),
      onClick: () => {
        window.open(`/api/download/image/${id}/hero`)
      }
    },

    canDownload && {
      label: t('Download Thumbnail Image'),
      onClick: () => {
        window.open(`/api/download/image/${id}/thumbnail`)
      }
    }
  ])

  return <MenuPopout options={options} />
}

export default React.memo(ImageFileMenuPopout)
