import { useRef } from 'react'
import * as React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import { confirm } from 'client/redux/actions/confirmation'
import AddBuilding from 'client/screens/AppEditor/MapEditor/AddBuilding'
import AddExteriorMap from 'client/screens/AppEditor/MapEditor/AddExteriorMap'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { PermanentActionMessage } from 'client/dsm/Dialog/Dialog'
import useBuildingList from 'client/screens/AppEditor/MapEditor/useBuildingList'
import { t } from 'client/i18n'
import { useExteriorMap } from 'client/screens/AppEditor/MapEditor/useExteriorMap'
import { useDelete, usePut } from 'client/hooks/api'
import { refetchActiveQueries } from 'client/apollo'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import { createFormData } from 'client/screens/AppEditor/MapEditor/MapEditorUtils'
import { IMapJson } from 'shared/json/IMapJson'
import {
  EditorContainer,
  ExteriorMapSection,
  ExteriorMapLeftSection
} from './FloorEditor/styledComponents'
import BuildingList from './Buildings/BuildingList'
import MapImageInput from './MapImageInput'
import GoogleMapsForm from './GoogleMapsForm'

export interface IMapSideBarProps {
  onSelect: (buildingId: number, floorId: number) => void
  isExteriorMapSelected?: boolean
  onExteriorMapSelected: (id: number) => void
}

const MapSidebar: React.FC<IMapSideBarProps> = (props) => {
  const { onSelect, isExteriorMapSelected, onExteriorMapSelected } = props
  const replaceExteriorMapRef = useRef<HTMLInputElement>(null)
  const { exteriorMap } = useExteriorMap()
  const buildings = useBuildingList()
  const dispatch = useDispatch()
  const { EDIT_GOOGLE_MAPS_INFO } = useFeatureFlags()
  const navigate = useNavigate()

  const [deleteExterior, isDeletingExteriorMap] = useDelete('/maps/exterior', {
    onSuccess: () => {
      refetchActiveQueries()
      if (isExteriorMapSelected) {
        navigate('/app-editor/maps/exterior')
      }
      dispatch(showChangesSavedToast())
    },
    onError: () => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: t("We weren't able to delete the exterior map. Please try again later."),
          isAlert: true
        })
      )
    }
  })

  const [updateExteriorMap, isUpdatingExteriorMap] = usePut<IMapJson>('/maps/exterior', {
    onSuccess: () => {
      refetchActiveQueries()
      dispatch(showChangesSavedToast())
    },
    onError: () => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: t("We weren't able to update the exterior map. Please try again later."),
          isAlert: true
        })
      )
    }
  })

  const handleExteriorMapImageUpdate = async (image: File) => {
    const formData = createFormData({ image })
    await updateExteriorMap(formData)
  }
  const confirmReplaceExteriorMap = () => {
    dispatch(
      confirm({
        title: t('Replace Exterior Map?'),
        message: (
          <>
            <p>{t('This Exterior Map is currently associated with building pins.')}</p>
            <p>
              {t(
                'Please check your building pin locations once the new map is uploaded and adjust if needed.'
              )}
            </p>
            <p>
              {t(
                'Note: the Exterior Map helps visitors orient themselves in your outdoor space (if any).'
              )}
            </p>
          </>
        ),
        confirmYes: {
          action: () => replaceExteriorMapRef.current!.click(),
          label: t('Replace Map')
        }
      })
    )
  }
  const confirmDeleteExteriorMap = () => {
    const messageWithBuildings = (
      <>
        <p>
          {t(
            'Are you sure you want to delete the Exterior Map? This will also delete its associated building pin.'
          )}
        </p>
        <p>{t('Note: Remaining building will be shown in the Map tab in the app by default.')}</p>
        <PermanentActionMessage />
      </>
    )

    const messageWithoutBuildings = (
      <>
        <p>{t('Are you sure you want to delete the Exterior Map?')}</p>
        <PermanentActionMessage />
      </>
    )

    dispatch(
      confirm({
        title: t('Delete Exterior Map?'),
        message: _.isEmpty(buildings) ? messageWithoutBuildings : messageWithBuildings,
        confirmYes: {
          action: async () => {
            await deleteExterior()
          },
          label: t('Delete Map'),
          isDestructive: true
        }
      })
    )
  }

  const options: IMenuOption[] = _.compact([
    {
      label: t('Replace Map'),
      onClick: confirmReplaceExteriorMap
    },
    buildings.length <= 1
      ? {
          label: t('Delete Map'),
          onClick: confirmDeleteExteriorMap
        }
      : null,
    EDIT_GOOGLE_MAPS_INFO || exteriorMap?.isGoogleMap
      ? {
          label: t('Edit Map'),
          onClick: () => navigate('edit')
        }
      : null
  ])

  const hasExteriorMap = !_.isEmpty(exteriorMap)

  return (
    <EditorContainer>
      <AddExteriorMap isHidden={hasExteriorMap} />
      {hasExteriorMap && (
        <ExteriorMapSection selected={isExteriorMapSelected}>
          <ExteriorMapLeftSection onClick={() => onExteriorMapSelected(exteriorMap.id)}>
            {t('Exterior Map')}
            <MapImageInput
              onFileChange={handleExteriorMapImageUpdate}
              id="replace-exterior-map"
              ref={replaceExteriorMapRef}
            />
          </ExteriorMapLeftSection>
          <MenuPopout options={options} />
          {(isDeletingExteriorMap || isUpdatingExteriorMap) && <LoadingOverlay />}
        </ExteriorMapSection>
      )}

      <BuildingList onFloorSelected={onSelect} />
      <AddBuilding />

      <Routes>
        <Route path="edit" element={<GoogleMapsForm exteriorMapId={exteriorMap?.id} />} />
      </Routes>
    </EditorContainer>
  )
}

export default MapSidebar
