import UserMFAType from 'shared/UserMFAType'
import UserPermissionType from 'shared/UserPermissionType'

export type ParsedTokenType = {
  email: string | null
  name: string | null
  firstName: string | null
  lastName: string | null
  qrDataURL: string | null
  mfaPassed: boolean
  mfaEnrolled: boolean
  mfaEnabled?: boolean
  permissionType: UserPermissionType | null
  isPasswordExpired: boolean
  privacyPolicyAcceptedAt: string | null
  mfaType: UserMFAType | null
  isUserLoggingInForFirstTime?: boolean
}

export const parseTokenToObject = (token: string): ParsedTokenType => {
  if (token) {
    const buffer = Buffer.from(token.split('.')[1], 'base64')
    return JSON.parse(buffer.toString('utf8'))
  }

  return {
    email: null,
    name: null,
    firstName: null,
    lastName: null,
    qrDataURL: null,
    mfaPassed: false,
    mfaEnrolled: false,
    permissionType: null,
    isPasswordExpired: false,
    privacyPolicyAcceptedAt: null,
    mfaType: null
  }
}
