import _ from 'lodash'
import { AxiosError } from 'axios'
import { ApolloError, ServerError } from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'

function isServerError(networkError: NetworkError): networkError is ServerError {
  return !!_.get(networkError, 'result')
}

function getNetworkErrorDetails(networkError: NetworkError): { message: string }[] | undefined {
  if (isServerError(networkError)) {
    if (typeof networkError.result === 'string') {
      return [{ message: networkError.result }]
    }
    return networkError.result.errors
  }
}

function defaultIfEmpty(obj: any, defaultValue: any = null) {
  return _.isEmpty(obj) ? defaultValue : obj
}

export function extractGQLError(error: ApolloError) {
  // There's a bug that prevents the error.graphQLErrors property from being populated
  // https://github.com/apollographql/apollo-client/issues/6222
  const graphQLErrors = defaultIfEmpty(error.graphQLErrors)
  const networkErrors = getNetworkErrorDetails(error.networkError)

  const errorMessage = [{ message: error.message }]
  const errorDetails = graphQLErrors || networkErrors || errorMessage
  return _.map(
    errorDetails,
    (e) =>
      `${e.message}\n${
        e.extensions?.stacktrace ? JSON.stringify(e.extensions.stacktrace, null, 2) : ''
      }`
  )
}

function extractAxiosError(error: AxiosError<{ message: string }>) {
  const isAxiosError = _.get(error, 'name') === 'AxiosError'
  const axiosErrorMessage = isAxiosError && error?.response?.data?.message
  return axiosErrorMessage
}

export type IExtractDisplayErrorMessageProps =
  | ApolloError
  | AxiosError<{ message: string }>
  | Error
  | string
  | undefined

export default function extractDisplayErrorMessage(error: IExtractDisplayErrorMessageProps) {
  if (_.isString(error)) {
    return error
  }

  if (error instanceof AxiosError) {
    return extractAxiosError(error)
  }

  if (error instanceof ApolloError) {
    return defaultIfEmpty(extractGQLError(error), error)
  }

  return error?.message
}
