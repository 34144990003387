export async function props(promiseMap: { [key: string]: Promise<any> }) {
  const results = {} as any
  const entries = Object.entries(promiseMap)
  entries.forEach(([key, value]) => {
    value
      .then((result) => {
        results[key] = result
        return result
      })
      .catch((e) => {
        results[key] = e
      })
  })

  const promises = Object.values(promiseMap)
  await Promise.all(promises)

  return results
}

export const delay = (milliseconds: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, milliseconds)
  })

export const randomDelay = (upToMs: number) => delay(Math.floor(Math.random() * upToMs))
