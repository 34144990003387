import styled from 'styled-components'
import Spinner, { ISpinnerProps } from 'client/dsm/Spinner/Spinner'
import { IThemeObject, ThemeType } from 'client/types'
import { createPortal } from 'react-dom'
import { Label1CSS } from 'client/components/TextStyles'

const Root = styled.div<{ type?: ThemeType }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-color: ${({ type }) =>
    type === ThemeType.DARK ? 'var(--overlay-dark-05)' : 'var(--overlay-light-05)'};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoadingText = styled.div`
  ${Label1CSS};
  margin: 16px;
`

interface IProps extends Pick<ISpinnerProps, 'progress'>, IThemeObject {
  loadingText?: string | null
  className?: string
  fullScreen?: boolean
}

const LoadingOverlay = ({
  loadingText,
  progress,
  className,
  type = ThemeType.LIGHT,
  fullScreen = false
}: IProps) => {
  const loadingComponent = (
    <Root data-testid="loading-spinner" className={className} type={type}>
      <Spinner progress={progress} />
      {loadingText && <LoadingText>{loadingText}</LoadingText>}
    </Root>
  )

  return fullScreen ? createPortal(loadingComponent, document.body) : loadingComponent
}

export default LoadingOverlay
