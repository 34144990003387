import { useQuery } from '@apollo/client'
import { IBuildingById } from 'client/types'
import gql from 'graphql-tag'
import _ from 'lodash'
import { IMapJson } from 'shared/json/IMapJson'

const MUSEUM_BUILDINGS_QUERY = gql`
  query museumBuildings($museumId: Int!) {
    museum(id: $museumId) {
      exteriorMap {
        id
        uuid
        title
        imageUrl
        optimizedSize
        position
        height
        width
        isDefault
        isGoogleMap
        cameraCenterLatitude
        cameraCenterLongitude
        cameraHeading
        cameraZoom
        mapLocations {
          id
          name
          mapId
          radius
          xPosition
          yPosition
          longitude
          latitude
          map {
            id
          }
        }
      }
      buildings {
        id
        uuid
        name
        position
        mapLocation {
          id
          name
          xPosition
          yPosition
          radius
          longitude
          latitude
          map {
            id
          }
        }
        maps {
          id
          uuid
          title
          imageUrl
          optimizedSize
          position
          height
          width
          isDefault
          isGoogleMap
          cameraCenterLatitude
          cameraCenterLongitude
          cameraHeading
          cameraZoom
          mapLocations {
            id
            name
            mapId
            radius
            xPosition
            yPosition
            longitude
            latitude
            map {
              id
            }
          }
        }
      }
    }
  }
`

export const useBuildings = () => {
  const { data, loading, error } = useQuery(MUSEUM_BUILDINGS_QUERY)

  const result = _.map(data?.museum?.buildings, ({ maps, ...building }) => {
    const floors = _.map(maps, (map) => ({
      ...map,
      optimizedUrl: map.imageUrl,
      buildingId: building.id,
      mapLocations: _.map(map.mapLocations, (mapLocation) => ({
        ...mapLocation,
        mapId: mapLocation.map.id
      }))
    }))
    return {
      ...building,
      floors,
      mapLocation: building.mapLocation,
      mapLocationId: building.mapLocation?.id || null
    }
  })
  const buildings: IBuildingById = _.keyBy(result, 'id')
  const exteriorMap: IMapJson = data?.museum?.exteriorMap

  return { buildings, exteriorMap, loading, error }
}
