import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  UsageReportServiceJobStatus,
  UsageReportServiceJobType
} from 'shared/constants/usageReport'
import { getFullUrl } from 'client/util/api'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import Banner, { BannerItem } from 'client/dsm/Banner/Banner'
import PageContent from 'client/components/PageContent/PageContent'
import GQLErrorRenderer from 'client/components/GQLErrorRenderer'
import Spinner from 'client/dsm/Spinner/Spinner'
import ErrorDialog from 'client/components/ErrorDialog'
import { usePost } from 'client/hooks/api'
import useGuideVisibility from 'client/screens/Reports/useGuideVisibility'
import { t } from 'client/i18n'
import { format } from 'date-fns'
import { UNIVERSAL_DATE_FORMAT } from 'shared/constants/dates'
import GenerateReportView from './GenerateReportView'
import CompletedReportView from './CompletedReportView'
import useLatestJob from './useLatestJob'

const API_USAGE_REPORT_BASE_URL = '/reports/usage-report'

const UsageReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ContainerContent = styled.div<{ disabled?: boolean }>`
  width: 100%;
  padding: var(--container-padding);
  display: inline-flex;
  flex-direction: column;
  color: ${({ disabled = false }) => (disabled ? 'var(--color-grey-04)' : 'inherit')};
  position: relative;
  height: 100%;

  .components-Form-DateRangeInput-DayPickerInput__DayPickerInput {
    position: relative;
    margin-right: 0;
    background-color: red;
  }
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const PrivateGuideWarningBanner = () => (
  <Banner type="warning" headingText={t('Private Guide')}>
    <BannerItem>
      {t(
        'This guide is currently set to Private. Reports can be generated for Public guides only.'
      )}
    </BannerItem>
  </Banner>
)

const ReportErrorBanner = ({ job }: { job: UsageReportServiceJobType }) => (
  <Banner type="error" headingText={t('Report not generated')}>
    <BannerItem>
      {t('Try generating the report __startDate__ to __endDate__ again.', {
        startDate: format(job.startDate, UNIVERSAL_DATE_FORMAT),
        endDate: format(job.endDate, UNIVERSAL_DATE_FORMAT)
      })}
    </BannerItem>
  </Banner>
)

interface IUsageReportsProps {
  isLoaner?: boolean
}

const UsageReports = ({ isLoaner = false }: IUsageReportsProps) => {
  const pageTitle = isLoaner ? t('Loaner Device Usage') : t('Usage')

  const { error: graphqlError, isPrivate, publicDate } = useGuideVisibility()

  const [showGenerateReportView, setShowGenerateReportView] = useState(false)
  const [isWaitingToPullInLatestGenerateRequestJob, setIsWaitingToPullInLatestGenerateRequestJob] =
    useState(false)

  const [generateReportRequestErrorDetails, setGenerateReportRequestErrorDetails] = useState<
    string | null
  >(null)

  const [requestNewReport] = usePost(
    `${API_USAGE_REPORT_BASE_URL}/create-job?isLoaner=${isLoaner ? 'true' : 'false'}`,
    {
      onError: (e) => {
        setGenerateReportRequestErrorDetails(e.message)
        setIsWaitingToPullInLatestGenerateRequestJob(false)
      }
    }
  )
  const { job, isRefreshed: isLatestJobRefreshed, isLoadingInitialFetch } = useLatestJob(isLoaner)
  const isJobProcessing =
    job?.status === UsageReportServiceJobStatus.PROCESSING ||
    isWaitingToPullInLatestGenerateRequestJob
  const isJobCompleted = job?.status === UsageReportServiceJobStatus.COMPLETE
  const isJobError = job?.status === UsageReportServiceJobStatus.ERROR

  // If the generate new report request completes and we've pulled in that latest
  // job, then reset the states, else we continue to allow it to effect the isJobProcessing variable above.
  useEffect(() => {
    setIsWaitingToPullInLatestGenerateRequestJob((isWaiting) => {
      if (isWaiting && isLatestJobRefreshed) {
        setShowGenerateReportView(false)
        return false
      }
      return isWaiting
    })
  }, [isLatestJobRefreshed])

  const handleGenerateReport = async (startDate: Date, endDate: Date) => {
    setIsWaitingToPullInLatestGenerateRequestJob(true)
    await requestNewReport({ startDate: startDate.getTime(), endDate: endDate.getTime() })
  }

  const handleDownloadReport = () =>
    window.open(
      getFullUrl(
        `${API_USAGE_REPORT_BASE_URL}/download-latest?isLoaner=${isLoaner ? 'true' : 'false'}`
      )
    )

  if (graphqlError) {
    return (
      <PageContent title={pageTitle}>
        <ContainerContent disabled={isPrivate}>
          <GQLErrorRenderer error={graphqlError} />
        </ContainerContent>
      </PageContent>
    )
  }

  if (isLoadingInitialFetch) {
    return (
      <PageContent title={pageTitle}>
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </PageContent>
    )
  }

  if (isJobCompleted && !showGenerateReportView) {
    return (
      <PageContent title={pageTitle}>
        <ContainerContent disabled={isPrivate}>
          <CompletedReportView
            job={job}
            onDownload={handleDownloadReport}
            onNewReport={() => setShowGenerateReportView(true)}
            isLoaner={isLoaner}
          />
        </ContainerContent>
      </PageContent>
    )
  }

  return (
    <PageContent title={pageTitle}>
      <UsageReportContainer>
        {generateReportRequestErrorDetails && (
          <ErrorDialog
            title={t('Unable to Generate Report')}
            error={generateReportRequestErrorDetails}
            onClose={() => setGenerateReportRequestErrorDetails(null)}
          />
        )}
        {isPrivate && <PrivateGuideWarningBanner />}
        {isJobError && <ReportErrorBanner job={job} />}
        <ContainerContent disabled={isPrivate}>
          {isJobProcessing && <LoadingOverlay loadingText={t('Generating Report')} />}
          <GenerateReportView
            disabled={isPrivate}
            publicDate={publicDate}
            isGeneratingReport={isJobProcessing}
            onGenerateReport={handleGenerateReport}
          />
        </ContainerContent>
      </UsageReportContainer>
    </PageContent>
  )
}

export default UsageReports
