import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import _ from 'lodash'
import { t } from 'client/i18n'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import * as React from 'react'
import { Nil } from 'shared/util/types'

interface IAudioFileMenuPopoutProps {
  id: number | Nil
  selectedLocaleCode: string
  onReplace: () => void
  isProcessing: boolean
  processingError: any
  localFile: any
}
function AudioFileMenuPopout(props: IAudioFileMenuPopoutProps) {
  const { id, selectedLocaleCode, isProcessing, processingError, localFile, onReplace } = props
  const { DOWNLOAD_ASSETS } = useFeatureFlags()
  const canDownload = DOWNLOAD_ASSETS && id && !localFile

  const options: IMenuOption[] = _.compact([
    {
      label: t('Replace Audio'),
      onClick: () => onReplace()
    },

    canDownload && {
      label: t('Download Original Audio'),
      onClick: () => window.open(`/api/download/audio/${id}/${selectedLocaleCode}/sourceUrl`)
    },

    canDownload && {
      label: t('Download Audio'),
      onClick: () => window.open(`/api/download/audio/${id}/${selectedLocaleCode}`),
      disabled: isProcessing || processingError,
      disabledTooltip: isProcessing
        ? t('Audio is processing')
        : t('Unable to download the audio file with errors.')
    }
  ])

  return <MenuPopout options={options} />
}

export default React.memo(AudioFileMenuPopout)
