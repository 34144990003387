import { t } from 'client/i18n'
import useField from 'client/hooks/useField'
import EnhancedStandardForm from 'client/components/StandardForm/EnhancedStandardForm'
import TextInput from 'client/components/Formik/TextInput/TextInput'
import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { Trans } from 'react-i18next'
import SupportLink from 'client/components/SupportLink'
import { languageOptions } from 'client/screens/shared'
import FormField from 'client/components/Form/FormField/FormField'
import SelectBox, { ISelectBoxProps } from 'client/components/Form/SelectBox/SelectBox'
import { usePut } from 'client/hooks/api'
import * as yup from 'yup'
import _ from 'lodash'
import { useErrorDialog } from 'client/components/ErrorDialog'
import styled from 'styled-components'
import { flash } from 'client/redux/actions/flash'
import { useDispatch } from 'react-redux'
import { refetchActiveQueries } from 'client/apollo'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const USER_QUERY = gql`
  query user {
    user {
      id
      email
      firstName
      lastName
      language
    }
  }
`

const LanguageSelectBox = styled(SelectBox)`
  margin-top: var(--spacing-xsmall);
`

const validationSchema = yup.object().shape({
  firstName: yup.string().label(t('First Name')).nullable().required(),
  lastName: yup.string().label(t('Last Name')).nullable().required()
})

const FormView = () => {
  const { value: languageValue, setValue: setLanguageValue } = useField('language')
  const handleChange: ISelectBoxProps['onChange'] = (e) => {
    setLanguageValue(e.target.value)
  }

  return (
    <>
      <TextInput
        name="email"
        label={t('* Email')}
        disabled={true}
        additionalLabelNode={() => (
          <ContextualHelp
            helpSize="large"
            header={t('Email')}
            helpContent={
              <ContextualHelpTextSection>
                <p>{t('Your email address cannot be changed on this form.')}</p>
                <p>
                  <Trans
                    i18nKey="emailSupportToUpdateEmail"
                    components={{ SupportLink: <SupportLink /> }}
                  />
                </p>
              </ContextualHelpTextSection>
            }
            tooltipContent={t('More on your email')}
            tooltipPlacement="right"
          />
        )}
      />
      <TextInput name="firstName" label={t('* First Name')} autoComplete="off" />
      <TextInput name="lastName" label={t('* Last Name')} autoComplete="off" />
      <FormField label={t('Language')}>
        <LanguageSelectBox
          name="language"
          options={languageOptions}
          onChange={handleChange}
          value={_.find(languageOptions, { value: languageValue })}
        />
      </FormField>
    </>
  )
}
interface IUserProfileFormProps {
  onClose: () => void
}

const UserProfileForm = (props: IUserProfileFormProps) => {
  const { onClose } = props
  const { data, loading: isFetchingUser } = useQuery(USER_QUERY)
  const user = data?.user

  const initialValues = {
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
    language: user?.language
  }

  // TODO: Currently the EnhancedStandardForm only handles client/validation errors. It should also handle server errors so that we don't have to use useErrorDialog.
  const [errorDialog, setError] = useErrorDialog()
  const dispatch = useDispatch()
  const [updateUserProfile] = usePut('/user-profile', {
    onSuccess: (updatedUserProfile) => {
      onClose()
      refetchActiveQueries()

      // Reload so that the CMS is displayed in the user's updated language
      if (initialValues.language !== updatedUserProfile.language) {
        window.location.reload()
      }
      dispatch(flash(t('Changes saved')))
    },
    onError: (error) => {
      setError({
        error,
        title: t('Unable to Complete Request')
      })
    }
  })

  return (
    <EnhancedStandardForm
      validationSchema={validationSchema}
      contentName="Profile"
      contentId={user?.id}
      initialValues={initialValues}
      onSubmit={(values) => updateUserProfile(values)}
      onClose={onClose}
      isLoading={isFetchingUser}
    >
      <FormView />
      {errorDialog}
    </EnhancedStandardForm>
  )
}

export default UserProfileForm
