import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { IBuildingJson } from 'shared/json/IBuildingJson'
import { useNavigate } from 'react-router-dom'
import { confirm } from 'client/redux/actions/confirmation'
import { getDeleteMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/buildings'
import { t } from 'client/i18n'
import { refetchActiveQueries } from 'client/apollo'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import { useDelete, usePost } from 'client/hooks/api'
import { GQLMap } from 'shared/graphql/types/graphql'
import { createFormData } from 'client/screens/AppEditor/MapEditor/MapEditorUtils'
import useNumericRouteParam from 'client/hooks/useNumericRouteParam'
import FloorList from '../FloorEditor/FloorList'
import BuildingHeader from './BuildingHeader'

const BuildingItemContainer = styled.div`
  border-bottom: var(--border-light);
  padding: 0px 16px 24px 16px;
`

export const BuildingItem = ({
  currentBuilding,
  onFloorSelected
}: {
  currentBuilding: IBuildingJson
  onFloorSelected: (buildingId: number, floorId: number) => void
}) => {
  const selectedBuildingId = useNumericRouteParam('buildingId')
  const selectedFloorId = useNumericRouteParam('floorId')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [addFloor, isAddingFloor] = usePost<GQLMap>(`/maps/building/${currentBuilding.id}/floor`, {
    onSuccess: async (data) => {
      // The reason is we want to await the front end get the added building images at the time it navigate to that
      // url, otherwise, there would be a very short amount of time the map preview show an empry view message because it do not get the updated image info from the gql yet.
      await refetchActiveQueries()
      navigate(`/app-editor/maps/building/${currentBuilding.id}/floor/${data.id}`)
      dispatch(showChangesSavedToast())
    },
    onError: () => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: t("We weren't able to create the floor. Please try again later."),
          isAlert: true
        })
      )
    }
  })

  const [deleteBuilding, isDeletingBuilding] = useDelete(`/maps/building/${currentBuilding.id}`, {
    onSuccess: async () => {
      await refetchActiveQueries()
      if (currentBuilding.id === selectedBuildingId) {
        navigate('/app-editor/maps')
      }
      dispatch(showChangesSavedToast())
    },
    onError: () => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: t("We weren't able to delete the building. Please try again later."),
          isAlert: true
        })
      )
    }
  })

  const handleFloorAdd = async (image: File) => {
    const formData = createFormData({ image })
    await addFloor(formData)
  }

  const handleDeleteBuilding = () => {
    const { title, message } = getDeleteMessage(currentBuilding.floors.length > 0)

    dispatch(
      confirm({
        title,
        message,
        confirmYes: {
          action: async () => {
            await deleteBuilding()
          },
          label: t('Delete Building'),
          isDestructive: true
        }
      })
    )
  }

  return (
    <BuildingItemContainer>
      <BuildingHeader
        name={currentBuilding.name}
        onEdit={() => navigate(`buildings/${currentBuilding.id}/edit`)}
        onDelete={handleDeleteBuilding}
      />
      <FloorList
        id={currentBuilding.id}
        floors={currentBuilding.floors}
        selectedFloorId={selectedFloorId}
        onFloorSelected={(id) => onFloorSelected(currentBuilding.id, id)}
        onFloorAdd={(file) => handleFloorAdd(file)}
      />
      {(isAddingFloor || isDeletingBuilding) && <LoadingOverlay />}
    </BuildingItemContainer>
  )
}
