import { useState, useEffect } from 'react'
import _ from 'lodash'
import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd'
import { t } from 'client/i18n'
import { useDispatch } from 'react-redux'
import { confirm } from 'client/redux/actions/confirmation'
import { usePut } from 'client/hooks/api'
import { refetchActiveQueries } from 'client/apollo'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { GQLMap } from 'shared/graphql/types/graphql'
import { IMapJson } from 'shared/json/IMapJson'
import FloorRow from './FloorRow'
import {
  EditorListContainer,
  EditorHeaderFooter,
  EditorBullet,
  EditorFloorContainer,
  IEditorHeaderFooter
} from './styledComponents'
import AddFloorImageButton from './AddFloorImageButton'

interface IFloorLevelLabel extends IEditorHeaderFooter {
  label: string
}

const FloorLevelLabel = (props: IFloorLevelLabel) => (
  <EditorHeaderFooter isHighestLevel={props.isHighestLevel} isLowestLevel={props.isLowestLevel}>
    <EditorBullet />
    {props.label}
  </EditorHeaderFooter>
)

interface IFloorListProps {
  id: number
  floors: IMapJson[]
  selectedFloorId?: number
  onFloorSelected?: (id: number) => void
  onFloorAdd?: (file: File) => void
}

const FloorList = (props: IFloorListProps) => {
  const {
    id,
    floors: floorsRaw,
    selectedFloorId,
    onFloorSelected = _.noop,
    onFloorAdd = _.noop
  } = props

  const [floors, setFloors] = useState<any>(_.sortBy(floorsRaw, 'position').reverse())

  useEffect(() => {
    setFloors(_.sortBy(floorsRaw, 'position').reverse())
  }, [floorsRaw])

  const [editFieldId, setEditFieldId] = useState<number | null>(null)

  const dispatch = useDispatch()

  const [updateFloorOrder] = usePut(`/maps/building/${id}/floor/reorder`, {
    onSuccess: () => {
      refetchActiveQueries()
      dispatch(showChangesSavedToast())
    },
    onError: () => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: t("We weren't able to change the order of these floors. Please try again later"),
          isAlert: true
        })
      )
    }
  })

  const handleFloorsRepositioned = async (orderedFloorIds: any[]) => {
    const preOrderedFloors = _.cloneDeep(floorsRaw)
    const repositionedFloors = _.map(orderedFloorIds, (floorId, index) => {
      const floorIndex = _.findIndex(floorsRaw, { id: floorId })

      if (floorIndex >= 0) {
        preOrderedFloors[floorIndex] = {
          ...preOrderedFloors[floorIndex],
          position: index
        }
      }
      return preOrderedFloors[floorIndex]
    }).reverse()

    setFloors(repositionedFloors)

    await updateFloorOrder({
      orderedFloorIds
    })
  }

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result
    if (destination && destination.index !== source.index) {
      const newId = _.toNumber(draggableId)
      const sortedIds = _.map(floors, 'id')
      const newFloorIds = _.without(sortedIds, newId)
      newFloorIds.splice(destination.index, 0, newId)
      handleFloorsRepositioned(newFloorIds.reverse())
    }
  }

  const handleBeginEditing = (fieldId: number) => {
    setEditFieldId(fieldId)
  }

  const handleEndEditing = (fieldId: number) => {
    if (editFieldId === fieldId) {
      setEditFieldId(null)
    }
  }

  return (
    <>
      {floors.length > 1 && <FloorLevelLabel label={t('Highest Floor')} isHighestLevel={true} />}
      <EditorListContainer count={floors.length}>
        <AddFloorImageButton onUpload={onFloorAdd} id={`add-map-image-button-${id}`} />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="floor-dropper">
            {(providerOuter) => (
              <EditorFloorContainer ref={providerOuter.innerRef} count={floors.length}>
                {floors.map((floor: GQLMap, index: number) => (
                  <Draggable
                    key={`draggable-floor-${floor.id}`}
                    draggableId={floor.id.toString()}
                    index={index}
                    isDragDisabled={floors.length === 1}
                  >
                    {(provided, snapshot) => (
                      <div
                        key={`draggable-div${floor.id}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <FloorRow
                          onClick={() => onFloorSelected(floor.id)}
                          buildingId={id}
                          floor={floor}
                          editing={editFieldId === floor.id}
                          isDefault={floor.isDefault}
                          selected={floor.id === selectedFloorId}
                          dragging={snapshot.isDragging}
                          onBeginEditing={() => handleBeginEditing(floor.id)}
                          onEndEditing={() => handleEndEditing(floor.id)}
                          showGrabber={floors.length > 1}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </EditorFloorContainer>
            )}
          </Droppable>
        </DragDropContext>
      </EditorListContainer>
      {floors.length > 1 && <FloorLevelLabel label={t('Lowest Floor')} isLowestLevel={true} />}
    </>
  )
}

export default FloorList
