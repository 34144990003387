import { useField as useFormikField } from 'formik'

export default function useField<T = any>(name: string) {
  const [field, meta, helpers] = useFormikField<T>(name)

  const { value } = field
  const { error, touched } = meta
  const { setValue, setTouched } = helpers

  return {
    name,
    value,
    error,
    touched,
    setValue,
    setTouched
  }
}
