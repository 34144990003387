import { createContext } from 'react'
import { FormikValues } from 'formik'
import { GQLLocale } from 'shared/graphql/types'
import { ContentName } from 'client/components/TranslationForm/types'
import _ from 'lodash'

// TODO: reconsider this API to align better with standard Formik
interface ITranslationFormContext {
  contentId?: number | null
  values: FormikValues | null
  initialValues: FormikValues | null
  locales: GQLLocale[]
  availableLocales?: GQLLocale[]
  isDefaultLocaleSelected: boolean
  setFieldValue: (name: string, value: any) => void
  getFieldValue: (name: string) => any
  // @ts-ignore DOCNT-10958
  setLoadingStatus(loadingStatus: number | boolean | undefined, text?: string)
  getFieldName: (name: string) => string
  onError: (e: Error) => void // gives access to a generic error dialog for lower level components
  contentName: ContentName
  defaultLocale?: GQLLocale | null
  selectedLocale: GQLLocale | null
}

export const TranslationFormContext = createContext<ITranslationFormContext>({
  contentId: null,
  values: null,
  initialValues: null,
  locales: [],
  availableLocales: [],
  isDefaultLocaleSelected: true,
  getFieldName: () => '',
  getFieldValue: _.noop,
  setFieldValue: _.noop,
  setLoadingStatus: _.noop,
  onError: _.noop,
  contentName: '' as ContentName, // // TODO is there a better way to handle this initail context state?
  defaultLocale: null,
  selectedLocale: null
})
