import { put, takeEvery, call } from 'redux-saga/effects'
import api from 'client/util/api'
import { FETCH_BULK_UPLOAD_START } from 'client/redux/actions/types'
import { fetchBulkUploadError, fetchBulkUploadSuccess } from 'client/redux/actions/bulkUpload'
import { AxiosResponse } from 'axios'

function* fetchBulkUpload() {
  try {
    const result: AxiosResponse = yield call(api.get, 'bulk-upload/latest')
    yield put(fetchBulkUploadSuccess(result.data))
  } catch {
    yield put(fetchBulkUploadError())
  }
}

export default function* root(): IterableIterator<any> {
  yield takeEvery(FETCH_BULK_UPLOAD_START, fetchBulkUpload)
}
