import { useParams } from 'react-router-dom'

const useNumericRouteParam = (key: string) => {
  const params = useParams()
  const arg = params[key]
  const parsedValue = arg ? parseInt(arg, 10) : undefined
  return Number.isFinite(parsedValue) ? parsedValue : undefined
}

export default useNumericRouteParam
