import { useContext } from 'react'
import gql from 'graphql-tag'
import TranslationForm from 'client/components/TranslationForm/TranslationForm'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import useNumericRouteParam from 'client/hooks/useNumericRouteParam'
import ExhibitDisplayType from 'shared/ExhibitDisplayType'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import FormFieldSection from 'client/components/TranslationForm/TranslatableFormFieldSection'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import RichTextEditor from 'client/components/Formik/RichTextEditor/RichTextEditor'
import AudiosList from 'client/components/PickerLists/AudiosList'
import VideosList from 'client/components/PickerLists/VideosList'
import RelatedContent from 'client/screens/Catalog/forms/shared/RelatedContent'
import MapLocations from 'client/screens/Catalog/forms/shared/MapLocations'
import DisplayPeriod from 'client/screens/Catalog/forms/shared/DisplayPeriod'
import ItemsHeadingSelect from 'client/screens/Catalog/forms/ExhibitForm/ItemsHeadingSelect'
import { createApiConfig } from 'client/components/TranslationForm/util'
import useValidateFeatured from 'client/components/TranslationForm/useValidateFeatured'
import DisplayType from 'client/screens/Catalog/forms/ExhibitForm/DisplayType'
import ExhibitLookupNumberSelect from 'client/screens/Catalog/forms/shared/LookupNumber/ExhibitLookupNumberSelect'
import ExhibitItemList from 'client/screens/Catalog/forms/shared/ExhibitItemList'
import ImagesListField from 'client/screens/Catalog/forms/shared/ImagesListField'
import { ExhibitInput } from 'shared/graphql/mutations/ExhibitInput'
import { ExhibitionType } from 'shared/constants/exhibits'
import { ExhibitionSchemaClient } from 'client/validation/Exhibition'
import { EXHIBITION_TITLE_MAX_LENGTH } from 'shared/validation/constants'
import Label from 'client/components/Form/Label/Label'
import styled from 'styled-components'
import { t } from 'client/i18n'
import extractFromToDatesAndCleanupExpirationData from 'client/screens/Catalog/forms/shared/fromToDates'
import {
  extractItemsForExhibitAndTour,
  parseInitialValuesForExhibitAndTour
} from 'client/util/exhibits'
import LinkedGuideSelectBoxField from 'client/screens/Catalog/forms/shared/LinkedGuideSelectBoxField'

const StyledLabel = styled(Label)`
  margin-bottom: var(--spacing-xsmall);
`

const apiConfig = createApiConfig(
  'exhibit',
  gql`
    query getSingleExhibit($id: Int!) {
      exhibit(id: $id) {
        id
        type
        externalId
        uuid
        displayPeriod
        from
        to
        timezone
        lookupNumber
        itemsHeading
        displayType
        linkedGuideId
        lastExpiredDate
        expirationEnabled
        hideOnExpiration
        images {
          id
        }
        audios {
          id
        }
        videos {
          id
        }
        items {
          id
          lookupNumber
          itemMapLocation {
            mapLocation {
              id
            }
          }
        }
        relatedContent {
          item {
            uuid
            id
          }
          exhibit {
            uuid
            id
          }
          relationshipType
        }
        exhibitMapLocations {
          mapLocation {
            id
          }
          featured
        }
        translations {
          locale {
            englishName
            code
          }
          title
          information
        }
      }
    }
  `
)

const ExhibitFormView = () => {
  const { getFieldName } = useContext(TranslationFormContext)

  return (
    <>
      <ImagesListField name={getFieldName('images')} />

      <TextInputFormField
        label={t('* Title')}
        name={getFieldName('title')}
        maxLength={EXHIBITION_TITLE_MAX_LENGTH}
      />

      <DisplayPeriod />

      <RichTextEditor
        name={getFieldName('information')}
        label={t('Description')}
        fieldContainer={FormField}
      />

      <LinkedGuideSelectBoxField />

      <FormFieldSection label={t('Audio')} translatable={false}>
        <FormField translatable={false}>
          <AudiosList name={getFieldName('audios')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection label={t('Video')} translatable={false}>
        <FormField translatable={false}>
          <VideosList name={getFieldName('videos')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection
        label={t('Show on Map')}
        description={t(
          'Choose a location pin to help visitors find the content on your map. Manage your pins on the Map page.'
        )}
        translatable={false}
      >
        <FormField translatable={false}>
          <MapLocations name={getFieldName('exhibitMapLocations')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection translatable={false}>
        <FormField translatable={false}>
          <ExhibitLookupNumberSelect name={getFieldName('lookupNumber')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection label={t('Exhibition Items')} translatable={false}>
        <FormField translatable={false}>
          <StyledLabel>{t('Section Header')}</StyledLabel>
          <ItemsHeadingSelect name={getFieldName('itemsHeading')} />
          <ExhibitItemList name={getFieldName('items')} type={ExhibitionType.EXHIBITION} />
          <DisplayType name={getFieldName('displayType')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection label={t('Related')} translatable={false}>
        <FormField translatable={false}>
          <RelatedContent name={getFieldName('relatedContent')} />
        </FormField>
      </FormFieldSection>
    </>
  )
}

const ExhibitForm = () => {
  const type = ExhibitionType.EXHIBITION
  const { validateDeleteFeatured } = useValidateFeatured('exhibit', type)
  const contentId = useNumericRouteParam('id')

  return (
    <TranslationForm
      contentName="Exhibition"
      contentId={contentId}
      apiConfig={apiConfig}
      validationSchema={ExhibitionSchemaClient}
      defaultValues={{
        type,
        displayPeriod: true,
        displayType: ExhibitDisplayType.LIST,
        // only new exhibits created after Expiration feature rolled out can set expirationEnabled to true
        expirationEnabled: true
      }}
      inputType={ExhibitInput}
      initialValuesParser={(values) => parseInitialValuesForExhibitAndTour(values)}
      submitValuesParser={(values) => {
        return {
          ...values,
          items: extractItemsForExhibitAndTour(values.items),
          ...extractFromToDatesAndCleanupExpirationData(values)
        }
      }}
      validateDelete={validateDeleteFeatured}
      additionalFields={{ nontranslatable: ['displayPeriodType'] }}
    >
      <ExhibitFormView />
    </TranslationForm>
  )
}
export default ExhibitForm
