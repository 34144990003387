import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import _ from 'lodash'
import configureReducers from './reducers'
import sagas from './sagas'

const composeMiddleware = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()

function configureStore() {
  const initialState = undefined
  const enhancer = composeMiddleware(applyMiddleware(sagaMiddleware))
  const store = createStore(configureReducers(), initialState, enhancer)

  _.forEach(sagas, (saga) => {
    sagaMiddleware.run(saga)
  })

  return store
}

export default configureStore()
