// @ts-ignore No types found.
import webvtt from 'node-webvtt'

export const isValidVTTData = (data: any) => {
  try {
    webvtt.parse(data, { meta: true })
    return true
  } catch (e) {
    return false
  }
}

export default (file: File): Promise<boolean> =>
  new Promise((resolve) => {
    const reader = new window.FileReader()
    reader.onload = () => resolve(isValidVTTData(reader.result))
    reader.onerror = () => resolve(false)
    reader.readAsText(file)
  })
