import _ from 'lodash'
import styled from 'styled-components'
import { ReactNode, KeyboardEventHandler } from 'react'
import ConnectsLogoSVG from 'client/assets/svg/logo_connects.svg'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import { Body1CSS } from 'client/components/TextStyles'

const Container = styled.div`
  ${Body1CSS};
  width: 100%;
  max-width: 600px;
  padding: 48px;
  border-radius: 16px;
  background-color: var(--color-white);
  box-shadow: var(--elevation-02);
`

const ConnectsLogo = styled(ConnectsLogoSVG)`
  height: auto;
  width: 160px;
  margin-bottom: var(--spacing-large);
`

export interface IContainerWithConnectsLogoProps {
  children: ReactNode
  className?: string
  onKeyPress?: KeyboardEventHandler<HTMLDivElement>
  onEnterPress?: () => void
  isLoading?: boolean
}
const ContainerWithConnectsLogo = ({
  children,
  className,
  isLoading,
  onKeyPress = _.noop,
  onEnterPress = _.noop
}: IContainerWithConnectsLogoProps) => {
  // @ts-ignore DOCNT-10958
  const handleKeyPress = (e) => {
    onKeyPress(e)
    if (e.key === 'Enter') {
      onEnterPress()
    }
  }

  return (
    <Container className={className} onKeyPress={handleKeyPress}>
      {isLoading && <LoadingOverlay />}
      <ConnectsLogo />
      {children}
    </Container>
  )
}

export default ContainerWithConnectsLogo
