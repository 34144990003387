import { useState, ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import MuseumVersionType from 'shared/MuseumVersionType'
import { setSelectedVersionInfo } from 'client/redux/actions/version'
import { useDelete, usePost } from 'client/hooks/api'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  PermanentActionMessage
} from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import { useErrorDialog } from 'client/components/ErrorDialog'
import { flash } from 'client/redux/actions/flash'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import { GQLGuide, GQLMuseum } from 'shared/graphql/types/graphql'
import { ThemeType } from 'client/types'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import GuideVisibilityType from 'shared/GuideVisibilityType'
import api from 'client/util/api'
import { delay } from 'shared/util/promise'
import { t } from 'client/i18n'
import LoadingOverlayWithDialog from 'client/components/LoadingOverlay/LoadingOverlayWithDialog'

interface IConfirmDialogProps {
  onClose: () => void
  onSave: () => void
  header: string
  message: ReactNode
  confirmLabel: string
  confirmActionIsDestructive?: boolean
}
function ConfirmDialog({
  header,
  message,
  onClose,
  onSave,
  confirmLabel,
  confirmActionIsDestructive = false
}: IConfirmDialogProps) {
  const handleSave = () => {
    onSave()
    onClose()
  }
  return (
    <Dialog>
      <DialogHeader>{header}</DialogHeader>
      <DialogBody>{message}</DialogBody>
      <DialogActions>
        <Button onClick={onClose} label={t('Cancel')} />
        <Button
          onClick={handleSave}
          type={confirmActionIsDestructive ? 'danger' : 'primary'}
          label={confirmLabel}
        />
      </DialogActions>
    </Dialog>
  )
}

interface IDraftConfirmDialogProps {
  onSave: () => void
  onClose: () => void
}
function ConfirmDeleteDraftDialog({ onSave, onClose }: IDraftConfirmDialogProps) {
  return (
    <ConfirmDialog
      onClose={onClose}
      onSave={onSave}
      header={t('Delete Draft?')}
      message={
        <>
          <p>{t('Are you sure you want to delete this draft?')}</p>
          <PermanentActionMessage />
        </>
      }
      confirmLabel={t('Delete Draft')}
      confirmActionIsDestructive={true}
    />
  )
}

function ConfirmPromoteDraftDialog({ onSave, onClose }: IDraftConfirmDialogProps) {
  return (
    <ConfirmDialog
      onClose={onClose}
      onSave={onSave}
      header={t('Publish this draft and replace all live content?')}
      message={
        <p>
          {t(
            'Publishing this draft will completely replace all the content that is currently live in your guide. This action cannot be undone.'
          )}
        </p>
      }
      confirmLabel={t('Publish & Replace')}
    />
  )
}

interface IProps {
  selectedVersion: GQLMuseum
  guide: GQLGuide
}
const VersionsMenu = (props: IProps) => {
  const { selectedVersion, guide } = props
  const { versions, code } = guide

  const { MARKETING } = useFeatureFlags()

  const dispatch = useDispatch()

  const draftVersion = _.find(versions, { versionType: MuseumVersionType.PREVIEW })
  const liveVersion = _.find(versions, { versionType: MuseumVersionType.LIVE })

  const setVersionInfoFromMuseum = (museum: GQLMuseum) => {
    dispatch(
      setSelectedVersionInfo({
        guideId: guide.id,
        id: museum.id,
        versionType: museum.versionType as MuseumVersionType
      })
    )
  }

  const [showDeleteDraftConfirmation, setShowDeleteDraftConfirmation] = useState(false)
  const [showPromoteDraftConfirmation, setShowPromoteDraftConfirmation] = useState(false)
  const [errorDialog, setError] = useErrorDialog()

  const [isCreatingDraft, setIsCreatingDraft] = useState(false)
  const [createDraft] = usePost('/preview/create', {
    onSuccess: async () => {
      let newVersion: undefined | GQLMuseum // stringified museum
      while (!newVersion) {
        /* eslint-disable no-await-in-loop */
        const response = await api.get<GQLMuseum>('/preview', { validateStatus: null })
        if (response.status === 200) {
          newVersion = response.data
          setIsCreatingDraft(false)
          setVersionInfoFromMuseum(newVersion)
        } else {
          await delay(1000)
        }
        /* eslint-enable no-await-in-loop */
      }
    },
    onError: (error) => {
      setIsCreatingDraft(false)
      setError({
        error,
        title: t('Unable to create new draft')
      })
    }
  })

  const [promoteDraft, isPromotingDraft] = usePost('/preview/promote', {
    onSuccess: (newVersion: GQLMuseum) => {
      setVersionInfoFromMuseum(newVersion)
    },
    onError: (error) => {
      setShowPromoteDraftConfirmation(false)
      setError({
        error,
        title: t('Unable to publish draft')
      })
    }
  })

  const [deleteDraft, isDeletingDraft] = useDelete('/preview', {
    onSuccess: () => {
      setVersionInfoFromMuseum(liveVersion!)
    },
    onError: (error) =>
      setError({
        error,
        title: t('Unable to delete draft')
      })
  })

  if (isCreatingDraft) {
    return <LoadingOverlayWithDialog loadingText={t('Creating draft...')} />
  }

  if (isPromotingDraft) {
    return <LoadingOverlayWithDialog loadingText={t('Publishing draft...')} />
  }

  if (isDeletingDraft) {
    return <LoadingOverlayWithDialog loadingText={t('Deleting draft...')} />
  }

  const isLiveVersion = selectedVersion.versionType === MuseumVersionType.LIVE
  const isDraftVersion = selectedVersion.versionType === MuseumVersionType.PREVIEW
  const isPublicGuide = guide.visibilityType === GuideVisibilityType.PUBLIC

  let versionSpecificMenuOptions: (IMenuOption | undefined)[] = []

  if (isLiveVersion) {
    versionSpecificMenuOptions = [
      draftVersion
        ? {
            label: t('View Draft'),
            onClick: () => setVersionInfoFromMuseum(draftVersion)
          }
        : {
            label: t('Create New Draft'),
            onClick: () => {
              setIsCreatingDraft(true)
              createDraft()
            }
          }
    ]
  } else if (isDraftVersion) {
    versionSpecificMenuOptions = [
      {
        label: t('Publish Draft'),
        disabled: !isPublicGuide,
        disabledTooltip: (
          <div>{t('Reach out to a Partnership Manager to publish your draft.')}</div>
        ),
        onClick: () => {
          setShowPromoteDraftConfirmation(true)
        }
      },
      liveVersion && {
        label: t('Delete Draft'),
        onClick: () => {
          setShowDeleteDraftConfirmation(true)
        }
      }
    ]
  }

  const options: IMenuOption[] = _.compact([
    ...versionSpecificMenuOptions,
    MARKETING && {
      label: t('Copy Guide Code to Clipboard'),
      onClick: async () => {
        await navigator.clipboard.writeText(code)
        dispatch(flash(t('Copied to clipboard')))
      }
    }
  ])

  return (
    <>
      {errorDialog}
      {showDeleteDraftConfirmation && (
        <ConfirmDeleteDraftDialog
          onSave={deleteDraft}
          onClose={() => setShowDeleteDraftConfirmation(false)}
        />
      )}
      {showPromoteDraftConfirmation && (
        <ConfirmPromoteDraftDialog
          onSave={promoteDraft}
          onClose={() => setShowPromoteDraftConfirmation(false)}
        />
      )}
      <MenuPopout theme={ThemeType.LIGHT} options={options} tooltipPlacement="right" />
    </>
  )
}

export default VersionsMenu
