import { IImageDimensions } from 'shared/constants/images'

export interface ICoordinates {
  x: number
  y: number
  z?: number
}

const getRandomNumber = (minimum: number, maximum: number) => {
  const min = Math.ceil(minimum)
  const max = Math.floor(maximum)
  return Math.floor(Math.random() * (max - min)) + min
}

export const generateCoordinates = (plane: IImageDimensions): ICoordinates => {
  const { width, height } = plane
  const minX = width / 4
  const maxX = width - minX
  const minY = height / 4
  const maxY = height - minY
  const x = getRandomNumber(minX, maxX)
  const y = getRandomNumber(minY, maxY)
  return { x, y }
}
