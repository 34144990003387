import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import _ from 'lodash'
import { GQLMapLocation } from 'shared/graphql/types/graphql'

const MAP_LOCATION_QUERY = gql`
  query getSingleMapLocation($id: Int!) {
    mapLocation(id: $id) {
      id
      name
      latitude
      longitude
      content {
        item {
          id
          title
          commonName
          scientificName
          type
          published
          images {
            id
            hero
          }
          locales {
            id
            code
            englishName
          }
          uuid
        }
        exhibit {
          id
          title
          type
          published
          displayPeriod
          from
          to
          timezone
          isAllDay
          exhibitMapLocations {
            mapLocation {
              id
            }
          }
          images {
            id
            hero
          }
          locales {
            id
            code
            englishName
          }
          uuid
        }

        featured
      }
    }
  }
`

export default function useMapLocationContent(mapLocationId: number | undefined) {
  const { data, loading, error } = useQuery<{ mapLocation: GQLMapLocation }>(MAP_LOCATION_QUERY, {
    variables: { id: mapLocationId },
    skip: _.isNil(mapLocationId)
  })

  // TODO: does this need `position`?
  const mapLocationContents = _.map(data?.mapLocation?.content, (mapLocationContent, index) => ({
    content: mapLocationContent,
    position: index,
    ...mapLocationContent.item,
    ...mapLocationContent.exhibit
  }))

  const { latitude, longitude, name } = data?.mapLocation || {}

  return {
    name,
    mapLocationContents,
    latitude,
    longitude,
    loading,
    error
  }
}
