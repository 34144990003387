import { Body1CSS, Header3CSS } from 'client/components/TextStyles'
import { MouseEventHandler, ReactNode, useCallback } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { t } from 'client/i18n'

interface IProps {
  fullscreen?: boolean
}
const Overlay = styled.div<IProps>`
  position: ${({ fullscreen = true }) => (fullscreen ? 'fixed' : 'absolute')};
  z-index: 10000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-dark-03);
`

const OverlayCentered = styled(Overlay)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DialogHeader = styled.div`
  ${Header3CSS};
  margin: 0 0 var(--spacing-xsmall);
`

export const DialogBody = styled.div`
  ${Body1CSS};
  > p:not(:first-child) {
    margin: 0;
    margin-top: var(--spacing-small);
  }
`

export const PermanentActionMessage = () => (
  <p>{t('This is a permanent action and cannot be undone.')}</p>
)

interface IDialogActions {
  stacked?: boolean
}

export const DialogActions = styled.div<IDialogActions>`
  display: flex;
  margin-top: 40px;
  justify-content: ${({ stacked = false }) => (stacked ? 'flex-start' : 'flex-end')};
  flex-direction: ${({ stacked = false }) => (stacked ? 'column' : 'row')};
  button + button {
    margin-top: ${({ stacked = false }) => (stacked ? 'var(--spacing-xsmall)' : 0)};
    margin-left: ${({ stacked = false }) => (stacked ? 0 : 'var(--spacing-xsmall)')};
  }
`

interface DialogProps {
  children: ReactNode
  scrollable?: boolean
  className?: string
}

export const DIALOG_MAX_WIDTH = '460px'
const DialogDiv = styled.div.attrs({ role: 'dialog' })<DialogProps>`
  min-width: 390px;
  max-width: ${DIALOG_MAX_WIDTH};
  padding: var(--spacing-medium);
  border-radius: 4px;
  background-color: var(--color-white);
  box-shadow: var(--elevation-03);

  ${({ scrollable }) =>
    scrollable &&
    `${DialogBody} {
        border-top: 1px solid var(--color-grey-02);
        margin-left: -24px;
        margin-right: -24px;
        padding: 0 0 var(--spacing-small) var(--spacing-medium);
        > *:first-child:before {
          // Adding a starting line-break before the first content.
          // We're assuming root child in the body renders the content. If that changes
          // we'll have to re-evaluate the automatic add top spacing approach for scrolling dialog.
          content: ' ';
          white-space: pre;
        }
      }
      ${DialogActions} {
        border-top: 1px solid var(--color-grey-02);
        margin: 0px -24px;
        padding: 10px var(--spacing-medium) 0 var(--spacing-medium);
      }
    `}
`

export function Dialog(props: DialogProps) {
  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>((e) => e.stopPropagation(), [])

  return createPortal(
    <OverlayCentered onClick={handleClick}>
      <DialogDiv {...props} className={props.className} />
    </OverlayCentered>,
    document.body
  )
}
