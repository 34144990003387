import { GQLBuilding, GQLMapLocation, GQLMapLocationContent } from 'shared/graphql/types/graphql'
import { useCallback } from 'react'
import ToggleInput, { ToggleInputProps } from 'client/components/Form/ToggleInput/ToggleInput'
import { ShowOnMapRow } from 'client/components/ContentRow/ReorderableRow'
import { t } from 'client/i18n'
import ContentMapLocationSelect from 'client/screens/Catalog/forms/shared/MapLocations/ContentMapLocationSelect'
import { ISelectBoxProps } from 'client/components/Form/SelectBox/SelectBox'

interface IMapLocationRendererProps {
  // TODO: Retype as this does not need to be IBuildingJson. Could become same type as ISelectBoxOptions
  buildings: GQLBuilding[]
  value?: GQLMapLocationContent
  onChange: (mapLocationContent: Partial<GQLMapLocationContent>) => void
  onRemove: () => void
}

export function MapLocationRenderer({
  buildings,
  value,
  onChange,
  onRemove
}: IMapLocationRendererProps) {
  const handleLocationChanged: ISelectBoxProps['onChange'] = useCallback(
    (e) => {
      const mapLocationId = e.target.value

      onChange({
        mapLocation: { id: mapLocationId } as GQLMapLocation,
        featured: value?.featured
      })
    },
    [onChange, value]
  )
  const handleFeatureChanged: ToggleInputProps['onChange'] = useCallback(
    (e) => {
      onChange({
        ...value,
        featured: e.target.value
      })
    },
    [onChange, value]
  )

  const flexComponent = () => (
    <ToggleInput
      label={t('Feature on Map Tab')}
      value={!!(value?.mapLocation && value?.featured)}
      onChange={handleFeatureChanged}
      disabled={!value?.mapLocation}
    />
  )

  return (
    <ShowOnMapRow
      showGrabber={false}
      isDragging={false}
      onRemove={onRemove}
      flexComponent={flexComponent}
      value={value!}
    >
      <ContentMapLocationSelect
        buildings={buildings}
        onChange={handleLocationChanged}
        selectedMapLocationId={value?.mapLocation?.id}
        placeholder={t('Choose a location')}
      />
    </ShowOnMapRow>
  )
}
