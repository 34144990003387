import styled from 'styled-components'
import Tippy, { TippyProps } from '@tippyjs/react'
import _ from 'lodash'
import { ThemeType } from 'client/types'
import { PropsWithChildren, forwardRef, useState } from 'react'
import { Body2CSS } from 'client/components/TextStyles'

// Copied from https://gist.github.com/atomiks/520f4b0c7b537202a23a3059d4eec908
const LazyTippy = forwardRef((props: any, ref) => {
  const [mounted, setMounted] = useState(false)

  const lazyPlugin = {
    fn: () => ({
      onMount: () => setMounted(true),
      onHidden: () => setMounted(false)
    })
  }

  const computedProps = { ...props }

  computedProps.plugins = [lazyPlugin, ...(props.plugins || [])]

  if (props.render) {
    computedProps.render = (...args: any[]) => (mounted ? props.render(...args) : '')
  } else {
    computedProps.content = mounted ? props.content : ''
  }

  return <Tippy {...computedProps} ref={ref} />
})

export interface IDocentTippyProps
  extends PropsWithChildren<Omit<TippyProps, 'theme' | 'children'>> {
  size?: 'large' | 'small' | 'medium'
  themeType?: ThemeType
}

const TOOLTIP_THEME = {
  [ThemeType.LIGHT]: { backgroundColor: 'var(--color-white)', color: 'var(--color-grey-07)' },
  [ThemeType.DARK]: { backgroundColor: 'var(--color-black)', color: 'var(--color-grey-03)' }
}

const StyledTippy = styled((props: IDocentTippyProps) => (
  <LazyTippy {..._.omit(props, 'size')} />
))<IDocentTippyProps>`
  // Docent Specific Override
  background-color: ${({ theme }) => theme.backgroundColor};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: ${({ theme }) => theme.color};
  ${Body2CSS};

  // Partially taken from "tippy.js/dist/tippy.css"
  &[data-animation='fade'][data-state='hidden'] {
    opacity: 0;
  }
  position: relative;
  outline: 0;
  transition-property: transform, visibility, opacity;

  .tippy-content {
    position: relative;
    padding: 0;
    z-index: 1;
    p:last-child {
      margin-bottom: 0;
    }

    padding: ${({ size }) => {
      switch (size) {
        case 'small':
          return '0px'
        case 'large':
          return '24px'
        case 'medium':
          return '16px'
        default:
          return '8px 12px 8px 12px'
      }
    }};
  }

  .tippy-arrow {
    width: 16px;
    height: 16px;
    border-color: inherit;
    border-style: inherit;
    &::before {
      content: '';
      position: absolute;
      border-color: transparent;
      border-style: solid;
    }
  }
  .tippy-arrow::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: -1;
    left: 50%;
    box-sizing: border-box;
    transform-origin: 0 0;
    box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.15);
  }
  &[data-placement^='top'] > .tippy-arrow {
    bottom: 0;
  }
  &[data-placement^='bottom'] > .tippy-arrow {
    top: 0;
  }
  &[data-placement^='left'] > .tippy-arrow {
    right: 0;
  }
  &[data-placement^='right'] > .tippy-arrow {
    left: 0;
  }
  &[data-inertia][data-state='visible'] {
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
  }
  &[data-placement^='top'] .tippy-arrow::before,
  &[data-placement^='top'] .tippy-arrow::after {
    border-top-color: ${({ theme }) => theme.backgroundColor};
  }
  &[data-placement^='bottom'] .tippy-arrow::before,
  &[data-placement^='bottom'] .tippy-arrow::after {
    border-bottom-color: ${({ theme }) => theme.backgroundColor};
  }
  &[data-placement^='left'] .tippy-arrow::before,
  &[data-placement^='left'] .tippy-arrow::after {
    border-left-color: ${({ theme }) => theme.backgroundColor};
  }
  &[data-placement^='right'] .tippy-arrow::before,
  &[data-placement^='right'] .tippy-arrow::after {
    border-right-color: ${({ theme }) => theme.backgroundColor};
  }
  &[data-placement^='top'] > .tippy-arrow:before {
    bottom: -7px;
    left: 0;
    border-width: 8px 8px 0;
    transform-origin: center top;
  }
  &[data-placement^='bottom'] > .tippy-arrow:before {
    top: -7px;
    left: 0;
    border-width: 0 8px 8px;
    transform-origin: center bottom;
  }
  &[data-placement^='left'] > .tippy-arrow:before {
    border-width: 8px 0 8px 8px;
    right: -7px;
    transform-origin: center left;
  }
  &[data-placement^='right'] > .tippy-arrow:before {
    left: -7px;
    border-width: 8px 8px 8px 0;
    transform-origin: center right;
  }
  &[data-placement^='top'] .tippy-arrow::after {
    box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    transform: rotate(-45deg);
    left: 1px;
    top: 16px;
  }
  &[data-placement^='bottom'] .tippy-arrow::after {
    box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.1);
    transform: rotate(135deg);
    left: 15px;
  }
  &[data-placement^='left'] .tippy-arrow::after {
    transform: rotate(-135deg);
    top: 15px;
    left: 16px;
  }
  &[data-placement^='right'] .tippy-arrow::after {
    transform: rotate(45deg);
    left: 1px;
    top: 1px;
  }
`

export default function DocentTippy({
  themeType = ThemeType.DARK,
  children,
  ...props
}: IDocentTippyProps) {
  return (
    // Tippy library has a default width of 350, add maxWidth="none" to not have any restriction on
    // the tippy width unless the consumers have maxWidth specifically pass to props to override it.
    <StyledTippy theme={TOOLTIP_THEME[themeType]} maxWidth="none" {...props}>
      {children}
    </StyledTippy>
  )
}
