/*
   There's effectively 3 modes for CMS translations

   - Null/None (Default)
     - Ignore language header, only return default language
   - Standard
       - Same behavior as non-CMS context
       - Used in Guide Export
       - Return CP-provided translations for supported languages
       - Return Google Translate for the rest, including whenever CP-provided translations are missing for specific objects within the guide's supported languages
   - Form
     - Use in Translation Form Preview  to give previews of Linked content when switching language
     - Return CP-provided translations only.
     - Ignore whether language is supported by guide (in `Supported Languages` form)
     - No Google Translate
  */
export enum CMSTranslationMode {
  STANDARD = 'STANDARD',
  FORM = 'FORM'
}

export const CMS_TRANSLATION_MODE_HEADER_NAME = 'x-cms-translation-mode'
