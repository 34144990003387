import './yup'
import { StrictMode } from 'react'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client/react'
import axios, { AxiosError } from 'axios'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import _ from 'lodash'
import store from './redux/store'
import { authFailed, mfaVerificationFailure, authAccessTimeRestricted } from './redux/actions/auth'
import { alert } from './redux/actions/confirmation'
import client from './apollo'
import App from './App'
import history from './util/history'

import 'client/i18n'

declare global {
  interface Window {
    gtag: () => void
    File: any
    FileReader: any
  }
}

function isFindDomNodeWarningForComponents(args: string[], componentNames: string[]) {
  const [message, deprecatedFunction, , actualComponentName] = args

  return (
    _.includes(message, 'is deprecated in StrictMode') &&
    deprecatedFunction === 'findDOMNode' &&
    _.includes(componentNames, actualComponentName)
  )
}

/* eslint-disable no-console */
const actualError = console.error
console.error = (...args) => {
  // react-virtualized has some internal checks that are not working well with our build process
  // Perhaps because of some class wrapping that webpack is doing
  if (args[2] === 'Table only accepts children of type Column') {
    return
  }

  if (
    // react-virtualized and our draggable library use `findDOMNode` internally.
    isFindDomNodeWarningForComponents(args, ['Grid', 'Draggable', 'DraggableCore'])
  ) {
    return
  }

  actualError(...args)
}
/* eslint-enable no-console */

const handleErrorStatusCodeInterceptor = (
  error: AxiosError<
    Partial<{
      message: string
      mfaPassed: boolean
      mfaRequired: boolean
    }>
  >
) => {
  const statusCode = error.response?.status
  const data = error.response?.data
  const message = data?.message

  switch (statusCode) {
    case 400: {
      if (message === 'Version mismatch') {
        return store.dispatch(
          alert(
            'Unable to Save Changes',
            'We were unable to save your changes because the status of the current version was updated. Please refresh your browser to continue editing.'
          )
        )
      }
      break
    }
    case 403:
    case 401: {
      if (data?.mfaPassed === false) {
        // In this case, we already dispatch a `mfaVerificationFailure` in the `onMFAVerificationStart` saga after this, so don't do that here
        // TODO figure out a better way to prevent duplicate redux actions from firing in this case
        break
      }

      const dispatchData = data?.mfaRequired
        ? mfaVerificationFailure()
        : authFailed({ statusCode, message })

      store.dispatch(dispatchData)
      break
    }
    case 429: {
      store.dispatch(authAccessTimeRestricted({ statusCode, message }))
      break
    }
    default: {
      break
    }
  }
  return Promise.reject(error)
}

axios.interceptors.response.use((response) => response, handleErrorStatusCodeInterceptor)

createRoot(document.getElementById('app')!).render(
  <StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        {/* We need to pass the history manually so that we can utilize the history object in sagas for now. */}
        <HistoryRouter history={history as any}>
          <App />
        </HistoryRouter>
      </ApolloProvider>
    </Provider>
  </StrictMode>
)
