import _ from 'lodash'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { navigateWithRefresh } from 'client/redux/actions/navigation'
import LoginContext from 'shared/LoginContext'
import { navigateTo } from 'client/redux/sagas/utils/navigation'
import api from 'client/util/api'
import { AxiosError, AxiosResponse } from 'axios'
import UserMFAType from 'shared/UserMFAType'
import { AnyAction } from 'redux'
import * as types from '../actions/types'
import { parseTokenToObject } from '../../util/auth'
import {
  logout as logoutAction,
  loginError,
  loginSuccess,
  logoutSuccess,
  mfaVerificationSuccess,
  mfaVerificationFailure,
  resetApp,
  fetchTokenSuccess,
  mfaFetchQRUrl,
  mfaFetchQRUrlSuccess,
  resetPasswordError,
  authAccessTimeRestricted
} from '../actions/auth'
import { isUserEnrolledInMFA, getMFA, getAuthData } from '../selectors/auth'

interface AxiosResponseWithOptionalError<T = any, K = string> extends AxiosResponse<T> {
  error?: K
}

function resolveAuthError(e: unknown, defaultErrorMessage: string) {
  if (e instanceof AxiosError) {
    return {
      statusCode: e.response?.status,
      message: e.response?.data?.message ?? e.response?.data?.error ?? e?.message
    }
  }
  return { message: e instanceof Error ? e.message : defaultErrorMessage }
}

function* authenticateWithServer(action: AnyAction) {
  const { email, password } = action

  try {
    const result: AxiosResponseWithOptionalError = yield call(api.post, '/auth/login', {
      email,
      password,
      loginContext: LoginContext.CMS
    })

    if (result.error) {
      throw new Error(result.error)
    }

    const parsedToken: ReturnType<typeof parseTokenToObject> = yield call(
      parseTokenToObject,
      result.data.token
    )

    yield put(
      loginSuccess({
        data: result.data,
        ...parsedToken
      })
    )
  } catch (error) {
    const { statusCode, message } = resolveAuthError(error, 'Unable to login.')
    yield put(loginError({ statusCode, message }))
  }
}

function* postLoginActions() {
  const {
    mfaType,
    mfaPassed,
    mfaEnabled,
    mfaEnrolled,
    isPasswordExpired,
    privacyPolicyAcceptedAt,
    isUserLoggingInForFirstTime
  } = yield select(getAuthData)

  // A user logging in for the first time should see the Password screen before the MFA screen
  if (isUserLoggingInForFirstTime && isPasswordExpired) {
    yield call(navigateTo, '/auth/reset-password')
  } else if (mfaEnabled && !mfaEnrolled && mfaType === UserMFAType.TOTP) {
    yield put(mfaFetchQRUrl())
  } else if (mfaEnabled && !mfaPassed) {
    yield call(navigateTo, '/auth/mfa-verification')
  } else if (isPasswordExpired) {
    yield call(navigateTo, '/auth/reset-password')
  } else if (!privacyPolicyAcceptedAt) {
    yield call(navigateTo, '/auth/acknowledge-privacy-notice')
  } else {
    yield put(navigateWithRefresh('/catalog/exhibits'))
  }
}

function* onFetchMFAQRUrl() {
  try {
    const result: AxiosResponseWithOptionalError<{ qrDataURL: string }> = yield call(
      api.get,
      '/auth/mfa/qr'
    )
    const { data, error } = result
    if (error) {
      throw new Error(error)
    }

    const { qrDataURL } = data
    yield put(mfaFetchQRUrlSuccess({ qrDataURL }))
    yield call(navigateTo, '/auth/mfa-enrollment')
  } catch {
    yield put(logoutAction())
  }
}

function* fetchToken() {
  try {
    const result: AxiosResponseWithOptionalError<{ token: string }> = yield call(
      api.get,
      '/auth/token'
    )

    if (result.error) {
      throw new Error(result.error)
    }

    const { token } = result.data
    const parsedToken: ReturnType<typeof parseTokenToObject> = yield call(parseTokenToObject, token)

    yield put(fetchTokenSuccess(parsedToken))

    const { mfaEnabled, mfaEnrolled, mfaType } = yield select(getMFA)

    if (mfaEnabled && !mfaEnrolled && mfaType === UserMFAType.TOTP) {
      yield put(mfaFetchQRUrl())
    }
  } catch (e) {
    yield put(resetApp())
    if (e instanceof AxiosError && e.response?.status === 429) {
      yield put(authAccessTimeRestricted())
    }
  }
}

function* authFailed() {
  yield call(navigateTo, '/auth/login')
}

function* logout() {
  try {
    const result: AxiosResponseWithOptionalError = yield call(api.post, '/auth/logout')
    if (result.error) {
      throw new Error(result.error)
    }
  } catch (e) {
    /**
     * We were not previously handling any errors in the event of a logout error.
     * We are throwing the error in this case for debugging purposes only.
     * The catch is currently a no-op.
     */
    _.noop(e)
  }
  yield put(logoutSuccess())
}

function* redirectToLogin() {
  yield put(navigateWithRefresh('/'))
}

function* onMFAVerificationStart(action: AnyAction) {
  try {
    const { code } = action

    const result: AxiosResponseWithOptionalError<{
      mfaPassed: boolean
      token: string
      error: string
    }> = yield call(api.post, '/auth/mfa/verify', { code })

    const { mfaPassed, error, token } = result.data

    if (mfaPassed) {
      const parsedToken: ReturnType<typeof parseTokenToObject> = yield call(
        parseTokenToObject,
        token
      )
      yield put(fetchTokenSuccess(parsedToken))
      yield put(mfaVerificationSuccess(token))
    } else {
      throw new Error(error)
    }
  } catch (error) {
    const errorData = resolveAuthError(error, 'Unable to verify.')
    yield put(mfaVerificationFailure(errorData))
  }
}

function* onMFAVerificationSuccess() {
  const { isPasswordExpired, privacyPolicyAcceptedAt } = yield select(getAuthData)

  if (isPasswordExpired) {
    yield call(navigateTo, '/auth/reset-password')
  } else if (!privacyPolicyAcceptedAt) {
    yield call(navigateTo, '/auth/acknowledge-privacy-notice')
  } else {
    yield put(navigateWithRefresh('/catalog/exhibits'))
  }
}

function* onMFAVerificationFailure() {
  const isEnrolled: boolean = yield select(isUserEnrolledInMFA)
  if (isEnrolled) {
    yield call(navigateTo, '/auth/mfa-verification')
  } else {
    yield call(navigateTo, '/auth/mfa-enrollment')
  }
}

function* handlePasswordReset(action: AnyAction) {
  try {
    const result: AxiosResponseWithOptionalError<{ token: string }> = yield call(
      api.post,
      '/auth/password/reset',
      action.values
    )

    const parsedToken: ReturnType<typeof parseTokenToObject> = yield call(
      parseTokenToObject,
      result.data.token
    )
    yield put(fetchTokenSuccess(parsedToken))

    const { mfaEnabled, isUserLoggingInForFirstTime, mfaType } = yield select(getAuthData)

    // A user logging in for the first time sees the Password screen before the MFA screen
    if (isUserLoggingInForFirstTime && mfaEnabled && mfaType === UserMFAType.TOTP) {
      // Send them to MFA enrollment
      yield put(mfaFetchQRUrl())
    } else if (!parsedToken.privacyPolicyAcceptedAt) {
      yield call(navigateTo, '/auth/acknowledge-privacy-notice')
    } else {
      yield put(navigateWithRefresh('/catalog/exhibits'))
    }
  } catch (error) {
    const { message } = resolveAuthError(error, 'Unable to reset password.')
    yield put(resetPasswordError({ message }))
  }
}

export default function* root(): IterableIterator<any> {
  yield takeEvery(types.LOGIN_START, authenticateWithServer)
  yield takeEvery(types.LOGIN_SUCCESS, postLoginActions)
  yield takeEvery(types.AUTH_FAILED, authFailed)
  yield takeEvery(types.LOGOUT_START, logout)
  yield takeEvery(types.LOGOUT_SUCCESS, redirectToLogin)
  yield takeEvery(types.MFA_FETCH_QR_URL, onFetchMFAQRUrl)
  yield takeEvery(types.MFA_VERIFICATION_START, onMFAVerificationStart)
  yield takeEvery(types.MFA_VERIFICATION_SUCCESS, onMFAVerificationSuccess)
  yield takeEvery(types.FETCH_TOKEN, fetchToken)
  yield takeLatest(types.MFA_VERIFICATION_FAILURE, onMFAVerificationFailure)
  yield takeEvery(types.USER_RESET_PASSWORD, handlePasswordReset)
}
